const EMAIL_REGEXP = new RegExp('^[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}.){1,125}[a-z]{2,63}$');

const fullValidation = (t) => ({
  email,
}) => {
  let errors = {};
  if(!email) {
    errors.email = t.form_error_required;
  } else if(!EMAIL_REGEXP.test(email)) {
    errors.email = t.form_error_invalid_email;
  }

  return errors;
};

export default fullValidation;
