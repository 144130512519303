import React from 'react'
import { CustomInput } from 'aplanet-ui-kit'

const String = ({
  optionValue,
  path,
  onChange,
  templateSection,
}) => (
  <CustomInput
    size="large"
    onChange={(e) => {
      let newValue = e.target.value;
      if(templateSection?.forbiddenChars) {
        newValue = newValue.replace(
          new RegExp(templateSection?.forbiddenChars, 'g'),
          '',
        );
      }
      if(templateSection?.transform) {
        newValue = newValue[templateSection?.transform]();
      }
      if(newValue !== optionValue) {
        onChange(path, newValue);
      }
    }}
    value={optionValue}
  />
)

export default String
