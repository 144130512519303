import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const RedirectIfNotAuthenticated = ({ isLoggedIn, location, to, render, ...props }) => {
  const history = useHistory()
  const [renderComponent, setRenderComponent] = useState(false)

  useEffect(
    () => {
      if (!isLoggedIn) {
        history.push(`${to}${location.search}`);
      } else {
        setRenderComponent(true)
      }
    },
    [isLoggedIn, history, location, to]
  )

  if (renderComponent) {
    return render({location, ...props});
  }

  return null
}

export default RedirectIfNotAuthenticated
