import { useReducer } from 'react';

const useSetState = (initialState = []) => {
  // WARNING: using native JS Sets and treating them as immutable is a pain
  // We will emulate using Array for now, but at some point we can change to Immutable.js

  const reducer = (state = initialState, action) => {
    switch(action.type) {
      case 'ADD':
        return state.includes(action.param) ? state : state.concat(action.param);
      case 'REMOVE':
        return state.filter((e) => e !== action.param );
      case 'TOGGLE':
        return state.includes(action.param) ? state.filter((e) => e !== action.param) : state.concat(action.param);
      case 'REPLACE_ALL':
        return action.param || initialState;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const has = param => state.includes(param);
  const add = param => dispatch({ type: 'ADD', param });
  const remove = param => dispatch({ type: 'REMOVE', param });
  const toggle = param => dispatch({ type: 'TOGGLE', param });
  const replaceAll = param => dispatch({ type: 'REPLACE_ALL', param });

  return {
    has,
    add,
    remove,
    toggle,
    set: state,
    replaceAll,
  }
};

export default useSetState;
