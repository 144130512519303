import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import useForm from 'utils/useForm';
import {
  recoverPassword,
  resetAuth,
} from 'actions/auth';

import AuthLayout from 'components/AuthLayout';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';

import validation from './validation';
import './style.less';

import {
  Layout,
  Form,
  Col,
  Row,
} from 'antd';
import {
  MailOutlined,
} from '@ant-design/icons';

const {
  Content,
} = Layout;

const ForgotPassword = ({
  intl,
  history,
  location,
}) => {
  const t = intl.messages;

  const submitForm = () => {
    dispatch(
      recoverPassword(values.email.trim())
    );
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });

  // Store and actions
  const dispatch = useDispatch();

  const {
    requested,
    loading,
    error: serverErrorCode,
  } = useSelector(state => state.password_recovery);

  const emailError = useMemo(() => {
    // Validation error
    if(!isDirty('email') && errors.email) {
      return errors.email;
    }
    if(requested) {
      return t.forgotpassword_recovery_email_sent;
    }
    if(serverErrorCode) {
      return t.forgotpassword_recovery_email_error;
    }
    return '';
  }, [
    t,
    isDirty,
    errors,
    requested,
    serverErrorCode,
  ]);

  const handleCancel = useCallback(() => {
    history.push({
      pathname: `/signin`,
      search: location.search,
    });
  }, [
    history,
    location.search,
  ]);

  const handleEmailChange = useCallback((e) => {
    handleChange(e.target.name)((e.target.value || '').toLowerCase());
  }, [handleChange]);

  useEffect(() => () => dispatch( resetAuth() ), [ dispatch ]);

  const validateStatus = (
    serverErrorCode || (!isDirty('email') && errors.email))
    ? 'error'
    : ( loading ? 'validating' : requested ? 'success' : ''
  );

  return (
    <AuthLayout>
      <Content className="Content">
        <h1>{ t.forgotpassword_title }</h1>
        <Form
          className="Content_form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            hasFeedback
            validateStatus={validateStatus}
            help={emailError}
            colon={false}
            label={t.forgotpassword_email}
          >
            <CustomInput
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              name="email"
              value={values.email}
              onChange={handleEmailChange}
            />
          </Form.Item>
          <Row
            type="flex"
            justify="space-between"
          >
          <Col>
            <Form.Item>
              <CustomButton
                onClick={handleCancel}
                disabled={loading}
              >
                { t.forgotpassword_back_to_login }
              </CustomButton>
            </Form.Item>
          </Col>
            <Col>
              <Form.Item>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  disabled={!values.email || loading || requested}
                >{ t.forgotpassword_send_email }
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  </AuthLayout>
  )
}

export default injectIntl(
  withRouter(
    ForgotPassword
  )
);
