import React from 'react';
import { injectIntl } from 'react-intl';

import { Layout } from 'antd';

const {
  Content,
} = Layout;

const NotFound = ({
  intl,
}) => {
  const t = intl.messages;
  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        404! { t.not_found }
      </Content>
    </Layout>
  )
}

export default injectIntl(NotFound);
