const fullValidation = (t) => ({
  email,
}) => {
  let errors = {};
  if(!email) {
    errors.email = t.form_error_required;
  // NOTICE: We now allow logging in with a username
  //} else if(!EMAIL_REGEXP.test(email)) {
  //errors.email = t.form_error_invalid_email;
  }

  return errors;
};

export default fullValidation;
