import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import SsoLayout from 'components/SsoLayout';

import {
  StopOutlined,
} from '@ant-design/icons';

import './style.less';

const SsoError = ({
  intl,
  match,
}) => {
  const t = intl.messages;
  const { code } = match.params;

  return (
    <SsoLayout
      className="SsoError__layout"
    >
      <StopOutlined className="SsoError__icon" />
      <h1>
        { t[`sso_error_${code}`] || t.sso_error_default }
      </h1>
    </SsoLayout>
  )
}

export default injectIntl(
  withRouter(
    SsoError
  )
);
