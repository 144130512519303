import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

const requestPolicy = async (
  token,
  type='password_reset',
) => {
  const response = await fetch(`${config.AUTH_URL}/password_policy/${token}?token_type=${type}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json'
    },
    body: null,
  })

  const result = await parseResponse(response);
  return result;
};

const requestSuccess = (response) => ({
  ...response,
  type: 'PASSWORD_POLICY_COMPLETE',
});

const requestFail = ({ code, text }) => ({
  type: 'PASSWORD_POLICY_FAILED',
  code,
  text,
});

const initialState = {
  requested: false,
  loading: false,
  error: false,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'PASSWORD_POLICY_REQUEST':
      return loop({ requested: false, loading: true, error: false, data: null, },
                Cmd.run(requestPolicy, {
                  successActionCreator: requestSuccess,
                  failActionCreator: requestFail,
                  args: [
                    action.token,
                    action.token_type,
                  ],
                }));
    case 'PASSWORD_POLICY_COMPLETE':
      return {
        requested: true,
        loading: false,
        error: false,
        data: {
          policy: action.password_policy,
          email: action.email,
        },
      };
    case 'PASSWORD_POLICY_FAILED':
      return { requested: false, loading: false, error: true, data: null, };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState; // The 'auth' reducer will do the rest
    default:
      return state;
  }
};

export {
  reducer as password_policy,
};
