import React, { useCallback } from 'react'
import {
  useSelector, useDispatch
} from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import {
  Layout,
  Col,
  Row,
  Tooltip,
  Popover,
} from 'antd';
import { injectIntl } from 'react-intl';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import { UserOutlined } from '@ant-design/icons';

import AppHeader from 'components/AppHeader';
import OrganizationLogo from 'components/OrganizationLogo';
import NotificationCenter from 'components/NotificationCenter';
import AplanetIcon from 'components/AplanetIcon';
import Avatar from 'components/Avatar';
import OrganizationTree from 'components/OrganizationTree'
import OrgLogos from 'components/OrgLogos';

import useOrganizations from 'utils/useOrganization';
import { switchCurrentOrganization } from 'actions/organization'

import { formatMessage } from '../../intl';
import './style.less';

const { Header, Sider } = Layout;

const zIndex = 999;
const height = '120px';
const width = '100%';

const AppFramework = ({
  children,
  errorMessage,
  intl,
  history,
  location
}) => {

  const dispatch = useDispatch()

  const {
    data: profile
  } = useSelector(state => state.profile);

  const organization_tree = useSelector((state) => state.organization_tree)

  const {
    avatar,
    name,
  } = profile || {};

  const {
    organization,
    suborganization,
    memberships,
  } = useOrganizations();



  const onNotificationNavigation = useCallback((route) => {
    history.push(route);
  },
    //eslint-disable-next-line
    []);

  const onChangeOrganizaion = useCallback(
    (organization) => dispatch(switchCurrentOrganization(organization)),
    [dispatch]
  )

  return (
    <Layout className='main-layout'>
      <Sider
        theme="light"
        className="sider"
        collapsedWidth={64}
        collapsed
        trigger={null}
        collapsible
      >
        <Row type="flex" justify="center" className="sider__logo-wrapper">
          <Col>
            {memberships.length <= 1 ? (
              <OrganizationLogo
                showPoweredBy={suborganization?.config?.show_powered_by}
                name={suborganization.name}
                logo={suborganization.logo_small}
                className="sider__logo-small"
              />
            ) : (
              <Tooltip placement="right" title={formatMessage('avatardropdown_change_organization')}>
                <Link to="/organizations">
                  <OrganizationLogo
                    showPoweredBy={suborganization?.config?.show_powered_by}
                    name={suborganization.name}
                    logo={suborganization.logo_small}
                    className="sider__logo-small"
                  />
                </Link>
              </Tooltip>
            )}
          </Col>
        </Row>
        <div className='grow' style={{ flexGrow: 2 }}></div>
        <div>
          <div className="icon">
            <NotificationCenter
              onNavigation={(link) => onNotificationNavigation(link)}
            />
          </div>
          {location.pathname.includes('organization') ? (
            <div className="icon selected">
              <div>
                <AplanetIcon name='Settings' faStyle='fad' />
              </div>
            </div>
          )
            : (
              <div className="icon">
                <Link
                  style={{ color: 'initial' }}
                  to="/organization"
                >
                  <AplanetIcon name='Settings' faStyle='fad' />
                </Link>
              </div>
            )}
          <div className="icon" id="avatar">
            <Popover
              overlayClassName="AvatarMenuPopOver"
              align={{
                offset: [4, 40],            // the offset sourceNode by 10px in x and 20px in y,
              }}
              content={
                <div className='main-layout-menu'>
                  <Link to="/profile">
                    {formatMessage('avatardropdown_profile')}
                  </Link>
                  <Link to="/logout">
                    {formatMessage('avatardropdown_logout')}
                  </Link>
                </div>
              }
              placement="right"
              trigger='click'
            >
              <section>
                <Avatar
                  shape="circle"
                  src={avatar || undefined}
                  name={name || undefined}
                  icon={<UserOutlined />}
                  className='AppHeader-avatar'
                />
              </section>
            </Popover>
          </div>
        </div>
      </Sider>
      {location.pathname.includes('organization') ? (
        <div className='main-layout-lateral-menu'>
          <OrgLogos
            organization={suborganization}
            mainOrganization={organization}
          />
          <OrganizationTree
            tree={organization_tree.tree || []}
            changeOrg={onChangeOrganizaion}
            currentOrg={suborganization}
          />
        </div>
      ) : null}
      <Layout style={{ maxHeight: "100vh", overflow: "hidden" }}>
        <Header
          style={{
            position: 'fixed',
            zIndex,
            width,
            height,
          }}
        >
          <AppHeader />
        </Header>
        <Layout style={{ overflow: "auto" }}>
          {errorMessage ? (
            <Sentry.ErrorBoundary
              fallback={
                <ErrorBoundaryFallback
                  titleErrorMessage={intl.formatMessage({ id: `error_boundary_title_message` })}
                  buttonLabel={intl.formatMessage({ id: `error_boundary_reload_button` })}
                  descriptionErrorMessage={errorMessage}
                  customErrorImage="/images/error_image.png"
                />
              }>
              {children}
            </Sentry.ErrorBoundary>
          ) : (children)}
        </Layout>
      </Layout>
    </Layout>
  )
};

export default injectIntl(withRouter(AppFramework));
