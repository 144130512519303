import chroma from "chroma-js";
import { formatMessage } from "../../intl";

export const settingsTemplate = {
  configuration_label_general_suborg: {
    email_from_name: {
      type: 'string',
      forbiddenChars: '[@,]',
    },
    email_from_slug: {
      type: 'string',
      forbiddenChars: '[^A-Za-z0-9_-]',
      transform: 'toLowerCase',
    },
  },
  configuration_label_atlas: {
    _product: 'atlas',
    _systemOnly: true, // System only for now
    atlas: {
      theme: {
        type: 'object',
        options: {
          '@primary-color': { type: 'color' },
        },
      },
      dashboard_theme: {
        type: 'array.color_schema',
        values: ['Dark2', 'Set1', 'RdYlGn', 'Paired', 'Accent'].map(set => chroma.scale(set).colors(9))
      },
      approval_levels: {
        type: 'number',
        min: 1,
        max: 10,
      },
      features: {
        type: 'object',
        options: {
          targets : {type: 'boolean'},
          chatbot: {type: 'boolean'},
          bulk_manage_kpis : {type: 'boolean'},
          bulk_manage_kpis_export : {type: 'boolean'},
        },
        description: true
      },
      show_kpi_variation: {
        type: 'boolean',
      },
      third_party_can_see_values: {
        type: 'boolean',
      },
      can_add_auditors: {
        type: 'boolean',
      },
    },
  },
}

export const rootNodeSettingsTemplate = {
  configuration_label_community: {
    _product: 'community',
    community: {
      features: {
        type: 'object',
        options: {
          dashboard: { type: 'boolean' },
          rankins: { type: 'boolean' },
          proposals: { type: 'boolean' },
          collaborations: { type: 'boolean' },
          responsible_profile: { type: 'boolean' },
          impact: { type: 'boolean' },
          community: { type: 'boolean' },
          about_our_program: { type: 'boolean' },
        },
        description: true
      },
      can_pin_initiatives: {
        type: 'boolean',
      },
      can_feature_initiatives: {
        type: 'boolean',
      },
      initiative_types: {
        type: 'array.string',
        values: ["volunteering", "donation", "collection", "informative"],
      },
      proposal_types: {
        type: 'array.string',
        values: ["volunteering", "donation", "collection", "informative"],
      },
      theme: {
        type: 'object',
        options: {
          '@primary-color': { type: 'color' },
        },
      },
      max_number_of_sdgs: {
        type: 'number',
        min: 1,
        max: 100,
      },
      currency: {
        type: 'string',
        values: ['USD', 'EUR', 'BRC', 'JPY', 'GBP', 'CHF', 'CAD', 'AUD/NDZ', 'ZAR'],
      },
    },
  },
  configuration_label_general: {
    contact_email: {
      type: 'string',
      default: `${formatMessage("contact_email_address")}@aplanet.org`
    },
    detail_auth_error_messages: {
      type: 'boolean',
    },
    show_system_admin_activity: {
      type: 'boolean',
    }
  },
  configuration_label_community_optional_emails: {
    _product: 'community',
    optional_emails: {
      type: 'array.string',
      values: [
        // Community general
        'reminder_1_day',
        'reminder_7_day',
        'initiative_finished',
        'initiative_finished_survey',
        'new_proposal',

        // Community user
        'new_initiative',
        'cancel_initiative',
        'reject_proposal',
        'join_initiative',

        // Community admin
        'not_going',
        'new_collaborative',
        'new_participant',
        'new_initiative_admin',
      ],
    },
  },
  configuration_label_atlas_optional_emails: {
    _product: 'atlas',
    optional_emails: {
      type: 'array.string',
      values: [
        // Sustainability
        'rejection',
        'validation',
        'validation_completed',
        'validation_next_approver',
        'ready_to_validate_bulk',
      ],
    },
  },
  configuration_label_materiality_optional_emails: {
    _product: 'materiality',
    optional_emails: {
      type: 'array.string',
      values: [
        'materiality',
      ],
    },
  },
}

export const parseReports = (reports = []) => {
  const equivalence = {
    'gri': 'GRI',
    'equality': 'EQ'
  }

  return reports.map(r => equivalence[r])
}

export function isAvailable(availableDimensions) {
  return d => availableDimensions.includes(d);
}

export function isAttachedToAvailableDimension(templateSection, availableDimensions) {
  return suboption => !templateSection.options[suboption].dimensions || templateSection.options[suboption].dimensions.some(d => availableDimensions.includes(d));
}
