import { mapOverTree, mapOverTreeFromLeafs } from 'utils/tree';
import {compact} from 'lodash'

const TARGET = 'organization_tree';

const initialState = {
  current_id: 0,
  current_slug: '',
  tree: null,
  fetching: false,
  error: null
};

const currenTreeOrg = (
  nodes,
  parent = {},
) => {
  return nodes.map(_node => {
    if(!_node) {
      return null;
    }

    const node = {..._node, parent};

    const {
      children = [],
    } = node;

    if (_node.permissions) return _node;

    const formattedChildren = compact(currenTreeOrg(children, node))

    if(formattedChildren.length === 0 || !formattedChildren[0].permissions) return null

    return formattedChildren[0];
  }).filter(Boolean);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || response.target !== TARGET) return state;
      switch(action.response.method) {
        case 'POST':
          const [ newTree ] = mapOverTreeFromLeafs([state.tree])((node, children) => {
            if(node.id === response.result.parent_id) {
              const newChildOrg = {
                ...response.result,
                permissions: response.result.permissions || node.permissions,
              };
              return {
                ...node,
                children: [ ...children, newChildOrg ],
              }
            }
            return { ...node, children };
          })
          return {
            ...state,
            tree: newTree,
            current_id: response.result.id,
            current_slug: response.result.slug,
            fetching: false,
            error: null
          };
        case 'DELETE':
          const [ updatedTree ] = mapOverTreeFromLeafs([state.tree])((node, children) => {
            if(node.id === response.result.parent_id) {
              return {
                ...node,
                children: children.filter(
                  child => child.slug !== response.result.slug
                ),
              }
            }
            return { ...node, children };
          })
          return {
            ...state,
            tree: updatedTree,
            current_id: state.tree.id,
            current_slug: state.tree.slug,
            fetching: false,
            error: null
          };
        default:
          const tree = action.response.result;
          const currentOrg = currenTreeOrg([tree]);
          const current_id = currentOrg[0]?.id || state.current_id;
          const current_slug = currentOrg[0]?.slug || state.current_slug;
          return {
            ...state,
            current_id,
            current_slug,
            tree,
            fetching: false,
            error: null
          };
      }
    case 'API_CALL_FAILED':
      if (!action.request || action.request.target !== TARGET) return state;
      const { code, text } = action;
      return {
        ...state,
        fetching: false,
        error: {
          code,
          text
        }
      };
    case 'SWITCH_CURRENT_ORGANIZATION':
      return {
        ...state,
        current_id: action.organization.id,
        current_slug: action.organization.slug,
      };
    case 'ORGANIZATION_UPDATE':
      let staleSlug;
      const [ updatedTree ] = mapOverTree([state.tree])(node => {
        if (node.id === action.organization.id) {
          staleSlug = node.slug;
          return { ...node, ...action.organization };
        }
        return node;
      });
      return {
        ...state,
        tree: updatedTree,
        current_slug: state.current_slug === staleSlug ? action.organization.slug : state.current_slug,
      };
    case 'ORGANIZATION_PROFILE_COMPLETE' :
      return {
        ...state,
        current_id: action.organization.id,
        current_slug: action.organization.slug,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export { reducer as organization_tree };
