import React, {
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import SsoLayout from 'components/SsoLayout';

import {
  CheckCircleOutlined,
} from '@ant-design/icons';

import './style.less';

const TIMEOUT = 1000; // 1 second

const SsoSuccess = ({
  intl,
}) => {
  const t = intl.messages;

  useEffect(() => {
    if(window.opener) {
      window.opener.location.reload();
      setTimeout(window.close, TIMEOUT);
    } else {
      window.location = '/';
    }
  }, []);

  return (
    <SsoLayout
      className="SsoSuccess__layout"
    >
      <CheckCircleOutlined className="SsoSuccess__icon" />
      <h1>
        { t.sso_success }
      </h1>
    </SsoLayout>
  )
}

export default injectIntl(SsoSuccess);
