import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LocaleProvider } from 'antd';
import qs from 'query-string';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';
import config from 'config';

import Loading from 'containers/Loading';
import Routes from 'containers/Routes';

import { saveState, loadState } from 'store/persistence';
import { hydrateStore } from 'actions/store';
import {
  requestIdentity,
  requestOrganizationProfile,
} from 'actions/auth';
import updateTheme from 'utils/updateTheme';
import { useEventTracking } from 'hooks/useEventTracking';

const Root = ({
  location,
  intl
}) => {
  const [hydrated, setHydrated] = useState(false);
  const [refreshStarted, setRefreshStarted] = useState(false);
  const [publicProfileRequested, setPublicProfileRequested] = useState(false);
  const [publicProfileLoaded, setPublicProfileLoaded] = useState(false);
  const eventTracking = useEventTracking();
  const dispatch = useDispatch();
  const {
    organization,
  } = useSelector(state => state);

  const {
    refreshing_token,
    logged_in,
  } = useSelector(state => state.auth);

  // Save state upon auth/org change
  useEffect(() => {
    if (hydrated) {
      saveState({
        organization_slug: (organization.data || {}).slug,
      });
    }
  }, [
    hydrated,
    organization.data,
  ]);

  useEffect(() => {
    eventTracking.init();
  }, [eventTracking]);

  // Load state when loading the app
  useEffect(() => {
    loadState()
      .then(state => dispatch(hydrateStore(state)))
      .then(() => setHydrated(true));
  }, [dispatch]);

  useEffect(() => {
    if (!organization.data || organization.data.is_default_org) {
      if (!publicProfileRequested && location.search) {
        const parsed = qs.parse(location.search);
        if (parsed.org) {
          setPublicProfileRequested(true);
          dispatch(
            requestOrganizationProfile(parsed.org)
          );
        }
      }
      return
    }
    const theme = organization.data?.config?.general?.theme;
    updateTheme(theme);
    if (publicProfileRequested) {
      // NOTICE: Half a second to load the theme
      setTimeout(() => setPublicProfileLoaded(true), 500);
    }
  }, [
    dispatch,
    publicProfileRequested,
    organization.data,
    location.search,
  ]);

  useEffect(() => {
    if (!config.INTERCOM_APP_ID) {
      return;
    }
    if (organization.data?.config?.atlas?.features?.chatbot === true) {
      window.Intercom('boot', {
        app_id: config.INTERCOM_APP_ID,
        user_id: organization.data.name
      });
    } else {
      window.Intercom('shutdown', {
        app_id: config.INTERCOM_APP_ID,
      });
    }
  }, [organization]);

  // Refresh access token when loading the app
  useEffect(() => {
    if (hydrated) {
      dispatch(requestIdentity());
      setRefreshStarted(true);
    }
  }, [
    hydrated,
    dispatch,
  ]);

  const isReady = hydrated && refreshStarted &&
    (
      logged_in ||
      !refreshing_token
    ) &&
    (
      !publicProfileRequested ||
      (!organization.fetching && publicProfileLoaded)
    );

  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorBoundaryFallback
          titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
          buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
          descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_default_message' })}
          customErrorImage="/images/error_image.png"
        />
      }>
      <LocaleProvider locale={intl.formats.antd}>
        {isReady ? <Routes /> : <Loading.Big />}
      </LocaleProvider>
    </Sentry.ErrorBoundary>
  );
}

if (config.INTERCOM_APP_ID) {
  (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + config.INTERCOM_APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();

  window.Intercom('boot', {
    app_id: config.INTERCOM_APP_ID,
  });

  window.Intercom('shutdown', {
    app_id: config.INTERCOM_APP_ID,
  });
}

export default withRouter(injectIntl(Root));
