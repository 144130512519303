import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { compact } from 'lodash'

import { flattenTree, searchTreeWithFullPath } from 'utils/tree';
import Avatar from 'components/Avatar';

import { Tree, Input } from 'antd';
import { InfoCircleOutlined, DownOutlined } from '@ant-design/icons';
import AplanetIcon from 'components/AplanetIcon';

import { formatMessage } from '../../intl';

import './style.less';

const searchTree = (tree, val) => {
  if (!val) {
    return [tree];
  }
  return searchTreeWithFullPath(
    [tree], (node) => node.name.toLowerCase().includes(val.toLowerCase())
  );
};

const treeData = (
  nodes,
  currentSlug,
  hideLogo,
  parent = {},
) => {
  return nodes.map(_node => {
    if (!_node) {
      return null;
    }

    const node = { ..._node, parent };

    const {
      name,
      children = [],
    } = node;

    const hideChildrenLogo = hideLogo || node.logo_small === node.parent.logo_small;

    const formattedChildren = compact(treeData(children, currentSlug, hideChildrenLogo, node))

    if (formattedChildren.length === 0 && !node.permissions) return null

    return {
      title: name,
      key: node.id,
      disabled: !node.permissions,
      children: formattedChildren,
      icon: !hideLogo &&
        <Avatar
          shape="square"
          src={node.logo_small || undefined}
          name={node.name || undefined}
          icon={<InfoCircleOutlined />}
          className='OrganizationSelector-avatar'
        />
    }
  }).filter(Boolean);
};


const OrganizationTree = ({
  tree,
  currentOrg,
  changeOrg,
}) => {
  const [search, setSearch] = useState("")
  const [expandedKeys, setExpandedKeys] = useState([]);


  const filteredTree = useMemo(() => searchTree(tree, search),
    [search, tree]
  );

  useEffect(() => {
    if (!expandedKeys.length) setExpandedKeys([currentOrg.id])
  },
    [currentOrg.id, expandedKeys])

  useEffect(
    () => {
      if (search && filteredTree[0]) {
        setExpandedKeys(
          Object.values(
            flattenTree(filteredTree[0])
          ).map(node => node.id)
        );
      }
    },
    [
      search,
      filteredTree,
    ]
  );

  const flat_tree = useMemo(() => flattenTree(filteredTree[0]), [filteredTree]);
  const idToSlug = useCallback((id) => flat_tree[id].slug, [flat_tree]);

  const handleSelect = useCallback(([id]) => id && id !== currentOrg.id && changeOrg({ id, slug: idToSlug(id) }), [currentOrg, changeOrg, idToSlug]);

  return (
    <div className="OrganizationTree">
      <Input value={search} onChange={e => setSearch(e.target.value)} suffix={<AplanetIcon name='Search' />} />
      {(!filteredTree || filteredTree.length === 0)
        ? (
          <span>{formatMessage('organization_tree_empty')}</span>
        )
        : (
          <Tree
            showIcon
            switcherIcon={<DownOutlined />}
            defaultExpandedKeys={expandedKeys}
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            onSelect={handleSelect}
            selectedKeys={currentOrg ? [currentOrg.id] : []}
            treeData={treeData(filteredTree, currentOrg.slug)}
          />
        )
      }

    </div>
  );
};

export default OrganizationTree;
