import React from 'react'
import { InputNumber } from 'antd'

const Number = ({ optionValue, path, templateSection, onChange }) => (
  <InputNumber
    size="large"
    min={templateSection.min}
    max={templateSection.max}
    value={optionValue}
    onChange={(value) => value !== optionValue && onChange(path, value)}
  />
)

export default Number
