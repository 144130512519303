import React, {
  useCallback,
  useMemo,
} from 'react';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  MailOutlined,
} from "@ant-design/icons";

import {
  lookup,
  resetAuthError,
} from 'actions/auth';

import {
  getEmailFromQueryString,
  getAppFromQueryString,
} from 'utils/clientUrl';

import useForm from 'utils/useForm';
import validation from './validation';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';
import './style.less';

const EmailForm = ({
  intl,
  location,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const {
    error: serverErrorCode,
    errorBody: serverErrorDetail,
    loading,
  } = useSelector(state => state.signin);

  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    dispatch(resetAuthError());
    dispatch(
      lookup(
        values.email.toLowerCase().trim(),
        getAppFromQueryString(location.search)
      )
    );
  };

  const defaultValues = useMemo(() => {
    return {
      email: getEmailFromQueryString(location.search),
    };
  }, [
    location.search,
  ]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues,
  });

  const emailError = (
    (serverErrorCode || errors.email) &&
    !isDirty('email')
  ) && (
    t[`signin_error_${serverErrorCode}_${serverErrorDetail?.error || ''}`] ||
    t[`signin_error_${serverErrorCode}`] ||
    errors.email ||
    t.signin_error_default
  );

  const handleEmailChange = useCallback((e) => {
    e.preventDefault();
    if(emailError) {
      dispatch( resetAuthError() );
    }
    handleChange(e.target.name)((e.target.value || '').toLowerCase());
  }, [
    dispatch,
    emailError,
    handleChange,
  ]);

  return (
    <article className="EmailForm">
      <h1>{ t.emailform_title }</h1>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          hasFeedback
          validateStatus={emailError ? 'error' : ''}
          help={emailError}
          colon={false}
          label={t.emailform_label}
        >
          <CustomInput
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="email"
            autoFocus
            value={values.email}
            onChange={handleEmailChange}
            disabled={loading}
          />
        </Form.Item>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
        >
          <Col>
            <Form.Item
              className="EmailForm__confirm_button"
            >
              <CustomButton
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                { t.emailform_submit }
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </article>
  )
}

export default injectIntl(
  withRouter(
    EmailForm
  )
);
