import React, {
  useCallback,
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import SignedOutFooter from 'components/SignedOutFooter';
import CustomButton from 'components/CustomButton';

import './style.less';

const DEFAULT_COVER_URL = '/images/cover/sherman-yang-VBBGigIuaDY-unsplash.jpg';

const AuthLayout = ({
  children,
  intl,
  organization = null,
  showLogin = false,
  history,
  location,
}) => {
  const t = intl.messages;

  const onLoginClick = useCallback(() => {
    history.push({
      pathname: `/signin`,
      search: location.search,
    });
  }, [
    history,
    location.search,
  ]);

  const cover_url = useMemo(() => {
    return organization?.config?.general?.cover_url || DEFAULT_COVER_URL
  }, [
    organization,
  ]);

  const cover_quote = useMemo(() => {
    return organization?.config?.general?.cover_quote || {
      visible: true,
      quote: t.quote[`quote_universal_1`],
      author: t.quote[`quote_universal_1_author`],
      meta: t.quote.quote,
    }
  }, [
    t,
    organization,
  ]);

  return (
    <div className="AuthLayout">
      <div className="AuthLayout__container">
        <section className= "AuthLayout__header">
          <div className= "AuthLayout__logo">
            {
              organization && organization.logo
              ? (
                <img src={organization.logo} alt={organization.name} />
              ) : (
                <img src='/images/aplanet.svg' alt="Aplanet" />
              )
            }
          </div>
          { !showLogin ? null :
            <div>
              <CustomButton
                onClick={onLoginClick}
              >
                { intl.formatMessage({ id: "login"}) }
              </CustomButton>
            </div>
          }
        </section>

        <section className="AuthLayout__content">
          {children}
        </section>
        <SignedOutFooter />
      </div>

      <div
        className="AuthLayout__cover"
        style={{
          backgroundImage: `linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${cover_url}')`,
        }}
      >
        {
          !cover_quote.visible
          ? null
          : (
            <div className="AuthLayout__quote">
              <div className="AuthLayout__quote_intro">
                { cover_quote.meta }
              </div>
              <q className="AuthLayout__quote_quote">
                { cover_quote.quote }
              </q>
              <div className="AuthLayout__quote_author">
                { cover_quote.author }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default injectIntl(
  withRouter(
    AuthLayout
  )
);
