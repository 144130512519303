import React from 'react';

import './style.less';

const SsoLayout = ({
  className = '',
  children,
}) => {
  return (
    <div className={`SsoLayout ${className}`}>
      <div className="SsoLayout__container">
        <section className="SsoLayout__content">
          { children }
        </section>
        <section className= "SsoLayout__footer">
          <div className= "SsoLayout__logo">
            <img alt="Aplanet" src='/images/aplanet.svg' />
          </div>
        </section>
      </div>
    </div>
  );
};

export default SsoLayout;
