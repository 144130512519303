import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { injectIntl } from 'react-intl';

import T from 'components/T';

const resetErrorState = () => ({
  type: 'RESET_STATE',
  target: 'error_notification',
});


const BackErrorNotification = ({ intl }) => {

  const t = intl.messages;

  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const {
    error
  } = useSelector(state => state.error_notification);

  useEffect(() => {
    if (error && error.copy_description) {
      api.error({
        message: t[error.copy_title] || t.api_call_failed_default_title,
        description: <T {...{ [error.copy_description]: error.values }}/>,
        onClose: () => dispatch(resetErrorState())
      });
    }
  }, [
    error, t, api, dispatch
  ]);
  
  return <div>{contextHolder}</div>
}

export default injectIntl(BackErrorNotification)