import React, { useState, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { CustomTag } from 'aplanet-ui-kit'

import {
  Col,
  Row,
  Divider,
  Radio,
  Modal,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import Logo from 'components/Logo';

import { formatCycleDate } from 'utils/cycleDates';

const ATLAS_FEATURES = [
  'organization_tree',
  'dashboard',
  'data_owners',
  'groups_of_interest',
  'materiality',
  'equality',
  'restricted_admins',
  'targets',
  'show_neutrality',
  'neutrality',
  'bulk_manage_kpis',
  'chatbot'
];

const FeatureFlag = injectIntl(({
  intl,
  app,
  feature,
  allowed,
}) => {
  const t = intl.messages;

  return (
    <Row className="OrganizationProfile-feature-flag">
      <Col
        span={1}
        className={`OrganizationProfile-feature-flag-${allowed ? 'allowed' : 'not-allowed'}`}
      >
        { allowed
          ? <CheckOutlined />
          : <CloseOutlined />
        }
      </Col>
      <Col span={23}>{ t[`feature_${app}_${feature}`] }</Col>
    </Row>
  );
});


const Information = ({
  intl,
  organization,
  isSystemAdmin,
  suborganization,
  handleUpdateOrganization,
}) => {
  const t = intl.messages;

  const [smallLogo, setSmallLogo] = useState();
  const [logo, setLogo] = useState();
  const [showLogoConfirmModal, setShowLogoConfirmModal] = useState();
  const [logoAppliesTo, setLogoAppliesTo] = useState('self');

  const onCancelLogoConfirmModal = useCallback(() => {
    setShowLogoConfirmModal(false);
    setSmallLogo(null);
    setLogo(null);
  }, [
    setShowLogoConfirmModal,
    setSmallLogo,
    setLogo,
  ]);

  const onSave = useCallback((dataURL = null) => {
    const payload = smallLogo
      ? { logo_small: dataURL ?? smallLogo }
      : { logo: dataURL ?? logo }
    handleUpdateOrganization({
      ...payload,
      _target: logoAppliesTo,
    });
    onCancelLogoConfirmModal();
  }, [
    handleUpdateOrganization,
    onCancelLogoConfirmModal,
    logoAppliesTo,
    smallLogo,
    logo,
  ]);

  const saveLogo = useCallback((dataURL) => {
    setLogo(dataURL);
    if ((suborganization?.children || []).length) {
      setShowLogoConfirmModal(true);
    } else {
      onSave(dataURL);
    }
  }, [onSave, suborganization.children]);

  const saveSmallLogo = useCallback((dataURL) => {
    setSmallLogo(dataURL);
    if ((suborganization?.children || []).length) {
      setShowLogoConfirmModal(true);
    } else {
      onSave(dataURL);
    }
  }, [suborganization.children, onSave]);

  const cycleDateOptions = useMemo(() => {
    if(!suborganization?.product_config?.atlas) {
      return null;
    }

    return (
      organization?.product_config?.atlas?.cycle_date_options ||
      suborganization?.product_config?.atlas?.cycle_date_options ||
      null
    );
  }, [
    organization,
    suborganization,
  ]);

  return (
    <div className="OrganizationProfile-information">
      <Row className="OrganizationProfile-information-row">
        <Col span={6} className="OrganizationProfile-label">
          { t.organization_profile_legal_name }
        </Col>
        <Col span={6} className="OrganizationProfile-label">
          { t.organization_profile_industry }
        </Col>
        <Col span={6} className="OrganizationProfile-label">
          { t.organization_profile_sector }
        </Col>
        <Col span={6} className="OrganizationProfile-label">
          { isSystemAdmin ? t.organization_profile_cs_name : '' }
        </Col>
        <Col span={6} className="OrganizationProfile-value">
          { organization.profile.legal_name || '-' }
        </Col>
        <Col span={6} className="OrganizationProfile-value">
          { t.industry[(organization.profile.industry_slug || '').toLowerCase()] || t.industry.not_set }
        </Col>
        <Col span={6} className="OrganizationProfile-value">
          { t.sector[(organization.profile.sector_slug || '').toLowerCase()] || t.sector.other }
        </Col>
        <Col span={6} className="OrganizationProfile-value">
          { isSystemAdmin ? (organization.profile.cs_name || '-') : '' }
        </Col>
      </Row>

      {
        !cycleDateOptions ||
        cycleDateOptions.length === 0
        ? null
        : (
          <Row className="OrganizationProfile-information-row">
            <Col span={24} className="OrganizationProfile-label">
              { t.organization_profile_cycle_dates }
            </Col>
            <Col span={24} className="OrganizationProfile-value">
              {
                cycleDateOptions.map((cycle_date) => {
                  return (
                    <CustomTag
                      key={cycle_date}
                      name={formatCycleDate(intl, cycle_date)}
                      className="OrganizationProfile-tag"
                    />
                  )
                })
              }
            </Col>
          </Row>
        )
      }

      <Row className="OrganizationProfile-information-row">
        <Col span={24} className="OrganizationProfile-label">
          { t.organization_profile_website }
        </Col>
        <Col span={24} className="OrganizationProfile-value">
          { organization.profile.url
            ? <a
                href={organization.profile.url}
                target="_blank" rel="noopener noreferrer"
              >
                { organization.profile.url }
              </a>
            : '-'
          }
        </Col>
      </Row>

      <Row className="OrganizationProfile-information-row">
        <Col span={24} className="OrganizationProfile-label">
          { t.organization_profile_address }
        </Col>
        <Col span={24} className="OrganizationProfile-value">
          { organization.profile.address || '-' }
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Col className="OrganizationProfile-label">
            { t.organization_profile_logo }
          </Col>
          <Col className="OrganizationProfile-value">
            <Logo
              name={organization.name}
              src={organization.logo}
              permissions={organization?.permissions?.can_manage_configuration}
              saveLogo={saveLogo}
              showLogoConfirmModal={showLogoConfirmModal}
              logo={logo}
              setLogo={setLogo}
            />
          </Col>
        </Col>
        <Col span={12}>
          <Col className="OrganizationProfile-label">
            { t.organization_profile_logo_small }
          </Col>
          <Col className="OrganizationProfile-value">
            <Logo
              name={organization.name}
              src={organization.logo_small}
              permissions={organization?.permissions?.can_manage_configuration}
              saveLogo={saveSmallLogo}
              showLogoConfirmModal={showLogoConfirmModal}
              logo={smallLogo}
              setLogo={setSmallLogo}
              small
            />
          </Col>
        </Col>
      </Row>

      {isSystemAdmin &&
      <>
        <Divider />

        <Row>
          { organization.settings.community && organization.settings.community.features
            ? <Col span={12} className="OrganizationProfile-feature">
                <Row>
                  <Col span={24} className="OrganizationProfile-label">
                    { t.organization_profile_features_community }
                  </Col>
                </Row>
                <Row className="OrganizationProfile-feature-flags">
                  <Col span={24}>
                    { Object.keys(organization.settings.community.features).map(
                      feature => {
                        return <FeatureFlag
                          app="community"
                          key={feature}
                          feature={feature}
                          allowed={organization.settings.community.features[feature]}
                        />;
                      }
                    )}
                  </Col>
                </Row>
              </Col>
            : null
          }
          { organization.settings.atlas && organization.settings.atlas.features
            ? <Col span={12} className="OrganizationProfile-feature">
                <Row>
                  <Col span={24} className="OrganizationProfile-label">
                    { t.organization_profile_features_atlas }
                  </Col>
                </Row>
                <Row className="OrganizationProfile-feature-flags">
                  <Col span={24}>
                    { Object.keys(organization.settings.atlas.features).sort(
                      (a, b) => ATLAS_FEATURES.indexOf(a) > ATLAS_FEATURES.indexOf(b) ? 1 : -1
                    ).map(
                      feature => {
                        return <FeatureFlag
                          app="atlas"
                          key={feature}
                          feature={feature}
                          allowed={organization.settings.atlas.features[feature]}
                        />;
                      }
                    )}
                  </Col>
                </Row>
              </Col>
            : null
          }
        </Row>
      </>
      }

      <Modal
        title={ t.organization_profile_logo_apply_title }
        visible={showLogoConfirmModal}
        onOk={() => onSave()}
        onCancel={onCancelLogoConfirmModal}
        okText={ t.organization_profile_logo_apply_ok }
        cancelText={ t.organization_profile_logo_apply_cancel }
      >
        <p>{ t.organization_profile_logo_apply_description }</p>
        <Radio.Group
          value={logoAppliesTo}
          onChange={e => setLogoAppliesTo(e.target.value)}
        >
          <Radio value="self">
            { t.organization_profile_logo_apply_self }
          </Radio>
          <Radio value="all">
            { t.organization_profile_logo_apply_all }
          </Radio>
        </Radio.Group>
      </Modal>

    </div>
  );
}

export default injectIntl(Information);
