import React, { useEffect, useState, useMemo } from 'react'
import { injectIntl } from 'react-intl'
import { isEqual, set, cloneDeep } from 'lodash'
import { Card, Col, Row, Button } from 'antd'
import OptionsPanel from './OptionsPanel'
import Modal from './Modal'

const Configuration = ({
  intl,
  initialSettings,
  isSystemAdmin,
  isLoading,
  availableDimensions,
  handleUpdateConfiguration,
}) => {
  const [showWarningResetModal, setShowWarningResetModal] = useState(false)
  const [settings, setSettings] = useState(initialSettings)

  useEffect(() => {
    setSettings(initialSettings)
  }, [initialSettings])

  const isThereAnyChanges = useMemo(
    () => !isEqual(initialSettings, settings),
    [initialSettings, settings]
  )

  return (
    <>
      <Row>
        <Col span={18}></Col>
        <Col offset={1} span={5} className="Configuration-actions">
          {isThereAnyChanges && <Button size="large" onClick={() => setShowWarningResetModal(true)} disabled={!isThereAnyChanges || isLoading}>
            {intl.formatMessage({ id: 'configuration_reset' })}
          </Button>}
          &nbsp;&nbsp;&nbsp;
          <Button
            size="large"
            type="primary"
            onClick={() => handleUpdateConfiguration(settings) }
            disabled={!isThereAnyChanges || isLoading}
          >
            {intl.formatMessage({ id: 'configuration_save' })}
          </Button>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
      <Card>
        <OptionsPanel
          settings={settings}
          availableDimensions={availableDimensions}
          isSystemAdmin={isSystemAdmin}
          onChange={(path, value) => {
            const newSettings = cloneDeep(settings)
            set(newSettings, path, value)
            setSettings(newSettings)
          }}
        />
      </Card>
      <Modal
        visible={showWarningResetModal}
        title={intl.formatMessage({
          id: 'configuration_warning_title',
        })}
        okText={intl.formatMessage({ id: 'configuration_reset_warning' })}
        cancelText={intl.formatMessage({
          id: 'configuration_cancel_warning',
        })}
        onOk={() => {
          setSettings(initialSettings)
          setShowWarningResetModal(false)
        }}
        onCancel={() => setShowWarningResetModal(false)}
      >
        {intl.formatMessage({
          id: 'configuration_reset_warning_copy',
        })}
      </Modal>
    </>
  )
}
export default injectIntl(Configuration)
