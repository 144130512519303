import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { install } from 'redux-loop';

import { setupIntl } from '../intl';  // NOTICE: 'intl' is a node package
import createRootReducer from 'reducers';

const history = createBrowserHistory();

const middlewares = [ /* Add middlewares here */ ];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  intl: setupIntl(),
};

export default function configureStore() {
  const store = createStore(
    createRootReducer (history),
    initialState,
    composeEnhancers(install(), applyMiddleware(...middlewares))
  );

  if (module.hot) {
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };


