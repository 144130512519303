import React, { useCallback, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import PermissionLogo from 'components/PermissionLogo';
import LevelsPicker from 'components/LevelsPicker';
import {
  levelToBackendLevel,
  allBackendLevelsUntil,
} from 'utils/validation';

import './style.less';

import { CustomCheckbox } from 'aplanet-ui-kit';
import {
  Col,
  Row,
  Modal,
  Tabs,
  Radio,
  Checkbox,
  Divider,
  Form,
} from 'antd';
import CustomWarningModal from 'components/CustomWarningModal';

const DEFAULT_APPROVAL_LEVELS = 1;

const BOOLEAN_PERMISSIONS = {
  atlas: [
    'can_read_kpi',
    'can_write_kpi',
    'can_request_kpi',
    'can_report_kpi',
    'can_configure_kpi',
    'can_undo_validate_kpi',
    'can_validate_kpi',
    'can_export_kpis',
    'can_import_kpis',
    'can_manage_variables_configuration',
  ],
  community: [
    'can_read_initiatives',
    'can_read_reports',
    'can_manage_participation',
    'can_manage_collaborations',
    'can_manage_proposals',
    'can_manage_initiatives',
    'can_manage_volunteers',
  ],
  general: [
    'can_manage_configuration',
    'can_manage_permissions',
  ],
};

const MANDATORY_PERMISSIONS = {
  atlas: ['can_read_kpi'],
  community: ['can_read_initiatives'],
  general: [],
};

const ManagePermissions = ({
  intl,
  visible,
  pushing,
  onSubmit,
  onCancel,
  member,
  organization,
  allowedProducts,
  currentUser,
  is_system
}) => {
  const t = intl.messages;

  const approvalLevels = useMemo(() => {
    return (organization.settings.atlas || {}).approval_levels || DEFAULT_APPROVAL_LEVELS;
  }, [
    organization,
  ]);
  
  const [values, setValues] = useState(
    member.details?.is_auditor
      ? { ...member }
      : {
          details: {...(member.details || {})},
          atlas_permission: {
            ...member.atlas_permission,
            can_read_kpi: true,
          },
          community_permission: {
            ...member.community_permission,
            can_read_initiatives: true,
          },
          general_permission: member.general_permission,
        }
    );
  const [showKPIDataOwnershipWarning, setShowKPIDataOwnershipWarning] = useState(false);

  const handleOnChangeApprovalLevel = useCallback(
    (value) => {
      setValues({
        ...values,
        [ `atlas_permission` ]: {
          ...values[`atlas_permission`],
          validate_kpi_level: value.map(v => levelToBackendLevel(v)).reduce((a, b) => a | b, 0),
        }
      });
    },
    [values]
  );

  const hasRestrictedAdminsPerm = useMemo(() => {
    return organization?.settings?.atlas?.features?.restricted_admins;
  }, [
    organization,
  ]);

  const togglePermission = useCallback(
    (app, permission) => {
      const newAppState = {
        ...values[`${app}_permission`],
        [ permission ]: !values[`${app}_permission`][permission],
      };

      if (app === 'atlas') {
        if (
          permission === 'can_validate_kpi'
          && !values[`atlas_permission`]['validate_kpi_level']
        ) {
          newAppState['validate_kpi_level'] = levelToBackendLevel(Number(DEFAULT_APPROVAL_LEVELS))
        }
        if (
          permission === 'can_validate_kpi'
          && values[`atlas_permission`]['can_validate_kpi']
        ) {
          newAppState['validate_kpi_level'] = 0
        }
        if (
          permission === 'can_import_kpis'
          && !values[`atlas_permission`]['can_import_kpis']
        ) {
          newAppState['can_export_kpis'] = true;
        }
        if (
          permission === 'can_affect_all_kpis'
          && values[`atlas_permission`]['can_affect_all_kpis']
        ) {
          newAppState['can_manage_variables_configuration'] = false;
          newAppState['can_report_kpi'] = false;
        }
      }

      setValues({
        ...values,
        [ `${app}_permission` ]: newAppState,
      })
    },
    [values]
  );

  const kpiOwnerPermissionsDisabled = useMemo(
    () => {
      if (
        member?.kpi_data_ownership?.length
      ) {
        return [
          'can_write_kpi',
          'can_validate_kpi',
        ].filter(
          permission => member.atlas_permission[permission]
            && !values.atlas_permission[permission]
        );
      }
      return [];
    },
    [
      values,
      member,
    ]
  );

  const handleSubmit = useCallback(
    () => {
      if (
        !showKPIDataOwnershipWarning &&
        member?.kpi_data_ownership?.length &&
        kpiOwnerPermissionsDisabled.length
      ) {
        setShowKPIDataOwnershipWarning(true);
      } else {
        onSubmit(values);
        onCancel();
      }
    },
    [
      onSubmit,
      onCancel,
      values,
      member,
      showKPIDataOwnershipWarning,
      kpiOwnerPermissionsDisabled,
    ]
  );

  const permissionDisabled = useCallback((app, permission) => {
    const hasPermission =  MANDATORY_PERMISSIONS[app].includes(permission);
    return is_system ? 
      hasPermission : 
      (hasPermission || !((currentUser[`${app}_permission_inherit`] || {})[permission] || currentUser[`${app}_permission`][permission]));
  }, [currentUser, is_system]);

  const mandatoryPermissions = useMemo(
    () => {
      let permissions = MANDATORY_PERMISSIONS;
      if (values.details?.is_auditor) {
        permissions = {
          atlas: [
            'can_read_kpi',
            'can_request_kpi',
            'can_undo_validate_kpi',
            'can_validate_kpi',
          ],
          community: [],
          general: [],
        };
      }
      if(values.atlas_permission.can_import_kpis) {
        if (!permissions.atlas.includes('can_export_kpis')){
          permissions.atlas.push('can_export_kpis');
        }
      } else {
        permissions.atlas = permissions.atlas.filter(p => p !== ('can_export_kpis'));
      }
      return permissions;
    }, [
      values,
    ]
  );

  const disabledPermissions = useMemo(
    () => {
      if (values.details?.is_auditor) {
        return {
          atlas: [
            'can_read_kpi',
            'can_write_kpi',
            'can_request_kpi',
            'can_report_kpi',
            'can_configure_kpi',
            'can_export_kpis',
            'can_import_kpis',
            'can_manage_variables_configuration',
          ],
          community: [
            'can_read_initiatives',
            'can_read_reports',
            'can_manage_participation',
            'can_manage_collaborations',
            'can_manage_proposals',
            'can_manage_initiatives',
            'can_manage_volunteers',
          ],
          general: [
            'can_manage_configuration',
            'can_manage_permissions',
          ],
        };
      } else if (!values.atlas_permission.can_affect_all_kpis) {
        return {
          atlas: [
            'can_report_kpi',
            'can_manage_variables_configuration',
          ],
        };
      }
      return {};
    },
    [
      values.details,
      values.atlas_permission,
    ]
  );

  const handleOnToggleAuditor = useCallback(
    e => {
      let newValues = {
        ...values,
        details: {
          ...(values.details || {}),
          is_auditor: e.target.checked,
        },
      };

      if (e.target.checked) {
        newValues = {
          ...newValues,
          general_permission: Object.fromEntries(
            Object.entries(newValues.general_permission || {})
              .map(([permission, value]) => [permission, permission === 'id' ? value : false])
          ),
          community_permission: Object.fromEntries(
            Object.entries(newValues.community_permission || {})
              .map(([permission, value]) => [permission, permission === 'id' ? value : false])
          ),
          atlas_permission: allowedProducts.includes('atlas')
            ? {
                ...Object.fromEntries(
                  Object.entries(newValues.atlas_permission || {})
                    .map(([permission, value]) => [permission, permission === 'id' ? value : false])
                ),
                can_read_kpi: true,
                can_undo_validate_kpi: true,
                can_request_kpi: true,
                can_validate_kpi: true,
                validate_kpi_level: allBackendLevelsUntil(Number(approvalLevels)),
              }
            : {}
        };
      } else {
        newValues = {
          ...newValues,
          general_permission: {},
          community_permission: allowedProducts.includes('community')
            ? { can_read_initiatives: true }
            : {},
          atlas_permission: allowedProducts.includes('atlas')
            ? { can_read_kpi: true }
            : {}
        };
      }
      setValues(newValues);
    },
    [
      allowedProducts,
      values,
      approvalLevels,
    ]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText={t.organization_profile_save}
      title={t.organization_profile_manage_permissions}
      className="ManagePermissions"
      okButtonProps={{ disabled: pushing }}
      cancelButtonProps={{ disabled: pushing }}
      closable={!pushing}
    >
      { allowedProducts.includes('atlas') && organization.settings?.atlas?.can_add_auditors &&
        <Row>
          <Col>
            <Form.Item
              label={
                <span className="AddAdmin-label">
                  {t.organization_profile_is_auditor}
                </span>
              }
              hasFeedback
              colon={false}
            >
              <CustomCheckbox
                checked={values.details?.is_auditor}
                onChange={handleOnToggleAuditor}
              />
            </Form.Item>
          </Col>
        </Row>
      }
      <Row>
        <Col span={24}>
          <Tabs>
            <Tabs.TabPane
              tab={ t.organization_profile_permissions_general }
              key="general"
            >
              <Row gutter={10}>
                {
                  BOOLEAN_PERMISSIONS.general.map(permission => (
                    <Col key={permission} span={24}>
                      <Checkbox
                        checked={values.general_permission[permission]}
                        onChange={() => togglePermission('general', permission)}
                        disabled={ mandatoryPermissions.general.includes(permission) || disabledPermissions.general?.includes(permission) || permissionDisabled('general', permission) }
                      >
                        <PermissionLogo
                          className="ManagePermissions-permission-logo"
                          permission={permission}
                          app="general"
                        />
                        { t[`permission_general_${permission}`] }
                      </Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Tabs.TabPane>
            { allowedProducts.includes('community')
              ? <Tabs.TabPane
                  tab={ t.organization_profile_permissions_community }
                  key="community"
                >
                  <Row gutter={10}>
                    {
                      BOOLEAN_PERMISSIONS.community.map(permission => (
                        <Col key={permission} span={24}>
                          <Checkbox
                            checked={values.community_permission[permission]}
                            onChange={() => togglePermission('community', permission)}
                            disabled={ mandatoryPermissions.community.includes(permission) || disabledPermissions.community?.includes(permission) || permissionDisabled('community', permission) }
                          >
                            <PermissionLogo
                              className="ManagePermissions-permission-logo"
                              permission={permission}
                              app="community"
                            />
                            { t[`permission_community_${permission}`] }
                          </Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                </Tabs.TabPane>
              : null
            }
            { allowedProducts.includes('atlas')
              ? <Tabs.TabPane
                  tab={ t.organization_profile_permissions_atlas }
                  key="atlas"
                >
                  <Row gutter={10}>
                    { hasRestrictedAdminsPerm &&
                    <Col span={24}>
                      <h4>{t.permission_atlas_can_affect_all_kpis}:</h4>
                      <Radio.Group
                        value={values.atlas_permission?.can_affect_all_kpis || false}
                        onChange={() => togglePermission('atlas', 'can_affect_all_kpis')}
                      >
                        <Radio
                          value={true}
                          style={{ display: 'block' }}
                        >
                          {t.permission_atlas_can_affect_all_kpis_yes}
                        </Radio>
                        <Radio
                          value={false}
                          style={{ display: 'block' }}
                        >
                          {t.permission_atlas_can_affect_all_kpis_no}
                        </Radio>
                      </Radio.Group>
                      <Divider />
                    </Col>
                    }
                    {
                      BOOLEAN_PERMISSIONS.atlas.map(permission => (
                        <Col key={permission} span={24}>
                          <Checkbox
                            checked={values.atlas_permission[permission]}
                            onChange={() => togglePermission('atlas', permission)}
                            disabled={ mandatoryPermissions.atlas.includes(permission) || disabledPermissions.atlas?.includes(permission) || permissionDisabled('atlas', permission) }
                          >
                            <PermissionLogo
                              className="ManagePermissions-permission-logo"
                              permission={permission}
                              app="atlas"
                            />
                            { t[`permission_atlas_${permission}`] }
                          </Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                  { values.atlas_permission.can_validate_kpi
                    ? 
                      <LevelsPicker 
                        kpi_level={values.atlas_permission.validate_kpi_level}
                        onChange={handleOnChangeApprovalLevel}
                        t={t}
                        organization={organization}
                      />
                    : null
                  }
                </Tabs.TabPane>
              : null
            }
          </Tabs>
        </Col>
      </Row>
      <CustomWarningModal
        key={kpiOwnerPermissionsDisabled}
        showModal={showKPIDataOwnershipWarning}
        onOk={handleSubmit}
        onCancel={() => setShowKPIDataOwnershipWarning(false)}
        onOkText={intl.formatMessage({id: 'organization_profile_change_permission_warning_modal_button_ok'})}
        onCancelText={intl.formatMessage({id: 'organization_profile_change_permission_warning_modal_button_cancel'})}
        title={`${intl.formatMessage({id: 'organization_profile_change_permission_warning_modal_title'})} ${member.name}`}
        content={
          <Row gutter={[0, 10]}>
            <Col span={24}>
              {intl.formatMessage({id: 'organization_profile_change_permission_warning_modal_content'})}
            </Col>
            <Col span={24}>
              <b>
                {intl.formatMessage({id: 'organization_profile_change_permission_warning_modal_content_bold'})}
              </b>
            </Col>
            <Col span={24}>
              <ul>
                { kpiOwnerPermissionsDisabled.map(permission =>
                  <li key={permission}>{intl.formatMessage({id: `permission_atlas_${permission}`})}</li>
                )}
              </ul>
            </Col>
          </Row>
        }
        icon="exclamation-triangle"
      />
    </Modal>
  )
}

export default injectIntl(ManagePermissions);
