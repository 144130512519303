import React from 'react';
import './style.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomIcon = ({className, icon, iconProps, style}) => {
  return (
    <FontAwesomeIcon className={`CustomIcon ${className || ""}`} style={style} icon={icon} {...iconProps}/>
  );
}

export default CustomIcon;
