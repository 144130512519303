import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

const RedirectIfNotOrgSelected = ({
  computedMatch,
  location,
  loading,
  organization,
  setOrganization,
  adminMemberships,
  render,
  to,
  ...props
}) => {
  const history = useHistory()
  const { slug } = computedMatch.params
  const [renderComponent, setRenderComponent] = useState(false)
  const isThereAnOrgSelected = useMemo(() => !!organization, [organization])
  const isPossibleToFigureOutOrg = useMemo(
    () => (adminMemberships && adminMemberships.length === 1) || slug,
    [adminMemberships, slug]
  )

  useEffect(
    () => {
      if(!loading) {
        if (isThereAnOrgSelected) {
          setRenderComponent(true)
        } else if (isPossibleToFigureOutOrg){
          setOrganization(slug || adminMemberships[0].slug)
        } else {
          history.push(`${to}${location.search}`)
        }
      }
    },
    //eslint-disable-next-line
    [isThereAnOrgSelected, isPossibleToFigureOutOrg, slug, to, location, loading, adminMemberships]
  )
  if (renderComponent) {
    return render({computedMatch, location, ...props});
  }

  return null
}

export default RedirectIfNotOrgSelected
