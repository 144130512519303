import {
  toDate,
  parse,
  subWeeks,
  subMonths,
  isSameDay,
  differenceInHours,
  differenceInDays,
  formatDistanceToNow as distanceInWordsToNow,
  getDate,
  format,
  isAfter,
  isBefore,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  isWithinInterval
} from 'date-fns';
import moment from 'moment';

const getLocale = (intl) => ((intl && intl.formats) || {}).dateFns;

const MONTH_SHORT = 'MMM';
const MONTH_YEAR = 'MMM YYYY';
const TIME_ONLY = 'HH:mm';
const SHORT_FORMAT = 'D MMM HH:mm';
export const SHORT_FORMAT_WITH_YEAR = 'D MMM YYYY HH:mm';
const LONG_FORMAT = 'ddd, D MMMM YYYY H:mm[h]';
const SHORT_DATE_ONLY_FORMAT = 'MMM. D';
const SHORT_MEDIUM_DATE_ONLY_FORMAT = 'MMMM. D';
export const BETWEEN_DOTS_DATE_FORMAT = 'DD.MMM.YYYY';
export const DATE_ONLY = 'D MMMM YYYY';
export const DB_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const DB_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME = 'D MMMM YYYY HH:mm';

export const fromDb = str => toDate(str);

export const formatDate = (time, formatStr = DATE_ONLY, intl) => {
  const d = fromDb(time);

  return format(d, formatStr, { locale: getLocale(intl) });
};

export const formatDateOnly = (date, formatStr = DATE_ONLY, intl) => {
  const d = fromDbDate(date);

  return format(d, formatStr, { locale: getLocale(intl) });
};

export const isPassed = (time) => {
  time = new Date(time)
  return time.getTime() - new Date().getTime() < 0
}

export const fromDbDate = (str) => parse(str, DB_FORMAT,new Date());

export const totalDays = (start_time, end_time) => {
  return 1 + differenceInDays(end_time, start_time);
}

export const totalCalendarDays = (start_time, end_time) => {
  return differenceInCalendarDays(end_time, start_time);
}

export const daysLeft = (time) => {
  return differenceInDays(new Date(), time)
}

export const toDateArray = (start_time, end_time, intl) => {
  const start = fromDb(start_time);
  const end = fromDb(end_time);

  if(isSameDay(start, end)) {
    return [{
      day: getDate(start),
      month: format(start, MONTH_SHORT, { locale: getLocale(intl) }),
    }];
  } else {
    return [{
      day: getDate(start),
      month: format(start, MONTH_SHORT, { locale: getLocale(intl) }),
    },{
      day: getDate(end),
      month: format(end, MONTH_SHORT, { locale: getLocale(intl) }),
    }];
  }
};

export const hoursPerDay = (start_time, end_time, hours_per_day = 8) => {
  const start = fromDb(start_time);
  const end = fromDb(end_time);

  if(isSameDay(start, end)) {
    return [differenceInHours(end, start), false];
  } else if(hours_per_day > 0) {
    return [hours_per_day, true];
  } else {
    return [];
  }
};

export const formatDateAndTime = (time, intl) => {
  const d= fromDb(time);

  return {
    date: format(d, DATE_ONLY, { locale: getLocale(intl) }),
    time: format(d, TIME_ONLY, { locale: getLocale(intl) })
  }
};

export const formatShort = (time, intl) => {
  const d= fromDb(time);

  return format(d, SHORT_FORMAT, { locale: getLocale(intl) });
};

export const formatShortWithYear = (time, intl) => {
  const d= fromDb(time);

  return format(d, SHORT_FORMAT_WITH_YEAR, { locale: getLocale(intl) });
};

export const formatLong = (time, intl) => {
  const d= fromDb(time);

  return format(d, LONG_FORMAT, { locale: getLocale(intl) });
};

export const formatDateLong = (time, intl) => {
  const d= fromDb(time);

  return format(d, DATE_ONLY, { locale: getLocale(intl) });
};

export const formatDateShort = (time, intl) => {
  const d= fromDb(time);

  return format(d, SHORT_DATE_ONLY_FORMAT, { locale: getLocale(intl) });
};

export const formatDistanceToNow = (date,intl) => {
  const d = fromDbDate(date);

  return distanceInWordsToNow(d,{
    locale: getLocale(intl),
    addSuffix:true,
    includeSeconds: true
  });

}
export const formatDateShortMedium = (time, intl) => {
  const d= fromDb(time);

  return format(d, SHORT_MEDIUM_DATE_ONLY_FORMAT, { locale: getLocale(intl) });
};

export const isAfterNow = (time) => {
  const d = fromDb(time);

  return isAfter(d, new Date());
};

export const isBeforeNow = (time) => {
  const d = fromDb(time);

  return isBefore(d, new Date());
};

export const isDayBefore = (_a, _b) => {
  const a = fromDb(_a);
  const b = fromDb(_b);

  return !isSameDay(a, b) && isBefore(a, b);
};

export const isBetween = (time, start, end ) => {
  if (Array.isArray(start) && !end ){
    end = start[1];
    start = start[0];
  }
  return isWithinInterval(fromDb(time),{start:fromDb(start),end:fromDb(end)});
}

export const toDb = (d) => format(d, DB_FORMAT);

export const toDbDate = (date) => format(date, DB_DATE_FORMAT);

export const weeksAgo = (weeks) => subWeeks(Date.now(), weeks);

export const monthsAgo = (months) => subMonths(Date.now(), months);

export const formatMonthYear = (time, intl) => {
  const d= fromDb(time);

  return format(d, MONTH_YEAR, { locale: getLocale(intl) });
};

export const formatDb = (time) => {
  const d= fromDb(time);
  return toDb(d);
};

export const rangeOverlap = ([start_a, end_a], [start_b, end_b]) =>
areIntervalsOverlapping(
    {start:fromDb(start_a) ,end: fromDb(end_a)},
    {start: fromDb(start_b),end: fromDb(end_b)}
  );

export const guessGroupBy = (start_date, end_date) => {
  let result = 'day';
  const start = new Date(start_date);
  const end = new Date(end_date);

  const diff = differenceInDays(end, start);

  if(diff && diff > 14) {
    result = 'week';
  }

  if(diff && diff > 62) {
    result = 'month';
  }

  if(diff && diff > 730) {
    result = 'year';
  }

  return result;
};

export const dateToDb = (d) => format(d, DB_DATE_FORMAT);

export const formatDateMoment = (time) => {
  if (!time) {
    return;
  }
  return moment(time, DB_FORMAT);
};

export { addMonths, getYear } from 'date-fns';

export const toBetweenDotsDateFormat = (date, intl) => format(date, BETWEEN_DOTS_DATE_FORMAT, { locale: getLocale(intl) });

export const getPeriodsFormat = (startDate, endDate, intl) => `${toBetweenDotsDateFormat(startDate, intl)} - ${toBetweenDotsDateFormat(endDate, intl)}`;
