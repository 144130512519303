import React, {
  useCallback,
  useMemo,
} from 'react';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  UnlockOutlined,
} from '@ant-design/icons';

import {
  getAppFromQueryString,
} from 'utils/clientUrl';

import {
  login,
  resetAuth,
  resetAuthError,
  recoverPassword,
} from 'actions/auth';

import useForm from 'utils/useForm';
import validation from './validation';
import A from 'components/A';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';
import './style.less';

const PasswordForm = ({
  intl,
  location,
}) => {
  const t = intl.messages;

  const validateForm = useMemo(() => validation(t), [ t ]);

  const dispatch = useDispatch();
  const {
    error: serverErrorCode,
    errorBody: serverErrorDetail,
    loading,
    email,
  } = useSelector(state => state.signin);

  const {
    requested: recoverPasswordRequested,
    loading: recoverPasswordLoading,
    error: recoverPasswordError,
  } = useSelector(state => state.password_recovery);

  const submitForm = () => {
    dispatch( resetAuthError() );
    dispatch(
      login(
        email,
        values.password,
        getAppFromQueryString(location.search)
      )
    );
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });

  const passwordError = (
    (serverErrorCode || errors.password) &&
    !isDirty('password')
  ) && (
    t[`signin_error_${serverErrorCode}_${serverErrorDetail?.error || ''}`] ||
    t[`signin_error_${serverErrorCode}`] ||
    errors.password ||
    t.signin_error_default
  );

  const handlePasswordChange = useCallback((e) => {
    e.preventDefault();
    if(passwordError) {
      dispatch( resetAuthError() );
    }
    handleChange(e.target.name)(e.target.value);
  }, [
    dispatch,
    handleChange,
    passwordError,
  ]);

  const handleCancel = useCallback(() => {
    dispatch( resetAuth() );
  }, [
    dispatch,
  ]);

  const handleRecoverPassword = useCallback(() => {
    if(!recoverPasswordLoading) {
      dispatch(
        recoverPassword(email)
      );
    }
  }, [
    recoverPasswordLoading,
    dispatch,
    email,
  ]);

  return (
    <article className="PasswordForm">
      <h1
        className="PasswordForm__heading"
      >{ t.passwordform_title }</h1>
      <p
        className="PasswordForm__subheading"
      >{ email }</p>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          hasFeedback
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
          colon={false}
          label={t.passwordform_label}
        >
          <CustomInput.Password
            prefix={<UnlockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            autoFocus
            value={values.password}
            onChange={handlePasswordChange}
            disabled={loading}
          />
        </Form.Item>
        <Row
          type="flex"
          justify="end"
        >
          <Col>
            <div className="PasswordForm__recover_password_line">
              <A
                onClick={handleRecoverPassword}
              >
                { t.passwordform_forgot_password }
              </A>
            </div>
            <div className="PasswordForm__recover_password_line">
              {
                recoverPasswordError
                ? (
                  <div className="PasswordForm__recover_password_error">
                    <span role="img" aria-label="ERROR">&#10060;</span>
                    { ' ' }
                    { t.passwordform_recover_link_error }
                  </div>
                )
                : recoverPasswordRequested
                ? (
                  <div className="PasswordForm__recover_password_sent">
                    <span role="img" aria-label="OK">&#10004;</span>
                    { ' ' }
                    { t.passwordform_recover_link_sent }
                  </div>
                )
                : null
              }
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
        >
          <Col>
            <CustomButton
              onClick={handleCancel}
              disabled={loading}
            >
              { t.passwordform_cancel }
            </CustomButton>
          </Col>
          <Col>
            <Form.Item>
              <CustomButton
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                { t.passwordform_submit }
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </article>
  )
}

export default injectIntl(
  withRouter(
    PasswordForm
  )
);
