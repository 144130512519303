import React, {
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useDispatch,
} from 'react-redux';

import {
  logout,
} from 'actions/auth';

import './style.less';

const Logout = ({
  intl,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Loggin out...');
    dispatch( logout() );
  }, [ dispatch ]);

  return (
    <div>
      { t.waiting_for_logout }
    </div>
  )
}

export default injectIntl(Logout);
