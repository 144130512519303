import React from 'react';
import { Select } from 'antd';
import './style.less';

const CustomSelect = (props) => (
  <Select className='CustomSelect' {...props}/>
)

CustomSelect.Option = (props) => (
  <Select.Option className='CustomSelect__option' {...props}/>
)

export default CustomSelect
