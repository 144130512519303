// COPIED FROM organization_detailed, CAN BE IMPROVED

import { Cmd, loop } from 'redux-loop';
import { getConfiguration, requestOrganizationDetail } from '../actions/api';

const TARGET = 'organization_configuration'

//const isTarget = (target) => [TARGET, MEMBER_TARGET].includes(target);
const isTarget = (target) => target === TARGET

const initialState = {
  fetching: false,
  pushing: false,
  data: null,
  error: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state
      return {
        ...state,
        data: action.method === 'GET' ? null : state.data,
        fetching: action.method === 'GET' || state.fetching,
        pushing: action.method !== 'GET' || state.pushing,
      }
    case 'API_CALL_COMPLETE':
      if (!action.response || !isTarget(action.response.target)) return state
      switch (action.response.method) {
        case 'PUT':
          return loop({
            ...state,
            fetching: false,
            pushing: false,
            error: null,
          }, Cmd.list([
            Cmd.action(getConfiguration(action.response.organization_slug, action.response.suborganization_slug)),
            Cmd.action(requestOrganizationDetail(action.response.organization_slug, action.response.suborganization_slug))
          ]))
        case 'GET':
          return {
            ...state,
            data: { ...state.data, ...action.response.result },
            fetching: false,
            pushing: false,
            error: null,
          }
        default:
          return {
            ...state,
            data: action.response.result,
            fetching: false,
            pushing: false,
            error: null,
          }
      }

    case 'API_CALL_FAILED':
      if (!action.request || !isTarget(action.request.target)) return state
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code,
      }
    case 'REFRESH_TOKEN_FAILED':
      return initialState
    default:
      return state
  }
}

export { reducer as organization_configuration }
