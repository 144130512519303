import { EMAIL_REGEXP } from 'utils/validation';


const addAdminValidation = (
  intl
) => ({
  name,
  email,
}) => {
  const t = intl.messages;
  let errors = {};
  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!email) {
    errors.email = t.form_error_required;
  }
  else if (!EMAIL_REGEXP.test(email)) {
    errors.email = t.form_error_invalid_email;
  }

  return errors;
};

const createEditOrgValidation = (
  t
) => ({
  name,
  industry_id,
  logo,
  logo_small,
  region,
  language,
  address,
  default_latitude,
  default_longitude,
  product_config = {},
  isAplanetAdmin = false
}) => {
  const { atlas: has_atlas, community: has_community } = product_config;
  const atlas = has_atlas || {};
  const community = has_community || {};
  const { preferred_languages: atlas_preferred_languages = [] } = atlas;
  const { preferred_languages: community_preferred_languages = [] } = community;
  let errors = {};
  if (!name || name === '') {
    errors.name = t.form_error_required;
  }
  if (!industry_id) {
    errors.industry_id = t.form_error_required;
  }
  if (!logo || logo === '') {
    errors.logo = t.form_error_required;
  }
  if (!logo_small || logo_small === '') {
    errors.logo_small = t.form_error_required;
  }
  if (region && region.length !== 2) {
    errors.region = t.alpha_2_code_error;
  }
  if (!region || region === '') {
    errors.region = t.form_error_required;
  }
  if (!language || language === '') {
    errors.language = t.form_error_required;
  }
  if (!address || address === '') {
    errors.address = t.form_error_required;
  }
  if (!default_latitude) {
    errors.default_latitude = t.form_error_required;
  }
  if (!default_longitude) {
    errors.default_longitude = t.form_error_required;
  }
  if (isAplanetAdmin && (!product_config || !Object.keys(product_config).length)) {
    errors.product_config = t.form_error_required;
  }
  if (isAplanetAdmin && has_atlas && (!atlas_preferred_languages.includes(language))) {
    errors.atlas_preferred_languages = t.form_error_preferred_language;
  }
  if (isAplanetAdmin && has_community && (!community_preferred_languages.includes(language))) {
    errors.community_preferred_languages = t.form_error_preferred_language;
  }
  return errors;
};

const emailTemplateValidation = (
  intl
) => ({
  subject,
  headerText,
  heading,
  content,
  buttonText,
}) => {
  const t = intl.messages;
  let errors = {};
  if (!subject) {
    errors.subject = t.form_error_required;
  }
  if (!headerText) {
    errors.headerText = t.form_error_required;
  }
  if (!heading) {
    errors.heading = t.form_error_required;
  }
  if (!content) {
    errors.content = t.form_error_required;
  }
  if (!buttonText) {
    errors.buttonText = t.form_error_required;
  }

  return errors;
};

export {
  addAdminValidation,
  createEditOrgValidation,
  emailTemplateValidation,
};
