import React, {
  useMemo,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';

import DatePicker from 'components/CustomDatepicker';
import A from 'components/A';

import moment from 'moment';

import './style.less';

const momentDateToCycleDate = (momentDate) => {
 return `${String(momentDate.month()+1).padStart(2, '0')}-${String(momentDate.date()).padStart(2, '0')}`
};

const disabledDate = (currentYear, disabledDates, enabledDate) => (date) => {
  const d = moment(date);
  const cycleDate = momentDateToCycleDate(d);

  return (
    d.date() >= 29 || (
      (disabledDates || []).includes(cycleDate) && (
        !enabledDate || enabledDate !== cycleDate
      )
    )
  );
};

const currentYear = (new Date()).getFullYear();

const EMPTY_CYCLE_DATES = [];

const dateSortFunc = (a, b) => {
  if(!a) return 1;
  if(!b) return -1;

  return a > b ? 1 : -1;
};

const CycleDateInput = ({
  intl,
  onChange,
  error,
  value,
  undeletable = [],
}) => {
  const cycle_dates = useMemo(() => value
    ? [ ...value ] // Cheap clone
      .sort(dateSortFunc)
      .map(date => ({
        date,
        deletable: !undeletable.includes(date),
      }))
    : EMPTY_CYCLE_DATES
  , [
    value,
    undeletable,
  ]);

  const cycle_date_values = useMemo(() => {
    return cycle_dates.map(({ date }) => date);
  }, [
    cycle_dates,
  ]);

  const handleDateChange = useCallback((index) => (value) => {
    const newValue = [
      ...cycle_dates.map(({ date }) => date).slice(0, index),
      (value ? momentDateToCycleDate(value) : null),
      ...cycle_dates.map(({ date }) => date).slice(index+1),
    ].filter(Boolean);
    onChange(newValue);
  }, [
    cycle_dates,
    onChange,
  ]);

  const handleNewDate = useCallback(() => {
    if((cycle_dates.slice(-1)[0])?.date !== '') {
      onChange([ ...(cycle_dates.map(({ date }) => date)), '']);
    }
  }, [
    cycle_dates,
    onChange,
  ]);

  return (
    <div>
      {
        cycle_dates.map((cycle_date, index) => {
          const { date, deletable } = cycle_date;
          const dateValue = date ? moment(`${currentYear}-${date} 12:00:00.000Z`) : null;
          return (
            <div
              className="CycleDateInput-picker"
            >
              <DatePicker
                allowClear={deletable && cycle_dates.length > 1}
                disabled={!deletable || cycle_dates.length === 0}
                disabledDate={disabledDate(currentYear, cycle_date_values, date)}
                value={dateValue}
                onChange={handleDateChange(index)}
                format={'D MMMM'}
                getPopupContainer={trigger => trigger.parentNode}
              />
            </div>
          );
        })
      }
      <A
        onClick={handleNewDate}
      >
        { intl.formatMessage({ id: 'organization_profile_cycle_dates_add' }) }
      </A>
    </div>
  )
}

export default injectIntl(CycleDateInput);
