import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useAdminRoles from './useAdminRoles';

const useAdminMemberships = () => {
  const {
    memberships = [],
  } = useSelector(state => state.organization);
  const ADMIN_ROLES = useAdminRoles();

  return useMemo(() => memberships.filter(m => ADMIN_ROLES.includes(m.role)), [memberships, ADMIN_ROLES]);
}

export default useAdminMemberships;
