import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganization } from '../../actions/organization';

import SignIn from 'containers/SignIn';
import Logout from 'containers/Logout';
import SsoSuccess from 'containers/SsoSuccess';
import SsoError from 'containers/SsoError';
import SetPassword from 'containers/SetPassword';
import ForgotPassword from 'containers/ForgotPassword';
import SetInitialPassword from 'containers/SetInitialPassword';
import NotFound from 'containers/NotFound';
import OrganizationProfile from 'containers/OrganizationProfile';
import OrganizationSelector from 'containers/OrganizationSelector';
import UserProfile from 'containers/UserProfile';
import RedirectIfNotAuthenticated from './RedirectIfNotAuthenticated';
import RedirectIfNotOrgSelected from './RedirectIfNotOrgSelected';
import useAdminMemberships from '../../utils/useAdminMemberships';
import RedirectIfNotAdminRole from './RedirectIfNotAdminRole';
import RedirectToAppIfPossible from './RedirectToAppIfPossible';
import { appAdminURLs, getRedirectUrl } from '../../utils/clientUrl';


const Routes = () => {
  const dispatch = useDispatch();
  const adminMemberships = useAdminMemberships()
  const {
    logged_in,
  } = useSelector(state => state.auth);
  const {
    fetching: loadingOrg,
    data: organization
  } = useSelector(state => state.organization);

  return (
    <Switch>
      <Redirect exact from="/" to="/organization" />
      <Route path="/signin" component={SignIn} />
      <Route path="/logout" component={Logout} />
      <Route path="/sso/success" component={SsoSuccess} />
      <Route path="/sso/error/:code" component={SsoError} />
      <Route path="/recover_password/:token" component={SetPassword} />
      <Route path="/recover_password" component={ForgotPassword} />
      <Route path="/(confirm_email|signup)/:token" component={SetInitialPassword} />
      <RedirectIfNotAuthenticated
        to="/signin"
        isLoggedIn={logged_in}
        path="/"
        render={() => (
          <RedirectIfNotAdminRole
            to={getRedirectUrl("community")}
            path="/"
            adminMemberships={adminMemberships}
            loading={loadingOrg}
            render={() => (
              <Switch>
                <Route
                  path="/organizations"
                  component={OrganizationSelector}
                />
                <RedirectIfNotOrgSelected
                  to="/organizations"
                  loading={loadingOrg}
                  adminMemberships={adminMemberships}
                  organization={organization}
                  setOrganization={(slug) => dispatch(setOrganization(slug))}
                  path="/organization/:slug?"
                  render={(props) => (
                    <RedirectToAppIfPossible
                      loading={loadingOrg}
                      adminMemberships={adminMemberships}
                      organization={organization}
                      {...props}
                      apps={appAdminURLs}
                      render={(props) => <OrganizationProfile {...props} />}/>
                  )}
                />
                <RedirectIfNotOrgSelected
                  to="/organizations"
                  loading={loadingOrg}
                  adminMemberships={adminMemberships}
                  organization={organization}
                  setOrganization={(slug) => dispatch(setOrganization(slug))}
                  path="/profile/:slug?"
                  render={(props) => <UserProfile {...props} />}
                />
                <Route component={NotFound} />
              </Switch>
            )}
          />
        )}
      />
    </Switch>
    )
};

export default withRouter(Routes);

