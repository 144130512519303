import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Modal, ConfigProvider } from 'antd';
import {
  PlusOutlined,
  EditOutlined
} from '@ant-design/icons';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { addOrganization, updateOrganization } from 'actions/api';
import CustomButton from 'components/CustomButton';
import CreateEditOrganizationForm from 'components/CreateEditOrganizationForm';
import { findTree } from 'utils/tree';
import useForm from 'utils/useForm';
import { createEditOrgValidation as validation } from './validation';

import './style.less';
import _ from 'lodash';

const getValuesFromOrganization = (values) => {
  const {
    address,
    default_longitude,
    default_latitude,
    industry_id,
    language,
    logo,
    logo_small,
    legal_name,
    cs_name,
    name,
    region,
    url,
    product_config = {},
    is_partner = false,
    used_cycle_dates,
    status,
    aplanet_id,
  } = values;

  const cycle_date_options = product_config?.atlas?.cycle_date_options || null;

  return {
    address,
    default_longitude,
    default_latitude,
    industry_id,
    language,
    logo,
    logo_small,
    legal_name,
    cs_name,
    name,
    region,
    url,
    product_config,
    is_partner,
    cycle_date_options,
    used_cycle_dates,
    status,
    aplanet_id
  };
};

const CreateEditOrganizationModal = injectIntl(({
  intl,
  values: orgValues = {},
  editting = false,
  is_main_org = false,
}) => {
  const t = intl.messages;

  const {
    data: organization,
  } = useSelector(state => state.organization);

  const organization_tree = useSelector(state => state.organization_tree);

  const suborganization = useMemo(
    () => {
      if(!organization_tree.tree) {
        return organization;
      }

      return findTree(
        [organization_tree.tree],
        ({ slug }) => slug === organization_tree.current_slug,
      );
    },
    [ organization, organization_tree ]
  );

  const {
    data: organizationDetail,
  } = useSelector(state => state.organization_detail);

  const {
    data: profile
  } = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const [ showNewOrgModal, setShowNewOrgModal ] = useState();
  const [logos, setLogos] = useState({logo: null, logo_small: null});

  const isAplanetAdmin = useMemo(() => editting ? profile && profile?.role === 'system' : profile && profile?.email === 'tech@aplanet.org', [editting, profile]);

  const initialValues = useMemo(() => {
    return editting ? getValuesFromOrganization(orgValues) : {}
  }, [editting, orgValues]);

  const submitForm = () => {
    let { cycle_date_options, ...payload } = _.cloneDeep(values || {});

    if(cycle_date_options && payload?.product_config?.atlas) {
      payload.product_config.atlas.cycle_date_options = cycle_date_options;
    }

    if(editting) {
      // Patching in front
      const edittedPayload = Object.keys(payload).reduce((acc, curr) => {
        let isEqualValue = false;
        isEqualValue = typeof payload[curr] === 'object' ? _.isEqual(payload[curr], initialValues[curr]) : payload[curr] === initialValues[curr];

        if (!isEqualValue) {
          acc[curr] = payload[curr];
        }
        return acc;
      }, {});

      if (!_.isEmpty(edittedPayload)) {
        dispatch(
          updateOrganization(organization.slug, suborganization.slug, edittedPayload)
        );
      }
    } else  {
      if(payload?.product_config?.atlas) {
        payload.product_config.atlas.features = {
          ...(payload.product_config.atlas.features || {}),
          restricted_admins: true,
          data_owners: true,
        };
      }

      dispatch(
        addOrganization(organization.slug, payload)
      );
      resetForm();
    }
    setShowNewOrgModal(false);
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: initialValues,
    validationDefaults: {isAplanetAdmin}
  });

  const formHasChanged = useMemo(() => {
    return editting ?
    !Object.keys(values).every(curr => typeof values[curr] === 'object' ? _.isEqual(values[curr], initialValues[curr]) : values[curr] === initialValues[curr]) :
    !_.isEmpty(values);
  }, [editting, initialValues, values]);

  const handleOnDelete = useCallback(
      (e) => {
        e.preventDefault();
        if (formHasChanged) {
          Modal.confirm({
            title: t.create_edit_organization_delete_modal_title,
            content: editting ? t.edit_organization_delete_modal_description : t.create_organization_delete_modal_description,
            okText: t.create_edit_organization_delete_modal_ok,
            cancelText: t.create_edit_organization_delete_modal_cancel,
            onOk() {
              setShowNewOrgModal(false);
              resetForm();
            },
          })
        } else {
          setShowNewOrgModal(false);
        }
      },
      [editting, formHasChanged, resetForm, t]
  );

  return (
    <>
      <CustomButton
        icon={editting ? <EditOutlined /> : <PlusOutlined />}
        onClick={() => setShowNewOrgModal(true)}
      >
        { editting ? t.edit : t.create_organization }
      </CustomButton>

      <Modal
        title={editting ? t.edit_organization : t.create_organization}
        destroyOnClose={true}
        visible={showNewOrgModal}
        onOk={handleSubmit}
        okText={editting ? t.edit : t.create}
        cancelText={t.create_edit_organization_delete_modal_cancel}
        onCancel={handleOnDelete}
        width="50%"
        okButtonProps={{icon: <CheckOutlined />}}
        cancelButtonProps={{icon: <CloseOutlined />}}
      >
        <ConfigProvider getPopupContainer={() => document.querySelector(".ant-modal-body")}>
          <CreateEditOrganizationForm
            editting={editting}
            suborganization={organizationDetail}
            organization={organization}
            is_aplanet_admin={isAplanetAdmin}
            logos={logos}
            setLogos={setLogos}
            values={values}
            handleChange={handleChange}
            handleChangeEvent={handleChangeEvent}
            errors = {errors}
            is_main_org={is_main_org}
            used_cycle_dates={initialValues?.used_cycle_dates || []}
            used_languages={organizationDetail?.used_languages || []}
          />
        </ConfigProvider>
      </Modal>
    </>
  );

});

export default injectIntl(CreateEditOrganizationModal);

