import React, {
  useCallback,
  useEffect,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  getDefaultRoute,
} from 'utils/clientUrl';

import './style.less';

const TIMEOUT = 1000; // 1 second

const SignInSuccess = ({
  intl,
  location,
  history,
}) => {
  const t = intl.messages;

  const {
    email,
  } = useSelector(state => state.signin);

  const {
    logged_in,
  } = useSelector(state => state.auth);

  const redirectToApp = useCallback(() => {
    const url = getDefaultRoute(location.search);
    history.push(url);
  }, [
    location,
    history,
  ]);

  useEffect(() => {
    if(logged_in) {
      // Redirect
      console.log('Login successful, redirecting...');
      setTimeout(
        redirectToApp,
        TIMEOUT
      );
    }
  }, [
    logged_in,
    redirectToApp,
  ]);

  return (
    <article className="SignInSuccess">
      <h1
        className="SignInSuccess__heading"
      >{ t.signin_success }</h1>
      <p
        className="SignInSuccess__subheading"
      >{ email }</p>
    </article>
  )
}

export default injectIntl(
  withRouter(
    SignInSuccess
  )
);
