import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import {
  UserOutlined,
  EditOutlined,
} from '@ant-design/icons';

import Avatar from 'components/Avatar';
import AvatarCrop from 'components/AvatarCrop';
import './style.less';

const Logo = ({
    name = '',
    src = '',
    permissions = true,
    intl,
    saveLogo,
    showLogoConfirmModal = false,
    logo = null,
    setLogo,
    small = false
}) => {
    const t = intl.messages;
    const logoInputRef = useRef(null);
    const onFileChange = (setter, ref, e) => {
        const reader = new FileReader();
        const file = e.target.files[0];

        if(!file) {
            console.log('No file!');
            return;
        }

        reader.onload = function(img) {
            ref.current.value = '';
            setter(img.target.result);
        }

        reader.readAsDataURL(file);
    };

    return (
        <div className="Logo-fileuploadwrap">
            {
                small ?
                <Avatar
                    name={name}
                    size={100}
                    shape="circle"
                    icon={<UserOutlined />}
                    src={src}
                /> :
                <img
                    className="Logo-logo"
                    alt={name}
                    src={src}
                    icon={<UserOutlined />}
                />
            }
            { permissions &&
            <>
                <input
                    className="Logo-fileupload"
                    ref={logoInputRef}
                    type="file"
                    accept="image/png"
                    onChange={(e) => onFileChange(setLogo, logoInputRef, e)}
                />
                <Avatar
                    className="Logo-plussign"
                    size="medium"
                    shape="circle"
                    icon={<EditOutlined />}
                />
            </>
            }
            {
              !showLogoConfirmModal && logo &&
              (
                small ? 
                <AvatarCrop
                    t={t}
                    src={logo}
                    onCrop={saveLogo}
                    onCancel={() => setLogo(null)}
                /> :
                <AvatarCrop
                    t={t}
                    src={logo}
                    onCrop={saveLogo}
                    ratio={null}
                    onCancel={() => setLogo(null)}
                />
              )
            }
        </div>
    );
};

export default injectIntl(Logo);