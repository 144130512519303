const lookup = (email, app) => ({
  type: 'LOOKUP_REQUEST',
  email,
  app,
});

const login = (email, password, app) => ({
  type: 'LOGIN_REQUEST',
  email,
  password,
  app,
});

const logout = () => ({
  type: 'LOGOUT_REQUEST',
});

const recoverPassword = (email) => ({
  type: 'RECOVER_PASSWORD_REQUEST',
  email,
});

const fetchPasswordPolicy = (token, token_type) => ({
  type: 'PASSWORD_POLICY_REQUEST',
  token,
  token_type,
});

const submitRecoveredPassword = (
  token,
  password,
  agreements,
  tokenType = 'recover_password',
) => ({
  type: 'SUBMIT_RECOVERED_PASSWORD_REQUEST',
  token,
  password,
  agreements,
  tokenType,
});

const resetAuth = () => ({
  type: 'RESET_AUTH',
});

const resetAuthError = () => ({
  type: 'RESET_AUTH_ERROR',
});

const refreshAccessToken = () => ({
  type: 'REFRESH_TOKEN_REQUEST',
});

const requestIdentity = () => ({
  type: 'IDENTITY_REQUEST',
});

const requestOrganizationProfile = slug => ({
  type: 'ORGANIZATION_PROFILE_REQUEST',
  slug,
});

export {
  lookup,
  login,
  logout,
  recoverPassword,
  fetchPasswordPolicy,
  submitRecoveredPassword,
  resetAuth,
  resetAuthError,
  refreshAccessToken,
  requestIdentity,
  requestOrganizationProfile,
}

