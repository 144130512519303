import React from 'react'
import { CustomSelect } from 'aplanet-ui-kit'
import { injectIntl } from 'react-intl'

const OptionSelector = ({ intl, optionValue, values, path, onChange }) => (
    <CustomSelect
        size="large"
        onSelect={(value) => onChange(path, value)}
        selected={optionValue}
        options={values.map(value => ({name: value, slug: value}))}
    />
)

export default injectIntl(OptionSelector)