import React, { useMemo } from 'react';
import AplanetIcon from 'components/AplanetIcon';
import { Col } from 'antd';
import AuthorizedLink from 'containers/AuthorizedLink';
import config from 'config';
import useOrganizations from 'utils/useOrganization';

const Notification = ({intl,content, onNavigation}) => {
    const {
        organization,
      } = useOrganizations();


    const atlasBaseUrl = useMemo(() => {
        return organization?.config?.atlas?.custom_redirect_urls?.admin || config.ATLAS_APP_URL;
      }, [
        organization,
      ]);
    const link = atlasBaseUrl + '/data/files/downloads';

    const {
        filename,
        download,
        failed,
        mimetype
        //state
    } = content;
    const stripExtension = (filename) => {
        let matches = filename.split('.');
        matches.pop();
        return matches.join('.');
    }
    return (
    <Col span={24}>
         {
            content.status === 'pending' && 
        (        
            intl.formatMessage(
                { id: 'notification_body_org_archive_pending' },
                {archive_link: <a href={link} style={{"padding":"0"}}>{filename}</a> }
            )
        )
         }
         { content.status !== 'pending' &&        
            (
                <div className='notification_body_container_org_archive'>
                    <div className='notification_body_org_archive_fileinfo'>         
                        <AplanetIcon name="File zip" />
                        {stripExtension(filename)}
                    </div>
                    { !failed && content.status !== 'deleted' &&
                        <div>
                        <AuthorizedLink
                            href={download}
                            download={filename}
                            mimeType={mimetype}
                            className="notification_body_org_archive_download_button"
                            // onClick = {()=>onDownload()}
                        >
                            <AplanetIcon name='Download' faStyle='fas' />
                            Download
                        </AuthorizedLink>
                        </div>
                    }
                {/* { failed ? 
                    intl.formatMessage(
                        { id: 'notification_body_org_archive_fail' },
                        {archive_link: <Button type='link' onClick={() => onNavigation(link)} style={{"padding":"0"}}>{filename}</Button> }
                    )
                    :
                    intl.formatMessage(
                        { id: 'notification_body_org_archive_success' },
                        {archive_link: <Button type='link' onClick={() => onNavigation(link)} style={{"padding":"0"}}>{filename}</Button> }
                    )
                } */}
                </div>
            )
         }
    </Col>
)
};

export default Notification;