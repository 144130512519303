import React from 'react';
import './style.less';
import { ReactComponent as MiniAplanetLogo } from './images/miniLogo.svg';

const OrganizationLogo = ({
  name,
  logo,
  logo_small,
  className = '',
  showPoweredBy = false,
}) => {
  return (
    <div className={`OrganizationLogo ${className}`}>
      <img src={logo} alt={name} />
      { !showPoweredBy ? null :
        <section className='poweredBy'>
          <span>{'Powered by'}</span>
          <MiniAplanetLogo/>
        </section>
      }
    </div>
  );
}

export default OrganizationLogo;
