import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import { injectIntl } from 'react-intl'
import {
  useDispatch
} from 'react-redux';

import {
  Row,
  Col,
  Form,
  Popconfirm,
  Button,
} from 'antd'

import {
  CustomInput,
  CustomButton,
  CustomTextArea,
} from 'aplanet-ui-kit';

import useForm from 'utils/useForm';
import useOrganization from 'utils/useOrganization';
import { emailTemplateValidation } from './validation';
import objectFilter from 'utils/objectFilter'

import {
  updateEmail,
} from 'actions/api';


const EDITABLE_FIELDS = [
  'subject',
  'headerText',
  'heading',
  'content',
  'buttonText',
];


const EmailTemplateForm = ({
  intl,
  emailTemplate,
}) => {
  const [textAreaKey, setTextAreaKey]= useState();

  const validateForm = useMemo(() =>
    emailTemplateValidation(intl),
    [ intl ]
  );
  const {
    organization,
    suborganization,
  } = useOrganization();
  const dispatch = useDispatch();

  const submitForm = () => {
    dispatch(
      updateEmail(
        organization.slug,
        suborganization.slug,
        emailTemplate.slug,
        emailTemplate.locale,
        values,
      )
    );
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    dirtyFields,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: objectFilter(EDITABLE_FIELDS)({
      ...emailTemplate.config, ...emailTemplate.org_config
    }),
  });

  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || null,
    [isDirty, errors]
  );

  const resetFormToDefault = useCallback(name => {
    EDITABLE_FIELDS.forEach(field => handleChange(field)(emailTemplate.config[field]))
    // CustomTextArea doesn't update after the value changes, so we assign it a
    // random key to force it to reload after updating the value.
    setTextAreaKey(Math.random());
  }, [
    emailTemplate,
    handleChange,
  ]);

  return (
    <Row>
      <Col span={24}>
        <Form layout="vertical">
          <Form.Item
            hasFeedback
            validateStatus={ showError('subject') ? 'error' : '' }
            help={ showError('subject') }
            label={intl.formatMessage({id: 'email_template_subject'})}
          >
            <CustomInput
              value={values.subject}
              placeholder={intl.formatMessage({id: 'email_template_subject_placeholder'})}
              name='subject'
              onChange={handleChangeEvent}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={ showError('headerText') ? 'error' : '' }
            help={ showError('headerText') }
            label={intl.formatMessage({id: 'email_template_headerText'})}
          >
            <CustomInput
              value={values.headerText}
              placeholder={intl.formatMessage({id: 'email_template_headerText_placeholder'})}
              name='headerText'
              onChange={handleChangeEvent}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={ showError('heading') ? 'error' : '' }
            help={ showError('heading') }
            label={intl.formatMessage({id: 'email_template_heading'})}
          >
            <CustomInput
              value={values.heading}
              placeholder={intl.formatMessage({id: 'email_template_heading_placeholder'})}
              name='heading'
              onChange={handleChangeEvent}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={ showError('content') ? 'error' : '' }
            help={ showError('content') }
            label={intl.formatMessage({id: 'email_template_content'})}
          >
            <CustomTextArea
              key={textAreaKey}
              value={values.content}
              placeholder={intl.formatMessage({id: 'email_template_content_placeholder'})}
              onChange={handleChange('content')}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={ showError('buttonText') ? 'error' : '' }
            help={ showError('buttonText') }
            label={intl.formatMessage({id: 'email_template_buttonText'})}
          >
            <CustomInput
              value={values.buttonText}
              placeholder={intl.formatMessage({id: 'email_template_buttonText_placeholder'})}
              name='buttonText'
              onChange={handleChangeEvent}
            />
          </Form.Item>
          <Row gutter={[5, 5]}>
            <Col>
              <CustomButton
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
                disabled={!dirtyFields.length}
              >
                {intl.formatMessage({id: 'email_template_save'})}
              </CustomButton>
            </Col>
            <Col>
              <Popconfirm
                title={intl.formatMessage({id: 'email_template_reset_to_default'})}
                okText={intl.formatMessage({id: 'email_template_reset_to_ok'})}
                okButtonProps={{ danger: true }}
                cancelText={intl.formatMessage({id: 'email_template_reset_to_cancel'})}
                onConfirm={resetFormToDefault}
              >
                <Button
                  type="link"
                >
                  {intl.formatMessage({id: 'email_template_reset_button'})}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default injectIntl(EmailTemplateForm)
