import React from "react";
import { Layout, Spin } from "antd";

import './style.less';

const {
  Content,
} = Layout;

const Big = () => {
  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        <div className="Loading-back">
          <Spin size="large" />
        </div>
      </Content>
    </Layout>
  )
};

const Block = () => (
  <div className="Loading__container--block">
    <Spin size="large" />
  </div>
);

const Loading = {
  Big,
  Block,
};

export default Loading;
