import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'
import { CustomModal } from 'aplanet-ui-kit';


import {
  getAllPermissions,
} from 'actions/api';

import Avatar from 'components/Avatar';
import CustomButton from 'components/CustomButton';
import CustomTable from 'components/CustomTable';
import PermissionLogo from 'components/PermissionLogo';
import AddAdmin from 'components/OrganizationProfile/AddAdmin';
import ManagePermissions from 'components/OrganizationProfile/ManagePermissions';
import SearchBox from 'components/SearchBox';
import T from 'components/T';
import AplanetIcon from 'components/AplanetIcon';
import {
  backendLevelToLevel,
} from 'utils/validation';
import _ from 'lodash';

import {
  Col,
  Row,
  Menu,
  Dropdown,
  Button,
  Select,
  Tooltip,
  Checkbox
} from 'antd';
import {
  MoreOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';


const ALL_PERMISSIONS = {
  atlas: [
    'can_read_kpi',
    'can_write_kpi',
    'can_request_kpi',
    'can_report_kpi',
    'can_configure_kpi',
    'can_undo_validate_kpi',
    'can_validate_kpi',
    'can_affect_all_kpis',
    'can_export_kpis',
    'can_import_kpis',
    'can_manage_variables_configuration',
  ],
  community: [
    'can_read_initiatives',
    'can_read_reports',
    'can_manage_participation',
    'can_manage_collaborations',
    'can_manage_proposals',
    'can_manage_initiatives',
    'can_manage_volunteers',
  ],
  general: [
    'can_manage_configuration',
    'can_manage_permissions',
  ],
};

const MEMBER_STATUS_OPTIONS = [
  'active',
  'blocked',
  'invited',
];

const Admins = ({
  intl,
  organizationId,
  organization,
  addAdmin,
  updateAdmin,
  changeAdmin,
  pushing,
  allowedProducts,
  organizationNamesById,
  isSystemAdmin,
  profileId,
  mainOrganizationSlug
}) => {
  const t = intl.messages;
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({});
  const [fullDelete, setFullDelete] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [currentMember, setCurrentMember] = useState('');

  const { data: memberPermissions } = useSelector((state) => state.permissions)
  const { data: profile } = useSelector((state) => state.profile)

  const resetState = () => {setFullDelete(false); setCurrentAction(''); setCurrentMember('')}

  const dispatch = useDispatch()

  useEffect(() => {
    if (mainOrganizationSlug && currentMember) {
      dispatch(getAllPermissions(mainOrganizationSlug, currentMember.id))
    }
  }, [dispatch, currentMember, mainOrganizationSlug])

  useEffect(() => {
    if (currentAction === 'delete' && memberPermissions.length === 1) setFullDelete(true)
  }, [currentAction, memberPermissions.length])

  const handleOnChange = useCallback(
    (member, action) => {
      setCurrentAction(action)
      setCurrentMember(member)
    },
    []
  );

  const isPermissionApplicable = useCallback((permission) => {
    switch(permission) {
      case 'can_affect_all_kpis':
        return !!organization?.settings?.atlas?.features?.restricted_admins;
      default:
        return true;
    }
  }, [
    organization,
  ]);

  const columns = useMemo(() => ([
    {
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <>
          <Row type="flex" gutter={10} justify="left" align="middle" style={{ flexWrap: 'nowrap' }}>
            <Col>
              <Avatar
                src={record.avatar}
                name={record.name}
                shape="circle"
                size={38}
                icon={<UserOutlined />}
              />
            </Col>
            <Col>
              <Row type="flex" gutter={2}>
                <Col>
                  <img
                    className="typeIcon typeIcon-img typeIcon-img-crown"
                    src='/images/crown.svg'
                    alt={t.admin}
                  />
                </Col>
                <Col>
                  { name }
                </Col>
              </Row>
              <div>
                { record.email }
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      dataIndex: 'permissions',
      key: 'permissions',
      render: (_, member) => (
        <>
          {
            allowedProducts.includes('atlas')
            ? <>
                <Row type="flex">
                  { ALL_PERMISSIONS.atlas.filter(p => member.atlas_permission[p]).length > 0
                    ? <span className="OrganizationProfile-label">
                        {t.organization_profile_permissions_atlas}:
                      </span>
                    : null
                  }
                  { ALL_PERMISSIONS.atlas.filter(p => member.atlas_permission[p]).map(permission => (
                    <PermissionLogo
                      className="OrganizationProfile-admins-permission-logo"
                      permission={permission}
                      app="atlas"
                      key={permission}
                      level={permission === 'can_validate_kpi'
                        ? backendLevelToLevel(
                            member.atlas_permission.validate_kpi_level
                          ).join()
                        : ''
                      }
                      size="sm"
                    />
                  ))}
                </Row>
                <Row type="flex">
                  { ALL_PERMISSIONS.atlas.filter(p => (member?.atlas_permission_inherit || {})[p]).length > 0
                    ? <span className="OrganizationProfile-label">
                        {t.organization_profile_permissions_atlas_inherit}:
                      </span>
                    : null
                  }
                  { ALL_PERMISSIONS.atlas.filter(p => (member?.atlas_permission_inherit || {})[p]).map(permission => (
                    <PermissionLogo
                      className="OrganizationProfile-admins-permission-logo"
                      permission={permission}
                      app="atlas"
                      key={permission}
                      level={permission === 'can_validate_kpi'
                        ? backendLevelToLevel(
                          (member?.atlas_permission_inherit || {}).validate_kpi_level
                          ).join()
                        : ''
                      }
                      size="sm"
                    />
                  ))}
                </Row>
              </>
            : null
          }
          {
            allowedProducts.includes('community')
            ? <>
                <Row type="flex">
                  { ALL_PERMISSIONS.community.filter(p => member.community_permission[p]).length > 0
                    ? <span className="OrganizationProfile-label">
                        {t.organization_profile_permissions_community}:
                      </span>
                    : null
                  }
                  { ALL_PERMISSIONS.community.filter(p => member.community_permission[p]).map(permission => (
                    <PermissionLogo
                      className="OrganizationProfile-admins-permission-logo"
                      permission={permission}
                      app="community"
                      key={permission}
                      size="sm"
                    />
                  ))}
                </Row>
                <Row type="flex">
                  { ALL_PERMISSIONS.community.filter(p => (member?.community_permission_inherit || {})[p]).length > 0
                    ? <span className="OrganizationProfile-label">
                        {t.organization_profile_permissions_community_inherit}:
                      </span>
                    : null
                  }
                  { ALL_PERMISSIONS.community.filter(p => (member?.community_permission_inherit || {})[p]).map(permission => (
                    <PermissionLogo
                      className="OrganizationProfile-admins-permission-logo"
                      permission={permission}
                      app="community"
                      key={permission}
                      size="sm"
                    />
                  ))}
                </Row>
              </>
            : null
          }
          <Row type="flex">
            { ALL_PERMISSIONS.general.filter(p => member.general_permission[p]).length > 0
              ? <span className="OrganizationProfile-label">
                  {t.organization_profile_permissions_general}:
                </span>
              : null
            }
            {
              ALL_PERMISSIONS.general.filter(p => member.general_permission[p]).map(permission => (
                <PermissionLogo
                  className="OrganizationProfile-admins-permission-logo"
                  permission={permission}
                  app="general"
                  key={permission}
                  size="sm"
                />
              ))
            }
          </Row>
          <Row type="flex">
            { ALL_PERMISSIONS.general.filter(p => (member?.general_permission_inherit || {})[p]).length > 0
              ? <span className="OrganizationProfile-label">
                  {t.organization_profile_permissions_general_inherit}:
                </span>
              : null
            }
            {
              ALL_PERMISSIONS.general.filter(p => (member?.general_permission_inherit || {})[p]).map(permission => (
                <PermissionLogo
                  className="OrganizationProfile-admins-permission-logo"
                  permission={permission}
                  app="general"
                  key={permission}
                  size="sm"
                />
              ))
            }
          </Row>
        </>
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div className={`OrganizationProfile-admins-status-${status}`}>
          { t[`organization_profile_admins_status_${status}`] }
        </div>
      ),
    },
    {
      dataIndex: 'status',
      key: 'index',
      render: (_, record) => {
        if (record.organization_id !== organizationId) {
          return (
            <Tooltip
              title={
                <T
                  organization_profile_admins_other_org={{
                    org: organizationNamesById[record.organization_id]
                  }}
                />
              }
              placement="left"
              overlayClassName="OrganizationProfile-admins-other-org-info-tooltip"
            >
              <InfoCircleOutlined
                className="OrganizationProfile-admins-other-org-info"
              />
            </Tooltip>
          );
        }

        return (
          <Dropdown
            overlay={(
              record.status === 'blocked'
                ? <Menu>
                    <Menu.Item onClick={() => handleOnChange(record, 'unblock')}>
                      {t.organization_profile_admins_unblock}
                    </Menu.Item>
                    <Menu.Item onClick={() => handleOnChange(record, 'delete')}>
                      {t.organization_profile_admins_delete}
                    </Menu.Item>
                    { isSystemAdmin && (
                      <Menu.Item onClick={() => handleOnChange(record, 'anonymize')}>
                        {t.organization_profile_admins_delete_anonymize}
                      </Menu.Item>
                    )}
                  </Menu>
                : <Menu>
                    <Menu.Item
                      onClick={() => setSelectedMember(record)}
                    >
                      {t.organization_profile_admins_manage_permissions}
                    </Menu.Item>
                    { record.status === 'invited'
                      ? <Menu.Item onClick={() => handleOnChange(record, 'reinvite')}>
                          {t.organization_profile_admins_reinvite}
                        </Menu.Item>
                      : null
                    }
                    {
                      record.id === profile.id
                      ? null
                      : <Menu.Item onClick={() => handleOnChange(record, 'block')}>
                          {t.organization_profile_admins_block}
                        </Menu.Item>
                    }
                    { (allowedProducts.length === 1 && allowedProducts[0] === 'atlas') || record.id === profile.id
                      ? null
                      : <Menu.Item onClick={() => handleOnChange(record, 'downgrade')}>
                          {t.organization_profile_admins_downgrade}
                        </Menu.Item>
                    }
                    {
                      record.id === profile.id
                      ? null
                      : <Menu.Item onClick={() => handleOnChange(record, 'delete')}>
                          {t.organization_profile_admins_delete}
                        </Menu.Item>
                    }
                    { isSystemAdmin && (
                      <Menu.Item onClick={() => handleOnChange(record, 'anonymize')}>
                        {t.organization_profile_admins_anonymize}
                      </Menu.Item>
                    )}
                  </Menu>
            )}
            placement="bottomLeft"
          >
            <Button
              type="link"
              shape="circle"
              icon={<MoreOutlined />}
            >
            </Button>
          </Dropdown>
        );
      },
    },
  ]), [t, allowedProducts, organizationId, isSystemAdmin, profile.id, organizationNamesById, handleOnChange]);

  const toggleAdminModal = useCallback(
    () => setShowAddAdminModal(!showAddAdminModal),
    [ showAddAdminModal ]
  );

  const handleOnUpdateAdmin = useCallback(
    (values) => {
      updateAdmin(
        selectedMember.slug,
        { ...values, status: selectedMember.status }
      );
      setSelectedMember();
    },
    [selectedMember, updateAdmin]
  );

  const onFilter = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [filters]
  );

  const organizationUsers = useMemo(() => [...organization.users], [organization]);

  const getInheritUsers = useCallback((filteredUsers) => {
    let users = filteredUsers ?? organizationUsers;
    const inheritUsers = users.filter(user => user.organization_id !== organizationId);
    const finalUsers = [];
    const allPermissions = {
      ...ALL_PERMISSIONS,
      atlas: [
        ...ALL_PERMISSIONS.atlas,
        'validate_kpi_level'
      ],
    };
    inheritUsers.forEach(user => {
      if (!finalUsers.some(({id}) => id === user.id)) {
        const inhUsers = inheritUsers.filter(inhUser => inhUser.id === user.id);
        Object.keys(allPermissions).forEach(app => {
          user[`${app}_permission`] = inhUsers.reduce((acc, curr) => {
            if (_.isEmpty(curr[`${app}_permission`])) return acc;
            allPermissions[app].forEach(perm => acc[perm] = acc[perm] || curr[`${app}_permission`][perm])
            return acc;
          }, {});
        });
        finalUsers.push(user);
      }
    })
    return finalUsers;
  }, [organizationId, organizationUsers]);

  const getOrgUsers = useCallback((filteredUsers) => {
    let users = filteredUsers ?? organizationUsers;
    const orgUsers = users.filter(user => user.organization_id === organizationId);
    orgUsers.forEach((user) => {
      const inhUsers = getInheritUsers(users).filter(inhUser => inhUser.id === user.id);
      Object.keys(ALL_PERMISSIONS).forEach(app => {
        user[`${app}_permission_inherit`] = inhUsers.reduce((acc, curr) => {
          if (_.isEmpty(curr[`${app}_permission`])) return acc;
          ALL_PERMISSIONS[app].forEach(perm => acc[perm] = acc[perm] || curr[`${app}_permission`][perm])
          return acc;
        }, {});
      });
    });
    return orgUsers;
  }, [getInheritUsers, organizationId, organizationUsers]);

  const currentUser = useMemo(() => {
    return (getOrgUsers() || []).find(({id}) =>
      id === profileId
    ) ??
    (getInheritUsers() || []).find(({id}) =>
      id === profileId
    )
  }, [profileId, getOrgUsers, getInheritUsers]);

  const sortedAndFilteredUsers = useMemo(
    () => {
      let users = organizationUsers;

      if (filters.searchText) {
        users = users.filter(
          user => {
            return (
              user.name.toLowerCase().includes(filters.searchText)
              || user.email.toLowerCase().includes(filters.searchText)
            );
          }
        );
      }

      if (filters.status) {
        users = users.filter(
          user => user.status === filters.status
        );
      }

      if (filters.permission) {
        const [ app, permission ] = filters.permission.split(':')
        users = users.filter(
          user => !!user[`${app}_permission`][permission] || !!(user[`${app}_permission_inherit`] || {})[permission]
        );
      }

      const orgUsers = getOrgUsers(users);
      const inhUsers = getInheritUsers(users);

      const nonRepInhUsers = orgUsers.length ?
        inhUsers.filter(inhUser => !orgUsers.map(({id}) => id).includes(inhUser.id)) : inhUsers;
      return [
        ...orgUsers,
        ...(filters.showInherited ? nonRepInhUsers : []),
      ];
    },
    [filters, getInheritUsers, getOrgUsers, organizationUsers]
  );

  return (
    <div className="OrganizationProfile-admins">
      <CustomModal
        shown = {currentMember && currentAction}
        title = {<><AplanetIcon name="Alert" faStyle="fad" size="28px" iconProps={{color: "#DAAA00"}}/>{t[`organization_profile_admins_${currentAction}_title`]}</>}
        onOk = {() => {
          changeAdmin(currentMember.slug, currentAction, {fullDelete});
          if (currentAction === 'unblock') {
            setSelectedMember(currentMember);
          }
          resetState()
        }}
        okText = {t[`organization_profile_admins_${currentAction}_okay`]}
        cancelText = {t[`organization_profile_admins_${currentAction}_cancel`]}
        afterClose = {resetState}
        onCancel = {resetState}
        width = "640"
        className = "OrganizationProfile-admins-actions-modal"
      >
        {currentAction === 'delete' && memberPermissions.length > 1
          ? (
            <>
              <span>{t[`organization_profile_admins_node_delete`]}</span>
              <br/>
              <Checkbox
                checked={fullDelete}
                onChange={e => setFullDelete(e.target.checked)}
              >
                {t[`organization_profile_admins_full_delete`]}
              </Checkbox>
              <br/>
              <span style={{color: fullDelete ? '#23302E': '#919897'}}>{t[`organization_profile_admins_${currentAction}_description`]}</span>
            </>
          )
          : t[`organization_profile_admins_${currentAction}_description`]
        }
      </CustomModal>
      <Row type="flex" justify="space-between" className="OrganizationProfile-admins-header">
        <Col span={12}>
          <SearchBox
            value={searchText}
            onChange={setSearchText}
            onSearch={(value) => onFilter('searchText', value)}
          />
        </Col>
        <Col>
          <CustomButton
            type="default"
            onClick={toggleAdminModal}
          >
            <Row type="flex" gutter={2}>
              <Col>
                <img
                  className="typeIcon typeIcon-img typeIcon-img-crown"
                  src='/images/crown.svg'
                  alt={t.admin}
                />
              </Col>
              <Col>
                { t.organization_profile_admins_add }
              </Col>
            </Row>
          </CustomButton>
          { showAddAdminModal
            ? <AddAdmin
                visible={true}
                pushing={pushing}
                onCancel={toggleAdminModal}
                onSubmit={addAdmin}
                organization={organization}
                allowedProducts={allowedProducts}
                currentUser={currentUser}
                is_system={isSystemAdmin}
              />
            : null
          }
        </Col>
      </Row>
      <Row gutter={10} className="OrganizationProfile-admins-header">
        <Col span={6}>
          <Select
            className="OrganizationProfile-admins-filter-select"
            value={filters.status}
            onChange={(value) => onFilter('status', value)}
            placeholder={t.organization_profile_admins_status}
            allowClear={true}
          >
            {
              MEMBER_STATUS_OPTIONS.map(option => (
                <Select.Option
                  key={option}
                  value={option}
                >
                  { t[`organization_profile_admins_status_${option}`] }
                </Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col span={6}>
          <Select
            className="OrganizationProfile-admins-filter-select"
            value={filters.permission}
            onChange={(value) => onFilter('permission', value)}
            placeholder={t.organization_profile_permissions}
            allowClear={true}
          >
            { allowedProducts.includes('atlas')
              ? <Select.OptGroup label={t.organization_profile_permissions_atlas}>
                  { ALL_PERMISSIONS.atlas.filter(isPermissionApplicable).map(
                      permission => {
                        return (
                          <Select.Option
                            key={permission}
                            value={`atlas:${permission}`}
                          >
                            { t[`permission_atlas_${permission}`] }
                          </Select.Option>
                        );
                      }
                    )
                  }
                </Select.OptGroup>
              : null
            }
            { allowedProducts.includes('community')
              ? <Select.OptGroup label={t.organization_profile_permissions_community}>
                  { ALL_PERMISSIONS.community.map(
                      permission => {
                        return (
                          <Select.Option
                            key={permission}
                            value={`community:${permission}`}
                          >
                            { t[`permission_community_${permission}`] }
                          </Select.Option>
                        );
                      }
                    )
                  }
                </Select.OptGroup>
              : null
            }
            <Select.OptGroup label={t.organization_profile_permissions_general}>
              { ALL_PERMISSIONS.general.map(
                  permission => {
                    return (
                      <Select.Option
                        key={permission}
                        value={`general:${permission}`}
                      >
                        { t[`permission_general_${permission}`] }
                      </Select.Option>
                    );
                  }
                )
              }
            </Select.OptGroup>
          </Select>
        </Col>
        <Col  span={6}>
        <Checkbox onChange={(value) => onFilter('showInherited', value.target.checked)}>
          {t.organization_profile_admins_show_inherited}
        </Checkbox>
      </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            showHeader={false}
            columns={columns}
            dataSource={sortedAndFilteredUsers}
            rowKey={user => `${user.id}+${user.organization_id}`}
            rowClassName={
              record => (record.organization_id === organizationId
                ? ``
                : `OrganizationProfile-admins-other-org`
              )
            }
          />
          { selectedMember
            ? <ManagePermissions
                visible={true}
                pushing={pushing}
                onCancel={() => setSelectedMember()}
                onSubmit={handleOnUpdateAdmin}
                member={selectedMember}
                organization={organization}
                allowedProducts={allowedProducts}
                currentUser={currentUser}
                is_system={isSystemAdmin}
              />
            : null
          }
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(Admins);
