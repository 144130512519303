import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect, withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import Loading from 'containers/Loading';
import MainLayout from 'components/MainLayout';
import Information from 'components/UserProfile/Information';
import Avatar from 'components/Avatar';
import AvatarCrop from 'components/AvatarCrop';
import {
  requestProfile,
  updateProfile,
} from 'actions/api';
import {
  setOrganization,
} from 'actions/organization';

import {
  Layout,
  Row,
  Col,
  Card,
  Tabs,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  UserOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import './style.less';
import CustomInput from 'components/CustomInput';

const {
  Sider
} = Layout;
const { TabPane } = Tabs;


const UserProfile = ({
  intl,
  location,
}) => {
  const t = intl.messages;
  const { slug: organizationSlug } = useParams();
  const [ currentAction, setCurrentAction ] = useState();
  const [ editingName, setEditingName ] = useState();
  const [ name, setName ] = useState();
  const [avatarImage, setAvatarImage] = useState(null);
  const fileInput = useRef(null);

  const onFileChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if(!file) {
      console.log('No file!');
      return;
    }

    reader.onload = function(img) {
      fileInput.current.value = '';
      setAvatarImage(img.target.result);
    }

    reader.readAsDataURL(file);
  };

  // NOTICE: Avatar is not part of the form
  const saveAvatar = (dataURL) => {
    handleUpdateProfile('avatar', { avatar: dataURL });
    setAvatarImage(null);
  };

  // Store and actions
  const dispatch = useDispatch();
  const {
    data: organization,
    memberships,
    changing: changingOrganization,
  } = useSelector(state => state.organization);

  const {
    data: profile,
    fetching,
    pushing,
    error,
  } = useSelector(state => state.profile);

  useEffect(
    () => {
      if (profile && profile.name) {
        setName(profile.name);
      }
    },
    [ profile ]
  );

  useEffect(() => {
    if (organization) {
      dispatch(requestProfile(organization.slug));
    }
  }, [
    organization,
    dispatch,
  ]);

  useEffect(
    () => {
      if (!organization && organizationSlug && !changingOrganization) {
        dispatch(setOrganization(organizationSlug));
      }
    },
    [ dispatch, organization, organizationSlug, changingOrganization ]
  );

  useEffect(
    () => {
      if (currentAction && !pushing) {
        if (error) {
          message.error(t[`user_profile_update_error_${currentAction}`]);
        } else {
          message.success(t[`user_profile_update_success_${currentAction}`]);
          setEditingName(false);
        }
        setCurrentAction();
      }
    },
    [ t, pushing, currentAction, error ]
  );

  const handleUpdateProfile = useCallback(
    (action, values) => {
      dispatch(
        updateProfile(organization.slug, values)
      );
      setCurrentAction(action);
    },
    [ organization, dispatch ]
  );

  const handleChangeName = useCallback(
    e => setName(e.target.value),
    []
  );

  const handleSubmitName = useCallback(
    () => handleUpdateProfile('name', { name }),
    [ name, handleUpdateProfile ]
  );

  const toggleNameInput = useCallback(
    () => setEditingName(!editingName),
    [ editingName ]
  );

  if (
    organizationSlug
    && !memberships.map(m => m.slug).includes(organizationSlug)
  ) {
    return <Redirect to="/404"/>
  }

  if (
    memberships.length > 1
    && !organization
    && (!organizationSlug || changingOrganization)
  ) {
    return <Redirect
      to={{
        pathname: "/organizations",
        state: { redirectUrl: `/profile${location.search}` }
      }}
    />
  }

  if (!organization || !profile) {
    return <Loading.Big />;
  }

  return (
    <div className="UserProfile">
      <MainLayout
        errorMessage={intl.formatMessage({id: 'error_boundary_user_profile_message'})}
      >
        <Sider>
          <Row type="flex" justify="center">
            <Col>
              <div className="UserProfile-fileuploadwrap">
                <input
                  className="UserProfile-fileupload"
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={onFileChange}
                />
                <Avatar
                  name={name}
                  size={128}
                  shape="circle"
                  icon={<UserOutlined />}
                  src={profile.avatar}
                />
                <Avatar
                  className="UserProfile-plussign"
                  size="large"
                  shape="circle"
                  icon={<PlusOutlined />}
                />
              </div>
            </Col>
          </Row>
        </Sider>
        <Layout>
          <Row>
            <Col span={24}>
              <Card className="UserProfile-card">
                <Row type="flex" justify="start">
                  <Col>
                    <span className="UserProfile-label">
                      { t.user_profile_name }
                    </span>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col>
                    <Row type="flex" align="middle" gutter={10}>
                      { editingName
                        ? <>
                            <Col>
                              <CustomInput
                                name="name"
                                value={name}
                                onChange={handleChangeName}
                              />
                            </Col>
                            <Col>
                              <Row type="flex" align="middle" gutter={5}>
                                { name
                                  ? <Col>
                                      <CheckCircleOutlined
                                        onClick={handleSubmitName}
                                      />
                                    </Col>
                                  : null
                                }
                                <Col>
                                  <CloseCircleOutlined
                                    onClick={toggleNameInput}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </>
                        : <>
                            <Col>
                              <span className="UserProfile-name">
                                { profile.name }
                              </span>
                            </Col>
                            <Col>
                              <EditOutlined onClick={toggleNameInput} />
                            </Col>
                          </>
                      }
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                  { fetching || !profile
                    ? <Loading.Block />
                    :
                      <Tabs defaultActiveKey="information" className="UserProfile-tabs">
                        <TabPane tab={t.user_profile_information} key="information">
                          <Information
                            profile={profile}
                            organization={organization}
                            updateProfile={handleUpdateProfile}
                            currentAction={currentAction}
                            error={error}
                            pushing={pushing}
                          />
                        </TabPane>
                      </Tabs>
                  }
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {
            !avatarImage ? null :
              <AvatarCrop
                t={t}
                src={avatarImage}
                onCrop={saveAvatar}
                onCancel={() => setAvatarImage(null)}
              />
          }
        </Layout>
      </MainLayout>
    </div>
  )
}

export default injectIntl(withRouter(UserProfile));