import React from 'react'
import { Modal } from 'antd'

const CustomModal = ({
  visible,
  title,
  okText,
  cancelText,
  children,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        style: {
          display: 'inline-flex',
          alignItems: 'center',
        },
      }}
      centered={true}
      destroyOnClose
    >
      {children}
    </Modal>
  )
}

export default CustomModal
