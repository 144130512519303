import React, {
  useMemo,
  useEffect,
} from 'react';
import { Row, Col, Typography, notification } from 'antd';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { reset } from 'actions/reset';

import { findTree } from 'utils/tree';
import CreateEditOrganizationModal from 'components/OrganizationProfile/CreateEditOrganizationModal';
import OrgLogos from 'components/OrgLogos';


import './style.less';

const { Text } = Typography;



const AppHeader = ({
  intl,
  location,
  history
}) => {
  const t = intl.messages;

  const isConfigPath = useMemo(() => {
    return location.pathname.includes('/organization');
  }, [location.pathname]);

  const {
    data: organization,
    error: createError,
    fetching,
    success: createSuccess
  } = useSelector(state => state.organization);
  const organization_tree = useSelector(state => state.organization_tree);

  const suborganization = useMemo(
    () => {
      if (!organization_tree.tree) {
        return organization;
      }

      return findTree(
        [organization_tree.tree],
        ({ slug }) => slug === organization_tree.current_slug,
      );
    },
    [organization, organization_tree]
  );

  const {
    data: profile
  } = useSelector(state => state.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (createError) {
      notification.error({
        message: t.error,
        description:
          t.error_creating_organization
      });
      dispatch(
        reset({ subtype: 'ERROR', target: 'organization_creation' })
      );
    }
  }, [createError, dispatch, t.error, t.error_creating_organization]);

  useEffect(() => {
    if (!createError && !fetching && createSuccess) {
      notification.success({
        message: t.success,
        description:
          t.success_creating_organization
      });
      dispatch(
        reset({ subtype: 'SUCCESS', target: 'organization_creation' })
      );
    }
  }, [createError, createSuccess, dispatch, fetching, t.success, t.success_creating_organization]);

  const isAplanetSystem = useMemo(() => profile?.email === 'tech@aplanet.org', [profile]);

  const title = useMemo(
    () => {
      if (location.pathname.includes('organization')) {
        return t.organization;
      } else if (location.pathname.includes('profile')) {
        return t.profile;
      }
    },
    [location, t]
  );

  return (
    <section className='AppHeader'>
      <Row
        type='flex'
        justify='space-between'
        align='middle'
      >
        <Col
          className="AppHeader__left"
        >
          {location.pathname.includes('profile') ? (
            <OrgLogos
              organization={suborganization}
              mainOrganization={organization}
            />
          ) : null}
          <Text className='AppHeader-appTitle'>
            {title}
          </Text>
        </Col>
        <Col
          className="AppHeader__right"
        >
          <Row
            type='flex'
            justify='end'
            align='middle'
            gutter={8}
          >
            {isConfigPath && (isAplanetSystem || organization.is_partner) &&
              suborganization?.permissions?.can_manage_configuration &&
              <Col>
                <CreateEditOrganizationModal
                  is_main_org={isAplanetSystem}
                />
              </Col>
            }
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default injectIntl(
  withRouter(
    AppHeader
  )
);
