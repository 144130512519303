import React, {useMemo} from "react";
import { injectIntl } from 'react-intl';

import { Row, Col, Divider, Button } from "antd";
import AplanetIcon from 'components/AplanetIcon';
import CustomButton from 'components/CustomButton';
import useOrganizations from 'utils/useOrganization';
import config from 'config';

import NotificationBody from "./Body";
import './style.less'

import {
    formatDistanceToNow,
  } from 'utils/date';

  
const NO_OP = ()=>{};

const Notification = ({
    intl,
    data,
    onRead = NO_OP,
    onUnread = NO_OP,
    onDelete = NO_OP,
    onNavigation = NO_OP
}) => {
    const t = intl.messages;

    const {
        id,
        title,
        created_at,
        read,
        content
    } = data;

    const {
        organization,
      } = useOrganizations();


    const atlasBaseUrl = useMemo(() => {
        return organization?.config?.atlas?.custom_redirect_urls?.admin || config.ATLAS_APP_URL;
      }, [
        organization,
      ]);

    const {linkUrl, linkTitle} = ((title) => {
        let linkUrl = '', linkTitle = '';
        switch (title) {
            case 'org_archive_completed':
                linkUrl = atlasBaseUrl + '/data/files/downloads';
                linkTitle = 'org_archive';
                break;
            // case 'data_request_answered':
            //     linkUrl = `/${content.organization.slug}/${content.kpi.slug}/${content.kpi.period}/data` // or Backend built it?
            //     linkTitle = 'indicator;
            //     break;
            default:
                break;
        }
        return {linkUrl, linkTitle};
    })(title);

    return (
        <section className={`Notification_container ${!read ? "unread" : "" }`}>
            <Row
                className="Notification__header"
                type="flex"
                justify="space-between"
            >
                <Col
                    className="Notification__header__notification__name"
                >
                    { intl.formatMessage( {id: `notification_header_title_${title}` }) }
                </Col>

                <Col className="Notification__header__notification__date">
                        {formatDistanceToNow(
                    created_at,
                    intl,
                    )}
                </Col>

            </Row>
            <Divider className="Notification__divider" />
                <NotificationBody
                    intl={intl}
                    title={title}
                    content={content}
                    onNavigation={onNavigation}
                 />
            <Divider className="Notification__divider" />
            <Row justify={ read ? "end" :"space-between"}>
                        
                <Col span={21} className="Notification__Footer__Navigation">
                { linkUrl && (
                    <>
                        { title === 'org_archive_completed' ? (
                            <a
                            href={linkUrl}>
                                <AplanetIcon name="Arrow to right" className="Notification__Footer__NavigationLink" />
                                {t[`notification_footer_navigation_link_${linkTitle}`]}                      
                            </a>
                        )
                        :(
                        <Button type="link" onClick={() => onNavigation(linkUrl)}>
                            <AplanetIcon name="Arrow to right" className="Notification__Footer__NavigationLink" />
                                {t[`notification_footer_navigation_link_${linkTitle}`]}                      
                        </Button>
                        )
                        }
                    </>
                    )
                }          
                </Col>
             
                <Col span={3} className="Notification__Footer__Buttons">

                        <CustomButton 
                            icon={<AplanetIcon name="Bin"/>} 
                            className="Notification__Footer__Buttons__delete"
                            onClick = { () => onDelete(id)}
                        />

                { !read &&    
                <CustomButton 
                            icon={<AplanetIcon name="Email read"/>} 
                            className="Notification__Footer__Buttons__read"
                            onClick = { () => onRead(id)}
                        />
                }
                { read &&    
                <CustomButton 
                            icon={<AplanetIcon faStyle="fas" name="Email notification"/>} 
                            className="Notification__Footer__Buttons__unread"
                            onClick = { () => onUnread(id)}
                        />
                }
        
                </Col>
            </Row>
        </section>
    )
};

export default injectIntl(Notification);
