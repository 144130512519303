import React, {
  useRef,
} from 'react';
import { injectIntl } from 'react-intl';

import { useHotkeys } from 'react-hotkeys-hook';

import { Input } from 'antd';
import './style.less';

const { Search } = Input;

const SearchBox = ({
  intl,
  onSearch,
  value,
  onChange,
}) => {
  const t = intl.messages;

  // Focus search field on Ctrl+F
  const searchInputRef = useRef()
  useHotkeys('ctrl+f', (e) => {
    if (searchInputRef.current.focus) {
      searchInputRef.current.focus()
      e.preventDefault()
    }
  }, [searchInputRef])

  return (
    <Search
      className='SearchBox'
      ref={searchInputRef}
      placeholder={t.search_box_placeholder}
      enterButton={<img src='/images/assets/icon-search.svg' alt=''/>}
      value={value}
      onSearch={(term) => onSearch((term || '').toLowerCase())}
      onChange={(event) => {
        const term = event.target.value;
        if(!term) {
          onSearch('');
        }
        onChange(term);
      }}
      allowClear
    />
  )
}

export default injectIntl(SearchBox);
