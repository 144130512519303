import React from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  Layout,
} from 'antd';

import SignInSuccess from 'containers/SignInSuccess';
import EmailForm from 'containers/EmailForm';
import PasswordForm from 'containers/PasswordForm';
import SsoPopup from 'containers/SsoPopup';
import AuthLayout from 'components/AuthLayout';

import './style.less';

const {
  Content,
} = Layout;

const SignIn = () => {
  // Store and actions
  const {
    stage,
  } = useSelector(state => state.signin);

  const {
    data: organization,
  } = useSelector(state => state.organization);

  return (
    <AuthLayout
      organization={organization}
    >
      <Content className="Content-noheader">
        { stage === 'success' ? <SignInSuccess /> : null }
        { stage === 'email' ? <EmailForm /> : null }
        { stage === 'password' ? <PasswordForm /> : null }
        { stage === 'sso_popup' ? <SsoPopup /> : null }
      </Content>
    </AuthLayout>
  )
}

export default SignIn;
