import React, {useState} from 'react'
import { injectIntl } from 'react-intl'
import { Radio, Popover, Typography } from 'antd';
import {isEqual, get} from 'lodash'
import { SketchPicker } from 'react-color'; 

const { Text } = Typography

const defaultColors = [
  '#117065',
  '#F0C419',
  '#E57E25',
  '#D65050',
  '#2E8979',
  '#FFC600',
  '#ACEF6A',
  '#43AB5F',
  '#42B7A6',
];

const OptionsListColors = ({
  intl,
  label,
  path,
  optionValue = [],
  templateSection,
  onChange,
  settingsValues,
}) => {
  const customDashboardPath = [...path]
  customDashboardPath.splice(-1, 1, "custom_dashboard_theme")
  const [customColors, setCustomColors] = useState(get(settingsValues, customDashboardPath) || defaultColors);
  return (
    <div className="schema-options">
      {templateSection.values.map((value) => (
        <Radio 
          value={value}
          checked={isEqual(value, optionValue)}
          onChange={e => {
            onChange(
              path, 
              e.target.value
            )
          }} 
        >
            {value.map(color => <div className="color" style={{width: '10px', height: '10px', backgroundColor: color}}/>)}
        </Radio>
      ))}
      <Text key={label} type="keyboard" className="schema-options-label">
        {intl.formatMessage({ id: 'configuration_label_atlas_custom_dashboard_theme'})}
      </Text>
      <Radio 
        value={customColors}
        checked={isEqual(customColors, optionValue)}
        onChange={e => {
          onChange(
            path,
            e.target.value
          )
        }} 
      >
          {customColors.map((color, i) => 
            <Popover
              content={
                <SketchPicker  
                  disableAlpha
                  color={color}
                  onChangeComplete={color => {
                    const newSchema = customColors.map((_, index) => i === index ? color.hex : _)
                    onChange(
                      customDashboardPath,
                      newSchema
                    )
                    onChange(
                      path,
                      newSchema
                    )
                    setCustomColors(newSchema)
                  }}
                />
              }
              trigger="click"
            >
              <div className="color" style={{backgroundColor: color}}/>
            </Popover>
          )}
      </Radio>
    </div>
  )
}

export default injectIntl(OptionsListColors)