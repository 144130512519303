import React, { useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use';
import { get, last, first } from 'lodash'
import TrueFalse from './TrueFalse'
import String from './String'
import Number from './Number'
import LabelList from './LabelList'
import Color from './Color'
import OptionSelector from './OptionSelector'
import OptionsList from './OptionsList'
import OptionListColors from './OptionListColors'

const getComponent = (templateSection) => {
  const components = {
    number: Number,
    string: String,
    boolean: TrueFalse,
    'array.string': LabelList,
    color: Color,
  }

  const componentsWithSelectableValues = {
    string: OptionSelector,
    'array.string': OptionsList,
    'array.color_schema': OptionListColors,
  }

  return templateSection?.values
    ? componentsWithSelectableValues[templateSection?.type]
    : components[templateSection?.type]
}



const SubOption = ({ path, templateSection, settingsValues, availableDimensions, onChange }) => {

  const value = useMemo(() => {
    return get(settingsValues, path)
  }, [path, settingsValues])

  const [optionValue, setOptionValue] = useState(value || templateSection.default);

  useUpdateEffect(() => setOptionValue(value), [value])

  const key = first(path) + '_' + last(path)
  const component = getComponent(templateSection)

  if (!component) return null
  return React.createElement(component, {
    templateSection,
    label: key,
    optionValue,
    availableDimensions,
    onChange,
    path,
    values: templateSection.values,
    key: path.join('-'),
    settingsValues,
  })
}

export default SubOption
