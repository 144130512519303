import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import './style.css';

import { Modal } from 'antd';

const RATIO = 1;
const DEFAULT_CROP = {
  x: 20,
  y: 20,
  width: 20,
  height: 20,
};

const doCrop = (src, crop, filename='image.png') => {
  const canvas = document.createElement('canvas');
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      canvas.toBlob(blob => {
        blob.name = filename;
        resolve(blob);
      }, 'image/png');
    };

    image.src = src;
  });
};

const convertBlobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = e => resolve(e.target.result);
    fr.readAsDataURL(blob);
  });
};

const AvatarCrop = ({
  t,
  src,
  onCrop,
  onCancel,
  ratio = RATIO,
}) => {
  const [crop, setCrop] = useState({
    aspect: ratio,
    ...DEFAULT_CROP,
  });
  const [pixelCrop, setPixelCrop] = useState({});

  const onOk = () => {
    if( Object.keys(pixelCrop).length === 0 ) { return; }
    //onCrop(editorRef.current.getImage().toDataURL());
    doCrop(src, pixelCrop)
      .then(convertBlobToBase64)
      .then(onCrop);
  };

  const changeCrop = (crop, pixelCrop) => {
    setCrop(crop);
    setPixelCrop(pixelCrop);
  };

  const onImageLoaded = (target, pixelCrop) => {
    setPixelCrop(pixelCrop)
  };

  return (
    <Modal
      visible
      closable={false}
      maskClosable={false}
      onOk={onOk}
      onCancel={onCancel}
    >
      <div className="AvatarCrop-inner">
        <ReactCrop
          src={src}
          crop={crop}
          onChange={changeCrop}
          onImageLoaded={onImageLoaded}
        />
        <div className="AvatarCrop-warning">
          { t.please_crop_image }
        </div>
      </div>
    </Modal>
  );
}

export default AvatarCrop;
