import React from 'react';
import { injectIntl } from 'react-intl';

import A from 'components/A';

import LanguageSelector from 'containers/LanguageSelector';

import { Layout } from 'antd';

import './style.less';

const {
  Footer,
} = Layout;

const SignedOutFooter = ({ intl }) => {
  return (
    <Footer className="SignedOutFooter">
      <div className="SignedOutFooter__block">
        <LanguageSelector />
      </div>
      <div className="SignedOutFooter__block">
        <div>
          <A
            href={`mailto:${intl.formatMessage({ id: "contact_email_address"})}@aplanet.org`}
            className="SignedOutFooter__link"
          >
            { intl.formatMessage({ id: "contact_email_address"}) }@aplanet.org
          </A>
        </div>
        <div>
          <A
            href="https://aplanet.org/privacy-policy/"
            className="SignedOutFooter__link"
          >
            { intl.formatMessage({ id: "privacy"}) }
          </A>
        </div>
        <div>
          <A
            href={intl.formatMessage({ id: "terms_and_conditions_url"})}
            className="SignedOutFooter__link"
          >
            { intl.formatMessage({ id: "terms"}) }
          </A>
        </div>
      </div>
    </Footer>
  );
}

export default injectIntl(SignedOutFooter);
