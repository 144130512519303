import { useEffect, useMemo, useState } from 'react'
import qs from 'query-string';
import {appToProduct, productAndRoleToApp} from "../../utils/clientUrl";

function getMembership(adminMemberships, selectedOrganization) {
  if (adminMemberships.length === 1) return adminMemberships[0];
  if (selectedOrganization) return adminMemberships.find(m => m.slug === selectedOrganization.slug)

  return { products: [] }
}

const RedirectToAppIfPossible = ({
  location,
  loading,
  adminMemberships,
  apps,
  render,
  organization,
  ...props
}) => {
  const [renderComponent, setRenderComponent] = useState(false)
  const slug = useMemo(() => qs.parse(location.search)?.app, [location])
  const redirect = useMemo(() => qs.parse(location.search)?.r, [location])
  const redirectToObject = useMemo(() => {
    const redirectTo = qs.parse(location.search)?.redirectTo;
    return redirectTo
    ? { redirectTo }
    : {}
  }, [location])
  const isPossibleToFigureOutApp = useMemo(
    () =>
      getMembership(adminMemberships, organization).products.length === 1,
    [adminMemberships, organization]
  )
  useEffect(() => {
    if (!loading) {
      const membership = getMembership(adminMemberships, organization)
      if (!!redirect && membership.products.includes(appToProduct(slug))) {
        const url = apps[slug](membership.slug, redirectToObject);
        window.location.replace(url);
      } else if (!!redirect && isPossibleToFigureOutApp) {
        const url = apps[productAndRoleToApp(membership.products[0], membership.role)](membership.slug, redirectToObject);
        window.location.replace(url)
      } else {
        setRenderComponent(true)
      }
    }
  }, [adminMemberships, apps, slug, redirect, redirectToObject, isPossibleToFigureOutApp, loading, organization])

  if (renderComponent) {
    return render({location, ...props})
  }

  return null
}

export default RedirectToAppIfPossible
