const requestIndustries = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'industry',
  method: 'GET',
  endpoint: `${organization_slug}/industry`,
  body: null,
})

const addOrganization = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_creation',
  method: 'POST',
  endpoint: `${organization_slug}/organization`,
  body,
})

const requestOrganizationTree = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_tree',
  method: 'GET',
  endpoint: `${organization_slug}/organization_tree`,
  body: null,
})

const requestOrganizationDetail = (
  organization_slug,
  suborganization_slug
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_detail',
  method: 'GET',
  endpoint: `${organization_slug}/organization_detail/${suborganization_slug}`,
  body: null,
})

const requestProfile = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'profile',
  method: 'GET',
  endpoint: `${organization_slug}/profile`,
  body: null,
})

const getAllPermissions = (organization_slug, member_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'permissions',
  method: 'GET',
  endpoint: `${organization_slug}/permissions/${member_id}`,
  body: null,
})

const updateProfile = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'profile',
  method: 'PUT',
  endpoint: `${organization_slug}/profile`,
  body,
})

const updateOrganization = (organization_slug, suborganization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/organization_detail/${suborganization_slug}`,
  body,
})

const getConfiguration = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_configuration',
  method: 'GET',
  endpoint: `${organization_slug}/organization_configuration/${suborganization_slug}`,
})

const updateConfiguration = (
  organization_slug,
  suborganization_slug,
  body
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_configuration',
  method: 'PUT',
  endpoint: `${organization_slug}/organization_configuration/${suborganization_slug}`,
  body,
  organization_slug,
  suborganization_slug,
})

const addMember = (
  organization_slug,
  suborganization_slug,
  name,
  email,
  details,
  atlas_permission,
  community_permission,
  general_permission
) => ({
  type: 'API_CALL_REQUEST',
  target: 'member',
  method: 'POST',
  endpoint: `${organization_slug}/member/${suborganization_slug}`,
  body: {
    name,
    email,
    details,
    atlas_permission,
    community_permission,
    general_permission,
  },
})

const updateMember = (
  organization_slug,
  suborganization_slug,
  member_slug,
  status,
  details,
  atlas_permission,
  community_permission,
  general_permission
) => ({
  type: 'API_CALL_REQUEST',
  target: 'member',
  method: 'PUT',
  endpoint: `${organization_slug}/member/${suborganization_slug}/${member_slug}`,
  body: {
    status,
    details,
    atlas_permission,
    community_permission,
    general_permission,
  },
})

const changeMember = (
  organization_slug,
  suborganization_slug,
  member_slug,
  action,
  options = {}
) => ({
  type: 'API_CALL_REQUEST',
  target: 'member',
  method: 'PUT',
  endpoint: `${organization_slug}/member/${suborganization_slug}/${member_slug}/${action}`,
  body: options,
})

const addChildOrg = (organization_slug, suborganization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_tree',
  method: 'POST',
  endpoint: `${organization_slug}/organization/${suborganization_slug}`,
  body,
})

const deleteOrg = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_tree',
  method: 'DELETE',
  endpoint: `${organization_slug}/organization/${suborganization_slug}`,
})

const requestEmails = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'email',
  method: 'GET',
  endpoint: `${organization_slug}/email/${suborganization_slug}`,
})

const updateEmail = (organization_slug, suborganization_slug, slug, locale, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'email',
  method: 'PUT',
  endpoint: `${organization_slug}/email/${suborganization_slug}/${slug}/${locale}`,
  body,
})

export {
  requestOrganizationTree,
  requestOrganizationDetail,
  requestProfile,
  requestEmails,
  updateProfile,
  updateOrganization,
  updateConfiguration,
  getConfiguration,
  getAllPermissions,
  addMember,
  updateMember,
  changeMember,
  addChildOrg,
  deleteOrg,
  requestIndustries,
  addOrganization,
  updateEmail,
}
