import { loop, Cmd } from 'redux-loop';
import {
  parseResponse,
  parseErrorBody,
} from 'utils';
import { appToProductAndRole } from 'utils/clientUrl';
import config from 'config';

// LOOKUP ACTIONS
const requestLookup = async (email, app) => {
  try {
    const [
      requiredProduct,
      requiredRole
    ] = appToProductAndRole(app);

    const response = await fetch(`${config.AUTH_URL}/lookup`, {
      method: 'POST', // NOTICE: This should be a GET
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        email,
        requiredRole,
        requiredProduct,
      }),
    })

    const result = await parseResponse(response);
    return result;
  } catch(err) {
    const errorBody = await parseErrorBody(err);
    // eslint-disable-next-line no-throw-literal
    throw { ...err, errorBody };
  }
};

const lookupSuccess = ({
  email,
  nextStage,
  organizations, // NOTICE: This might be empty to avoid leaking info
  url,
}) => ({
  type: 'LOOKUP_COMPLETE',
  email,
  stage: nextStage,
  organizations: organizations || [],
  url,
});

const lookupFail = ({
  code = 504,
  text,
  errorBody,
}) => ({
  type: 'LOOKUP_FAILED',
  code,
  text,
  errorBody,
});

// LOGIN ACTIONS
const requestLogin = async (email, password, app) => {
  try {
    const [
      requiredProduct,
      requiredRole
    ] = appToProductAndRole(app);

    const response = await fetch(`${config.AUTH_URL}/login`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        requiredRole,
        requiredProduct,
      }),
    })

    const result = await parseResponse(response);
    return result;
  } catch(err) {
    const errorBody = await parseErrorBody(err);
    // eslint-disable-next-line no-throw-literal
    throw { ...err, errorBody };
  }
};

const loginSuccess = ({ access_token, user, organizations }) => ({
  type: 'LOGIN_COMPLETE',
  access_token,
  user,
  organizations,
});

const loginFail = ({
  code = 504,
  text,
  errorBody,
}) => ({
  type: 'LOGIN_FAILED',
  code,
  text,
  errorBody,
});

const initialState = {
  stage: 'email',
  loading: false,
  error: null,
  errorBody: null,
  email: null,
  url: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LOGIN_REQUEST':
      return loop({
        ...state,
        loading: true,
        error: null,
        errorBody: null,
        url: null,
      }, Cmd.run(requestLogin, {
        successActionCreator: loginSuccess,
        failActionCreator: loginFail,
        args: [action.email, action.password, action.app],
      })
    );
    case 'LOOKUP_REQUEST':
      return loop({
        ...state,
        loading: true,
        error: null,
        errorBody: null,
        url: null,
      }, Cmd.run(requestLookup, {
        successActionCreator: lookupSuccess,
        failActionCreator: lookupFail,
        args: [action.email, action.app],
      })
    );
    case 'LOOKUP_COMPLETE':
      return {
        ...state,
        loading: false,
        error: null,
        errorBody: null,
        stage: action.stage,
        email: action.email,
        url: action.url,
      };
    case 'LOGIN_COMPLETE':
    case 'SUBMIT_RECOVERED_PASSWORD_COMPLETE':
    case 'REFRESH_TOKEN_COMPLETE':
    case 'IDENTITY_COMPLETE':
      return {
        ...initialState,
        stage: 'success',
        logged_in: true,
        loading: false,
        error: null,
        errorBody: null,
        url: null,
      };
    case 'LOGOUT_COMPLETE':
    case 'LOGOUT_FAILED':
      return initialState;
    case 'LOOKUP_FAILED':
    case 'LOGIN_FAILED':
      return {
        ...state,
        loading: false,
        error: action.code,
        errorBody: action.errorBody,
        url: null,
      };
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        error: null,
        errorBody: null,
      };
    case 'REFRESH_TOKEN_FAILED':
    case 'IDENTITY_FAILED':
      return initialState; // TODO: process error
    case 'RESET_AUTH':
      return initialState;
    case 'HYDRATE_STORE':
      return state; // TODO: consider storing email, using action.state
    default:
      return state;
  }
};

export {
  reducer as signin,
};
