import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  injectIntl,
} from 'react-intl';

import {
  Form,
  Alert,
  Checkbox,
  Row,
} from 'antd';
import {
  LockOutlined,
} from '@ant-design/icons';

import {
  fetchPasswordPolicy,
  submitRecoveredPassword,
} from 'actions/auth';

import A from 'components/A';
import AuthLayout from 'components/AuthLayout';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';

import getPasswordRules from 'utils/getPasswordRules';

import './style.less';
import FormItem from 'antd/lib/form/FormItem';

const handlePasswordChange = (setter) => (e) => {
  e.preventDefault();
  setter(e.target.value);
};

const SetInitialPassword = ({
  intl,
  match,
}) => {
  const t = intl.messages;

  const [ password1, setPassword1 ] = useState('');
  const [ password2, setPassword2 ] = useState('');
  const [ agreeTC, setAgreeTC ] = useState(false);
  const [ agreeComms, setAgreeComms ] = useState(false);

  const equal = (password1 === password2);
  const { token } = match.params;

  const tokenType = useMemo(() => {
    if(match?.url?.includes('confirm_email')) {
      return 'confirm_email';
    }
    return 'recover_password';
  }, [
    match,
  ]);

  // Store and actions
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchPasswordPolicy(
        token
      )
    );
  }, [
    dispatch,
    token,
  ]);

  const {
    data: passwordPolicy,
  } = useSelector(state => state.password_policy);

  const {
    error,
  } = useSelector(state => state.password_recovery);

  const passwordRules = useMemo(() =>
    getPasswordRules(intl, passwordPolicy), 
    [
      intl,
      passwordPolicy,
    ]
  )

  const handleSubmit = useCallback(() => {
    if(equal) {
      dispatch(
        submitRecoveredPassword(
          token,
          password1,
          {
            agreeTC,
            agreeComms,
          },
          tokenType,
        )
      );
    }
  }, [
    equal,
    dispatch,
    token,
    password1,
    agreeTC,
    agreeComms,
    tokenType,
  ]);


  return (
    <AuthLayout>
      <article className="SetInitialPassword">
        <h1
          className="SetInitialPassword__heading"
        >{ t.setinitialpassword_title }</h1>
        <p
          className="SetInitialPassword__subheading"
        >{ '' }</p>
        <div>
          { !error ? null : <Alert type="error" message={ t.setinitialpassword_error_setting_password } /> }
        </div>
        <Form onFinish={handleSubmit} layout="vertical" scrollToFirstError>
          <Form.Item
            label={t.setinitialpassword_label}
            name="password1"
            rules={passwordRules}
            hasFeedback
          >
            <CustomInput.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={ t.setinitialpassword_password }
              value={password1}
              onChange={handlePasswordChange(setPassword1)}
              label={ t.setinitialpassword_password_label }
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            label={t.setinitialpassword_repeat_label}
            name="password2"
            rules={[
              {
                required: true,
                message: t.setinitialpassword_remind
              },
              {
                validator: (_, value) => value && value !== password1 ? Promise.reject(t.setinitialpassword_passwords_must_be_equal) : Promise.resolve()
              }
            ]}
          >
            <CustomInput.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={ t.setinitialpassword_password }
              value={password2}
              onChange={handlePasswordChange(setPassword2)}
            />
          </Form.Item>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(t.terms_and_conditions_remind),
              },
            ]}
          >
            <Checkbox
              checked={agreeTC}
              onChange={() => setAgreeTC(!agreeTC)}
            >
              <A href={t.terms_and_conditions_url} newWindow>{ t.terms_and_conditions }</A>
            </Checkbox>
          </Form.Item>
          <FormItem>
            <Checkbox
              checked={agreeComms}
              onChange={() => setAgreeComms(!agreeComms)}
            >
              { t.receive_commercial_comms }
            </Checkbox>
          </FormItem>
          <Row
            type="flex"
            justify="end"
          >
            <Form.Item className="SetInitialPassword__ButtonWrapper">
              <CustomButton
                block
                type="primary"
                htmlType="submit"
              >{ t.setinitialpassword_set_password }
            </CustomButton>
            <div className="SetInitialPassword__link">
              {t.setinitialpassword_already}
              <a href="/signin">{t.setinitialpassword_login}</a> 
            </div>
          </Form.Item>
        </Row>
      </Form>
      </article>
    </AuthLayout>
  )
}

export default injectIntl(SetInitialPassword);

