import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Checkbox
} from 'antd';

import CustomIcon from 'components/CustomIcon';

import './style.less';

const CustomWarningModal = ({
  intl,
  className,
  showModal = false,
  title,
  content,
  onOk,
  onCancel,
  onOkText,
  onCancelText,
  icon = 'exclamation-triangle',
  iconType = 'fas',
  hasNotShowAgainCheckbox = false,
}) => {
  const [notShowAgain, setNotShowAgain] = useState(false);

  return (
    <Modal
      visible={showModal}
      width={700}
      className={`CustomWarningModal${className ? ` ${className}` : ''}`}
      title={(
        <div className="CustomWarningModal__title">
          <CustomIcon
            className='CustomWarningModal__icon'
            icon={[iconType, icon]}
          />
          {title}
        </div>
      )}
      footer={(
        <div className="CustomWarningModal__footer">
          <div className="CustomWarningModal__buttons">
            <Button onClick={() => onCancel(notShowAgain)}>
              {onCancelText}
            </Button>
            <Button type="primary" onClick={() => onOk(notShowAgain)}>
              {onOkText}
            </Button>
          </div>
          {hasNotShowAgainCheckbox ? (
            <Checkbox
              className="CustomWarningModal__checkbox"
              onChange={() => setNotShowAgain(!notShowAgain)}
              checked={notShowAgain}
            >
              <span className="CustomWarningModal__checkboxText">
                  {intl.formatMessage({id: 'custom_warning_modal_not_show_again'})}
              </span>
            </Checkbox>
          ) : null}
        </div>
      )}
      closable={false}
      centered
    >
      <div className="CustomWarningModal__content" >{content}</div>
    </Modal>
  )
};

CustomWarningModal.propTypes = {
  onOkText: PropTypes.string.isRequired,
  onCancelText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  content: PropTypes.element.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  hasNotShowAgainCheckbox: PropTypes.bool,
};

export default injectIntl(CustomWarningModal);
