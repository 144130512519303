const DEFAULT_PASSWORD_POLICY = {
    minLength: 6,
    maxLength: 80,
    forbidEmail: true,
  };
  
  const EMAIL_PASSWORD_SUBSTRING_LENGTH = 3;
  const SYMBOL_REGEXP = new RegExp('[^0-9A-Za-z]');
  const UPPERCASE_REGEXP = new RegExp('[A-Z]');
  const LOWERCASE_REGEXP = new RegExp('[a-z]');
  const NUMBER_REGEXP = new RegExp('[0-9]');
  
  const checkEmail = (email, password) => {
    if(!email || !password) {
      return false;
    }
  
    const lcPassword = password.toLowerCase();
  
    if(email.length <= EMAIL_PASSWORD_SUBSTRING_LENGTH) {
      return lcPassword.includes(email.toLowerCase());
    }
  
    const chars = email.toLowerCase().split('');
  
    for(let i = 0; i <= (chars.length - EMAIL_PASSWORD_SUBSTRING_LENGTH); i++) {
      if(lcPassword.includes( chars.slice(i, i+EMAIL_PASSWORD_SUBSTRING_LENGTH).join('') )) {
        return true;
      }
    }
  
    return false;
  };
  
  // TODO: replace strings
  export const getPolicyError = (intl, policy) => (name) => intl.formatMessage({
    id: `password_policy_${name}`,
  }, {
    [name]: policy[name],
  });
  
  const validate = (
    intl,
    passwordPolicy
  )=> {
    const t = intl.messages;
    const policy = passwordPolicy ? passwordPolicy.policy : DEFAULT_PASSWORD_POLICY
    const email = passwordPolicy ? passwordPolicy.email : ''
    const rules = [
        {
            required: true,
            message: t.setinitialpassword_remind,
        }
    ]
  
    const getError = getPolicyError(intl, policy);
  
    if(typeof (policy.minLength) !== 'undefined') {
      rules.push({ min: policy.minLength, message: getError('minLength')},)
    } 
    if(typeof (policy.maxLength) !== 'undefined') {
      rules.push({ max: policy.maxLength, message: getError('maxLength')},)
    } 
    if(policy.hasSymbols) {
      rules.push({ pattern: SYMBOL_REGEXP, message: getError('hasSymbols')},)
    } 
    if(policy.hasUppercase) {
      rules.push({ pattern: UPPERCASE_REGEXP, message: getError('hasUppercase')},)
    } 
    if(policy.hasLowercase) {
      rules.push({ pattern: LOWERCASE_REGEXP, message: getError('hasLowercase')},)
    } 
    if(policy.hasNumbers) {
      rules.push({ pattern: NUMBER_REGEXP, message: getError('hasNumbers')},)
    } 
    if(policy.forbiddenWords) {
      rules.push({validator: (_, value) => policy.forbiddenWords.find(word => value.toLowerCase().includes(word.toLowerCase())) ? Promise.reject(getError('forbiddenWords')) : Promise.resolve()})
    } 
    if(policy.forbidEmail) {
      rules.push({validator: (_, value) => checkEmail(email, value) ? Promise.reject(getError('forbidEmail')) : Promise.resolve()})
    }
  
    return rules;
  }
  
  export default validate;
  
  