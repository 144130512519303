import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';

import './style.less';

const BIG_ICONS = {
  community: {
    can_read_initiatives: 'clipboard',
    can_read_reports: 'chart-pie',
    can_manage_participation: 'thumbs-up',
    can_manage_collaborations: 'handshake',
    can_manage_proposals: 'arrow-up',
    can_manage_initiatives: 'clipboard',
    can_manage_volunteers: 'users',
    default: 'cog',
  },
  general: {
    can_manage_permissions: 'unlock-alt',
    can_manage_configuration: 'cog',
    default: 'cog',
  },
  atlas: {
    can_read_kpi: 'eye',
    can_write_kpi: 'pencil-alt',
    can_request_kpi: 'envelope',
    can_validate_kpi: 'check-square',
    can_report_kpi: 'upload',
    can_configure_kpi: 'cog',
    can_affect_all_kpis: 'th-list',
    can_undo_validate_kpi: 'undo',
    can_export_kpis: 'file-export',
    can_import_kpis: 'file-import',
    can_manage_variables_configuration: 'list',
    default: 'cog',
  },
};

const SMALL_ICONS = {
  community: {
    can_read_initiatives: 'eye',
    can_read_reports: 'eye',
    can_manage_participation: 'cog',
    can_manage_collaborations: 'cog',
    can_manage_proposals: 'cog',
    can_manage_initiatives: 'cog',
    can_manage_volunteers: 'cog',
    default: 'cog',
  },
  general: {
    can_manage_permissions: 'cog',
    can_manage_configuration: 'cog',
    default: 'cog',
  },
  atlas: {
    can_read_kpi: 'eye',
    can_write_kpi: 'cog',
    can_request_kpi: 'cog',
    can_validate_kpi: 'cog',
    can_report_kpi: 'cog',
    can_configure_kpi: 'cog',
    can_affect_all_kpis: 'cog',
    can_undo_validate_kpi: 'cog',
    can_export_kpis: 'cog',
    can_import_kpis: 'cog',
    can_manage_variables_configuration: 'cog',
    default: 'cog',
  },
};

const PermissionLogo = ({
  intl,
  app,
  permission,
  title = true,
  size = 'md',
  className = '',
  style = {},
  level = '',
}) => {
  const t = intl.messages;
  return (
    <div
      className={`PermissionLogo PermissionLogo__size-${size} ${className}`}
      title={
        title === true
        ? ( t[`permission_${app}_${permission}`] || permission )
        : title
      }
      style={style}
    >
      <FontAwesomeIcon
        className="PermissionLogo__bigicon"
        icon={ BIG_ICONS[app][permission] || BIG_ICONS[app].default }
      />
      { !level
        ? null
        : <div 
            className="PermissionLogo__validation"
            style={{
              right: `-${6.5 + level.length * 2.5}px`, 
              padding: level.length > 1 ? '2px' : '0'
            }} 
          >
            { level }
          </div>
      }
      { level || !SMALL_ICONS[app][permission]
        ? null
        : <FontAwesomeIcon
            className="PermissionLogo__smallicon"
            icon={SMALL_ICONS[app][permission]}
          />
      }
    </div>
  );
}

export default injectIntl(PermissionLogo);
