import React, { useState } from 'react';
import { Divider, List, Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { first, keys, last } from 'lodash';
import SubOption from './SubOption';
import { InfoCircleOutlined } from '@ant-design/icons';

function getKey(path) {
  if(path.length === 1) return path[0];

  return `${first(path)}_${last(path)}`;
}

const Option = ({ intl, availableDimensions, path, templateSection, settingsValues, onChange }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  return (
    <div>
      <Divider orientation="left">
        {intl.formatMessage({
          id: `configuration_label_${getKey(path)}`,
        })} {
          templateSection.description && <InfoCircleOutlined onClick={() => setShowDescriptionModal(true)} />
        }
      </Divider>
      <div className="Option">
        {templateSection.options ? (
          keys(templateSection.options)
            .map((suboption) => (
            <div className="Option-item" key={suboption}>
              <SubOption
                path={[...path, suboption]}
                availableDimensions={availableDimensions}
                templateSection={templateSection.options[suboption]}
                settingsValues={settingsValues}
                onChange={onChange}
              />
            </div>
          ))
        ) : (
          <div className="Option-item">
            <SubOption
              path={[...path]}
              availableDimensions={availableDimensions}
              templateSection={templateSection}
              settingsValues={settingsValues}
              onChange={onChange}
            />
          </div>
        )}
      </div>
      {
        templateSection.description && (
          <Modal
            title={intl.formatMessage({ id: `configuration_description_${first(path)}_${last(path)}`})}
            destroyOnClose={true}
            visible={showDescriptionModal}
            onCancel={() => setShowDescriptionModal(false)}
            footer={null}
          >
            <List
              layout="vertical"
              dataSource={keys(templateSection.options).map(suboption => suboption)}
              renderItem={suboption => (
                <List.Item key={suboption}>
                  <List.Item.Meta
                    title={intl.formatMessage({ id: `configuration_description_title_${suboption}`})}
                    description={intl.formatMessage({ id: `configuration_description_info_${suboption}`})}
                  />
                </List.Item>
              )}
            />
          </Modal>
        )
      }
    </div>
  )
}

export default injectIntl(Option)
