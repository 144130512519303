import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { findTree } from 'utils/tree';

const MAIN_PRODUCT = 'general';

const useOrganizations = () => {
  const {
    data: organization,
    memberships,
  } = useSelector(state => state.organization);

  const {
    tree: organizationTree,
    current_slug: currentSlug,
  } = useSelector(state => state.organization_tree);

  const suborganization = useMemo(() => {
    if(!organizationTree) {
      return organization;
    }

    const result = findTree(
      [organizationTree],
      ({ slug }) => slug === currentSlug,
    );

    if(!result) {
      return result;
    }

    return {
      ...result,
      config: (result?.product_config || {})[MAIN_PRODUCT]
    }
  }, [
    organization,
    organizationTree,
    currentSlug
  ]);

  const permissions = useMemo(() => {
    return suborganization ? suborganization.permissions : null;
  }, [
    suborganization,
  ]);

  const isRootOrganization = useMemo(
    () => (suborganization ? suborganization.id: null) === (organization ? organization.id: null),
    [
      organization,
      suborganization,
    ]
  );

  return {
    organization,
    memberships,
    suborganization,
    organizationTree,
    permissions,
    isRootOrganization,
  };
};

export default useOrganizations;
