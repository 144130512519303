import React from 'react'
import {
  Table,
} from 'antd'
import './style.less'

const CustomTable = ({
  dataSource,
  columns,
  ...props
}) => {
  return (
    <Table
      {...props}
      className='CustomTable'
      columns={columns}
      dataSource={dataSource}
    />
  );
}

export default CustomTable
