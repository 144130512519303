import React from 'react'
import { CompactPicker } from 'react-color'

const Color = ({ optionValue, path, onChange }) => (
  <CompactPicker
    color={optionValue}
    onChangeComplete={(color) =>
      color.hex !== optionValue && onChange(path, color.hex)
    }
  />
)

export default Color
