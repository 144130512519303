import React, { useMemo } from 'react';
import './style.less';
import {Button, Tooltip} from 'antd';


const CustomButton = (props) => {
  const className = useMemo(
    () => {
      let name = `CustomButton`;

      if (props.icon) {
        name += ` CustomButton-icon`;
      }

      if (props.className) {
        name += ` ${props.className}`;
      }

      return name;
    },
    [props]
  );

  const renderButton = (
    <Button 
      {...props}
      className={className} 
    >
      {props.children}
    </Button>
  )

  if (props.tooltip){

    const extraProps = props.tooltipPlacement ? { placement: props.tooltipPlacement } : {};

    return (
      <Tooltip 
        title={props.tooltip} 
        overlayClassName="CustomButton__tooltip" 
        {...extraProps}
      >
        { renderButton }
      </Tooltip>
    )
  }

  return renderButton;
}

export default CustomButton;
