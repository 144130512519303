import React, { useMemo } from 'react'
import { withRouter } from 'react-router';
import config from 'config';
import {
  Col,
  Row,
} from 'antd';
import { injectIntl } from 'react-intl';

const OrgLogos = ({
  intl,
  organization,
  mainOrganization,
}) => {
  const t = intl.messages;


  const atlasBaseUrl = useMemo(() => {
    return mainOrganization?.config?.atlas?.custom_redirect_urls?.admin || config.ATLAS_APP_URL;
  }, [
    mainOrganization,
  ]);

  const adminBaseUrl = useMemo(() => {
    return mainOrganization?.config?.community?.custom_redirect_urls?.admin || config.ADMIN_APP_URL;
  }, [
    mainOrganization,
  ]);

  return (
    <Row type='flex' align='middle'>
      {
        (organization?.products.includes('atlas') ||
          organization?.products.includes('community')) &&
        <Col className='AppHeader-productLinks'>
          <Row type='flex' align='middle' gutter={16}>
            {organization?.products.includes('atlas')
              ? <Col>
                <a
                  href={`${atlasBaseUrl}?toplevelorg=${mainOrganization?.slug}&org=${organization.slug}`}
                  className='AppHeader-linkToApp'
                >
                  {t.product_atlas}
                </a>
              </Col>
              : null
            }
            {organization?.products.includes('community')
              ? <Col>
                <a
                  href={`${adminBaseUrl}?toplevelorg=${mainOrganization?.slug}&org=${organization.slug}`}
                  className='AppHeader-linkToApp'
                >
                  {t.product_community}
                </a>
              </Col>
              : null
            }
          </Row>
        </Col>
      }
    </Row>
  );
};

export default injectIntl(withRouter(OrgLogos))