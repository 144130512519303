import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import { injectIntl } from 'react-intl'
import { groupBy, partition, sortBy } from 'lodash';

import { useDispatch, useSelector } from 'react-redux'

import {
  Tabs,
  Divider,
  Collapse,
} from 'antd'

import {
  requestEmails,
} from 'actions/api';

import EmailTemplateForm from 'components/OrganizationProfile/EmailTemplateForm';
import useOrganization from '../../utils/useOrganization';

const { TabPane } = Tabs;
const { Panel } = Collapse;


/*
 * XXX: Using hard-coded materiality emails here since we categorise the emails
 * by product whilst materiality is a feature within sustainability product.
 * Ideally materiality could be its own product or we could categorize
 * materiality emails in a separate email domain.
 */
const MATERIALITY_EMAILS = [
  'survey-request',
  'active-listening-ready',
  'active-listening-request',
  'benchmark-ready',
  'benchmark-request',
];

const EmailConfiguration = ({
  intl,
}) => {
  const dispatch = useDispatch();
  const { data: emails } = useSelector((state) => state.email);

  const {
    organization,
    suborganization,
  } = useOrganization();

  useEffect(() => {
    if (organization) {
      dispatch(requestEmails(organization.slug, suborganization.slug));
    }
  }, [
    dispatch,
    organization,
    suborganization,
  ])

  const products = useMemo(() => [...organization.products, 'null'], [ organization ]);

  const filterPreferredLanguageTemplates = useCallback(template => {
    const preferredLanguagesAtlas = organization?.config?.atlas?.preferred_languages || [];
    const preferredLanguagesCommunity = organization?.config?.community?.preferred_languages || [];
    const preferredLanguagesCommon = [ ...preferredLanguagesAtlas, ...preferredLanguagesCommunity ];

    switch (template.product_slug) {
      case 'atlas':
        return preferredLanguagesAtlas.includes(template.locale);
      case 'community':
        return preferredLanguagesCommunity.includes(template.locale);
      default:
        if (template.domain === 'common') {
          return preferredLanguagesCommon.includes(template.locale);
        }
        return true;
    }
  }, [
    organization,
  ]);

  const getTemplateName = useCallback(
    (slug, emails) => (
      emails
      .filter(email => email.slug === slug)
      .find(({locale}) => locale === intl.locale)?.config?.name || slug
    ),
    [intl]
  );

  const [
    _emails,
    materialityEmails,
  ] = useMemo(() => partition(
    sortBy(emails, email => getTemplateName(email.slug, emails)),
    email => !MATERIALITY_EMAILS.includes(email.slug)
  ), [
    getTemplateName,
    emails,
  ]);

  return (
    <Tabs>
      {Object.entries(groupBy(_emails, 'product_slug'))
        .filter(([product, emails]) => products.includes(product))
        .map(([product, emails]) => (
          <TabPane tab={intl.formatMessage({ id: `email_template_${product}` })} key={product}>
            {Object.entries(groupBy(emails, 'domain')).map(([domain, emails]) => (
              <div key={domain}>
                <h3>{intl.formatMessage({ id: `email_template_${domain}` })}</h3>
                <Collapse>
                  {Object.entries(groupBy(emails, 'slug'))
                    .map(([slug, emails]) => (
                    <Panel header={getTemplateName(slug, emails)} key={slug}>
                      <Tabs>
                        {
                          emails
                          .filter(filterPreferredLanguageTemplates)
                          .map(template => (
                            <TabPane tab={intl.formatMessage({ id: `language_${template.locale}` })} key={template.locale}>
                              <EmailTemplateForm emailTemplate={template} />
                            </TabPane>
                          ))
                        }
                      </Tabs>
                    </Panel>
                  ))}
                </Collapse>
                <Divider />
              </div>
            ))}
          </TabPane>
      ))}
      {!!organization?.config?.atlas?.features?.materiality &&
      <TabPane tab={intl.formatMessage({ id: `email_template_materiality` })} key="materiality">
        <Collapse>
          {Object.entries(groupBy(materialityEmails, 'slug'))
            .map(([slug, emails]) => (
            <Panel header={getTemplateName(slug, emails)} key={slug}>
              <Tabs>
                {
                  emails
                  .filter(filterPreferredLanguageTemplates)
                  .map(template => (
                    <TabPane tab={intl.formatMessage({ id: `language_${template.locale}` })} key={template.locale}>
                      <EmailTemplateForm emailTemplate={template} />
                    </TabPane>
                  ))
                }
              </Tabs>
            </Panel>
          ))}
        </Collapse>
      </TabPane>
      }
    </Tabs>
  );
}

export default injectIntl(EmailConfiguration)
