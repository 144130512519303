import React, {
  useState,
  useMemo,
  useEffect,
} from 'react'
import './style.less'

import { makeSearchString } from 'utils/strings';

import {
  Select,
  Divider
} from 'antd';

import { CustomButton } from 'aplanet-ui-kit';
import { CustomInput } from 'aplanet-ui-kit';

import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';

const DEFAULT_RENDER_OPTION = ({ name, slug }) => name;

const NewCustomSelect = ({
  title,
  options = [],
  selected = false,
  onSelect,
  className,
  renderOption = DEFAULT_RENDER_OPTION,
  showSearch = true,
  disabled = false,
  addItems = false,
  addItemsPlaceholder = '',
  returnValueKey,
  addItemText = '',
  defaultValue = null,
  highlightSelected = false,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [items, setItems] = useState(options);
  const [newItem, setNewItem] = useState('');
  const isHighlighted = useMemo(() => {
    return highlightSelected && selected;
  }, [
    highlightSelected,
    selected,
  ]);
  
  const filterOption = useMemo(() => {
    if(!showSearch) {
      return undefined;
    }

    return (searchTerm, option) => {
      return makeSearchString(option.title).includes(makeSearchString(searchTerm))
    }
  }, [
    showSearch,
  ]);

  const onNewItemChange = event => {
      setNewItem(event.target.value);
    };
  
  const addNewItem = () => {
      setItems([...items, { name: newItem, isNew: true }]);
      setNewItem('');
    };
  
  useEffect(() => {
      const oldItems = items.filter(i => !i?.hasOwnProperty('isNew'));
      if (!addItems && !_.isEqual(options, items)) {
          setItems(options);
      } else if (addItems && !_.isEqual(oldItems, options)) {
          const newItems = items.filter(i => i?.hasOwnProperty('isNew'));
          setItems([...options, ...newItems]);
      }
  }, [addItems, items, options]);

  return (
    <section
      className={`NewCustomSelect ${className || ''}`}
    >
      <Select
        size="large"
        disabled={disabled}
        className={
          `NewCustomSelect-button ${selectOpen ? 'NewCustomSelect-open' : isHighlighted ? 'NewCustomSelect-open highlighted' : 'NewCustomSelect-closed'} ${disabled ? 'NewCustomSelect-disabled' : ''}`
        }
        dropdownClassName="NewCustomSelect-menu"
        value={selected || undefined}
        onSelect={onSelect}
        open={selectOpen}
        onDropdownVisibleChange={setSelectOpen}
        showSearch={showSearch}
        filterOption={filterOption}
        placeholder={title}
        defaultValue={defaultValue}
        virtual={false}
        dropdownRender={opts => (
          <>
            {opts}
            {addItems && 
            <>
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, flexDirection:'row', alignItems: 'center' }}>
                <CustomInput placeholder= {addItemsPlaceholder} style={{ flex: 'auto', marginRight: '5px' }} value={newItem} onChange={onNewItemChange} />
                <CustomButton
                  onClick={addNewItem}
                  disabled = {newItem === ''}
                >
                  <PlusOutlined /> {addItemText}
                </CustomButton>
              </div>
            </>
            }
          </>
        )}
      >
        {
          items.map(({name, slug, ...rest}) => (
            <Select.Option
              key={rest[returnValueKey] ?? (slug || name)}
              value={rest[returnValueKey] ?? (slug || name) }
              title={ name || slug }
              className="NewCustomSelect-option"
              { ...rest }
            >
              { renderOption({ name, slug, ...rest }) }
            </Select.Option>
          ))
        }
      </Select>
    </section>
  );
}

export default NewCustomSelect;
