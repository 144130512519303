import React, {
  useEffect,
  useRef,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
} from 'react-redux';

import './style.less';

const POPUP_NAME = 'popup_window';
const POPUP_SPECS = 'width=980,height=600,resizable=yes';

const SsoPopup = ({
  intl,
}) => {
  const t = intl.messages;
  const {
    email,
    url,
  } = useSelector(state => state.signin);

  const popupRef = useRef(null);

  useEffect(() => {
    if(url && !popupRef.current) {
      popupRef.current = window.open(url, POPUP_NAME, POPUP_SPECS);
    }
  }, [ url ]);

  return (
    <article className="SsoPopup">
      <h1
        className="SsoPopup__heading"
      >{ t.waiting_for_signup }</h1>
      <p
        className="SsoPopup__subheading"
      >{ email }</p>
    </article>
  )
}

export default injectIntl(SsoPopup);
