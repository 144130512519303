const TARGET = 'permissions';

const initialState = {
  data: [],
  fetching: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      const result = action.response.result;
      return {
        data: result,
        fetching: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        fetching: false,
        error: action.code || 500,
      };
    default:
      return state;
  }
};

export {
  reducer as permissions,
};
