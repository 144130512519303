import React from 'react'
import {Input} from 'antd'
import './style.less'

const CustomInput = (props) => (
  <Input {...props} className={`CustomInput ${props.className}`} />
)

CustomInput.Password = (props) => (
  <Input.Password className='CustomInput' {...props}/>
)

export default CustomInput
