import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl'
import { keys, isEmpty } from 'lodash'
import { Menu, Col, Row } from 'antd'
import {
  settingsTemplate,
  rootNodeSettingsTemplate,
} from './SettingsTemplate';
import Option from './Option'
import EmailConfiguration from './EmailConfiguration';
import useOrganization from '../../utils/useOrganization';

// NOTICE: These should probably be constants, but since they are only used in this file...
const PRIVATE_KEYS_TOP_LEVEL = [
  '_product',    // used for product match
  '_systemOnly', // only shown for system admins. Notice this can exist at leaf level too.
];

const MENU_ORDER = [
  'configuration_label_atlas',
  'configuration_label_atlas_optional_emails',
  'configuration_label_community_optional_emails',
  'email_template_menu',
  'configuration_label_general_suborg',
  'configuration_label_community',
  'configuration_label_general',
];

function isAGroup(template, selectedCategory, param) {
  return !template[selectedCategory][param].type;
}

function isAParamOrGroupWithParams(template, selectedCategory, settings) {
  return param => settings && (!isAGroup(template, selectedCategory, param) || !isEmpty(settings[param]));
}

// NOTICE: _product can be at top level only for now
function isProductMatch(template, orgProductSet) {
  return category => !template[category]?._product || orgProductSet.has( template[category]?._product )
}

// NOTICE: _systemOnly can be at top level only for now
function isMemberRoleMatch(template, isSystemAdmin) {
  return category => !template[category]?._systemOnly || isSystemAdmin
}

const useSettingsTemplateWithoutEmptyCategories = (
  settings,
  isRootOrganization,
  isSystemAdmin,
  orgProductSet,
) => {
  return useMemo(() => {
    const base = {
      ...settingsTemplate,
      ...( isRootOrganization && rootNodeSettingsTemplate )
    };
    return keys(base)
      .filter(key => !PRIVATE_KEYS_TOP_LEVEL.includes(key))
      .filter(isMemberRoleMatch(base, isSystemAdmin))
      .filter(isProductMatch(base, orgProductSet))
      .reduce((acc, key) => {
        // TODO: Remove this. It prevents certain sections from appearing or not based on what there
        //       is in the config. Just filtering the product is much easier
        //       We don't remove it right away because the options then appear empty...
        if (
          !Object.keys(base[key]).some(isAParamOrGroupWithParams(base, key, settings))
        ) {
          return acc;
        } else {
          return {...acc, [key]: base[key]}
        }
      }, {});
  }, [
    settings,
    isRootOrganization,
    isSystemAdmin,
    orgProductSet,
  ])
}

const OptionsPanel = ({
  intl,
  isSystemAdmin,
  availableDimensions,
  settings,
  onChange,
}) => {
  const {
    isRootOrganization,
    organization,
    suborganization,
  } = useOrganization();

  const orgProductSet = useMemo(() => {
    const orgFeatures = organization?.product_config?.atlas?.features || {};
    const suborgFeatures = suborganization?.product_config?.atlas?.features || {};

    const hasMateriality = orgFeatures.materiality || suborgFeatures.materiality;
    const hasEquality = orgFeatures.equality || suborgFeatures.equality;

    return new Set([
      ...(organization?.products || []),
      ...(suborganization?.products || []),
      ...(hasMateriality ? ['materiality'] : []),
      ...(hasEquality ? ['equality'] : []),
    ]);
  }, [
    organization,
    suborganization,
  ]);

  const template = useSettingsTemplateWithoutEmptyCategories(
    settings,
    isRootOrganization,
    isSystemAdmin,
    orgProductSet
  )

  const menuItems = useMemo(
    () => {
      let items = [...keys(template)];

      if (isRootOrganization) {
        items.push('email_template_menu');
      }
      return items
        .sort((a, b) => {
          if (MENU_ORDER.indexOf(a) < 0) {
            return 1;
          }
          if (MENU_ORDER.indexOf(b) < 0) {
            return -1;
          }
          return MENU_ORDER.indexOf(a) > MENU_ORDER.indexOf(b) ? 1 : -1;
        });
    },
    [
      template,
      isRootOrganization,
    ]
  );

  const [selectedCategory, setSelectedCategory] = useState(menuItems[0]);

  return (
    <Row>
      <Col span={8}>
        <Menu selectedKeys={[selectedCategory]} mode="inline">
          { menuItems
            .map((key) => (
            <Menu.Item
              key={key}
              onClick={({ key }) => setSelectedCategory(key)}
            >
              {intl.formatMessage({ id: key })}
            </Menu.Item>
          ))}
        </Menu>
      </Col>
      <Col span={15} offset={1}>
        {keys(template[selectedCategory])
          .filter(isAParamOrGroupWithParams(template, selectedCategory, settings))
          .map((param) => {
              if (isAGroup(template, selectedCategory, param)) {
                return keys(template[selectedCategory][param]).map((option) => (
                  <Option
                    key={option}
                    availableDimensions={availableDimensions}
                    path={[param, option]}
                    templateSection={
                      template[selectedCategory][param][option]
                    }
                    settingsValues={settings}
                    onChange={onChange}
                  />
                ))
              } else {
                return <Option
                  key={param}
                  availableDimensions={availableDimensions}
                  path={[param]}
                  templateSection={
                    template[selectedCategory][param]
                  }
                  settingsValues={settings}
                  onChange={onChange}
                  />
                }}
          )}
        { selectedCategory === 'email_template_menu' &&
        <Row>
          <Col span={24}>
            <EmailConfiguration />
          </Col>
        </Row>
        }
      </Col>
    </Row>
  )
}
export default injectIntl(OptionsPanel)
