import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  injectIntl,
} from 'react-intl';

import {
  Form,
  Alert,
  Row,
} from 'antd';
import {
  LockOutlined,
} from '@ant-design/icons';

import {
  fetchPasswordPolicy,
  submitRecoveredPassword,
} from 'actions/auth';

import AuthLayout from 'components/AuthLayout';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';
import { PasswordPolicyInfo } from 'aplanet-ui-kit';

import validatePasswordPolicy from 'utils/validatePasswordPolicy';

import './style.less';

const handlePasswordChange = (setter) => (e) => {
  e.preventDefault();
  setter(e.target.value);
};

const SetPassword = ({
  intl,
  match,
}) => {
  const t = intl.messages;

  const [ password1, setPassword1 ] = useState('');
  const [ password2, setPassword2 ] = useState('');

  const equal = (password1 === password2);
  const { token } = match.params;

  // Store and actions
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchPasswordPolicy(
        token
      )
    );
  }, [
    dispatch,
    token,
  ]);

  const {
    loading: loadingPolicy,
    error: errorPolicy,
    data: passwordPolicy,
  } = useSelector(state => state.password_policy);

  const {
    loading,
    error,
  } = useSelector(state => state.password_recovery);

  const passwordError = useMemo(() => {
    if(!passwordPolicy) {
      return null;
    }
    return validatePasswordPolicy(
      intl,
      passwordPolicy.policy,
      passwordPolicy.email,
    )(password1)
  }, [
    intl,
    passwordPolicy,
    password1,
  ]);
  const valid = !passwordError;


  const validateStatus1 = password1 && !valid ? 'warning' : '';
  const validateStatus2 = !validateStatus1 && !equal ? 'warning' : '';

  const handleSubmit = useCallback(() => {
    if(equal) {
      dispatch(
        submitRecoveredPassword(
          token,
          password1,
          {},
          'recover_password',
        )
      );
    }
  }, [
    equal,
    dispatch,
    token,
    password1,
  ]);

  return (
    <AuthLayout showLogin>
      <article className="SetPassword">
        <h1
          className="SetPassword__heading"
        >
            { t.setpassword_title }
            <PasswordPolicyInfo policy={passwordPolicy?.policy} intl={intl} />
        </h1>
        <p
          className="SetPassword__subheading"
        >{ '' }</p>
        <div>
          { !error ? null : <Alert type="error" message={ t.setpassword_error_setting_password } /> }
        </div>
        <Form onFinish={handleSubmit}>
          <Form.Item
            hasFeedback
            validateStatus={validateStatus1}
            help={ validateStatus1 && passwordError }
          >
            <CustomInput.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={ t.setpassword_password }
              value={password1}
              onChange={handlePasswordChange(setPassword1)}
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            validateStatus={validateStatus2}
            help={ validateStatus2 && t.setpassword_passwords_must_be_equal }
          >
            <CustomInput.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={ t.setpassword_password }
              value={password2}
              onChange={handlePasswordChange(setPassword2)}
            />
          </Form.Item>
          <Row
            type="flex"
            justify="end"
          >
            <Form.Item>
              <CustomButton
                block
                type="primary"
                htmlType="submit"
                disabled={!valid || loading || loadingPolicy || !equal || error || errorPolicy}
              >
                { t.setpassword_set_password }
              </CustomButton>
            </Form.Item>
          </Row>
        </Form>
      </article>
    </AuthLayout>
  )
}

export default injectIntl(SetPassword);

