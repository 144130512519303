import React from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

const OrganizationSummaryCard = ({
  organization,
  intl,
  onClick,
}) => {
  const t = intl.messages;

  const handleClickCard = () => {
    onClick(organization.slug)
  }

  return (
    <section className="OrganizationSummaryCard" onClick={handleClickCard}>
      <div className="OrganizationSummaryCard__logo">
        <img alt="" src={organization.logo_small} />
      </div>
      <div className="OrganizationSummaryCard__info"> 
        <h3 className="OrganizationSummaryCard__name">{organization.name}</h3>
        <div className="OrganizationSummaryCard__detail">
          <ul>
            {(organization.products || []).map(product => (
              <li
                key={product}
              >
                {t[`product_${product}`]}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(OrganizationSummaryCard);
