import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import useForm from 'utils/useForm';
import CustomInput from 'components/CustomInput';
import PermissionLogo from 'components/PermissionLogo';
import LevelsPicker from 'components/LevelsPicker';
import { addAdminValidation as validation } from './validation';
import {
  levelToBackendLevel,
  allBackendLevelsUntil,
} from 'utils/validation';

import { CustomCheckbox } from 'aplanet-ui-kit';
import {
  Col,
  Row,
  Modal,
  Tabs,
  Form,
  Radio,
  Divider,
  Checkbox,
} from 'antd';

import './style.less';

const DEFAULT_APPROVAL_LEVELS = 1;

const BOOLEAN_PERMISSIONS = {
  atlas: [
    'can_read_kpi',
    'can_write_kpi',
    'can_request_kpi',
    'can_report_kpi',
    'can_configure_kpi',
    'can_undo_validate_kpi',
    'can_validate_kpi',
    'can_export_kpis',
    'can_import_kpis',
    'can_manage_variables_configuration',
  ],
  community: [
    'can_read_initiatives',
    'can_read_reports',
    'can_manage_participation',
    'can_manage_collaborations',
    'can_manage_proposals',
    'can_manage_initiatives',
    'can_manage_volunteers',
  ],
  general: [
    'can_manage_configuration',
    'can_manage_permissions',
  ],
};

const MANDATORY_PERMISSIONS = {
  atlas: ['can_read_kpi'],
  community: ['can_read_initiatives'],
  general: [],
};

const AddAdmin = ({
  intl,
  visible,
  pushing,
  onSubmit,
  onCancel,
  organization,
  allowedProducts,
  currentUser,
  is_system
}) => {
  const t = intl.messages;

  const approvalLevels = useMemo(() => {
    return (organization.settings.atlas || {}).approval_levels || DEFAULT_APPROVAL_LEVELS;
  }, [
    organization,
  ]);

  const hasRestrictedAdminsPerm = useMemo(() => {
    return organization?.settings?.atlas?.features?.restricted_admins;
  }, [
    organization,
  ]);

  const validateForm = useMemo(() =>
    validation(intl),
    [ intl ]
  );
  const defaults = {
    general_permission: {},
    atlas_permission: allowedProducts.includes('atlas')
      ? {
          can_read_kpi: true,
          ...(
            hasRestrictedAdminsPerm
            ? {can_affect_all_kpis: true}
            : {}
          )
        }
      : {},
    community_permission: allowedProducts.includes('community')
      ? { can_read_initiatives: true }
      : {},
  };
  const submitForm = () => {
    onSubmit(values);
    onCancel();
    resetForm();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || null,
    [isDirty, errors]
  );

  const handleOnChangeApprovalLevel = useCallback(
    (value) => {
      handleChange('atlas_permission')({
        ...values.atlas_permission,
        validate_kpi_level: value.map(v => levelToBackendLevel(v)).reduce((a, b) => a | b, 0),
      })
    },
    [handleChange, values]
  );

  const togglePermission = useCallback(
    (app, permission) => {
      let newState = {
        ...values[`${app}_permission`],
        [ permission ]: !values[`${app}_permission`][permission]
      };

      if (app === 'atlas') {
        if (
          permission === 'can_validate_kpi'
          && !values[`atlas_permission`]['validate_kpi_level']
        ) {
          newState['validate_kpi_level'] = levelToBackendLevel(Number(DEFAULT_APPROVAL_LEVELS))
        }
        if (
          permission === 'can_import_kpis'
          && !values[`atlas_permission`]['can_import_kpis']
        ) {
          newState['can_export_kpis'] = true;
        }
        if (
          permission === 'can_affect_all_kpis'
          && values[`atlas_permission`]['can_affect_all_kpis']
        ) {
          newState['can_manage_variables_configuration'] = false;
        }
      }

      handleChange(`${app}_permission`)(newState);
    },
    [values, handleChange]
  );

  const permissionDisabled = useCallback((app, permission) => {
    const hasPermission =  MANDATORY_PERMISSIONS[app].includes(permission);
    return is_system ? 
      hasPermission : 
      (hasPermission || !((currentUser[`${app}_permission_inherit`] || {})[permission] || currentUser[`${app}_permission`][permission]));
  }, [currentUser, is_system]);

  const mandatoryPermissions = useMemo(
    () => {
      let permissions = MANDATORY_PERMISSIONS;
      if (values.details?.is_auditor) {
        return {
          atlas: [
            'can_read_kpi',
            'can_request_kpi',
            'can_undo_validate_kpi',
            'can_validate_kpi',
          ],
          community: [],
          general: [],
        };
      }
      if(values.atlas_permission.can_import_kpis && !permissions.atlas.includes('can_export_kpis')) {
        permissions.atlas.push('can_export_kpis');
      } else {
        permissions.atlas = permissions.atlas.filter(p => p !== ('can_export_kpis'));
      }
      return permissions;
    }, [
      values,
    ]
  );

  const disabledPermissions = useMemo(
    () => {
      if (values.details?.is_auditor) {
        return {
          atlas: [
            'can_read_kpi',
            'can_write_kpi',
            'can_request_kpi',
            'can_report_kpi',
            'can_configure_kpi',
            'can_export_kpis',
            'can_import_kpis',
            'can_manage_variables_configuration',
          ],
          community: [
            'can_read_initiatives',
            'can_read_reports',
            'can_manage_participation',
            'can_manage_collaborations',
            'can_manage_proposals',
            'can_manage_initiatives',
            'can_manage_volunteers',
          ],
          general: [
            'can_manage_configuration',
            'can_manage_permissions',
          ],
        };
      }
      if (!values.atlas_permission.can_affect_all_kpis) {
        return {
          atlas: [
            'can_manage_variables_configuration',
          ],
          community: [],
          general: [],
        };
      }
      return {};
    },
    [
      values.details,
      values.atlas_permission,
    ]
  );

  const handleOnToggleAuditor = useCallback(
    e => {
      handleChange('details')({ is_auditor: e.target.checked });

      if (e.target.checked) {
        handleChange('general_permission')({});
        handleChange('community_permission')({});
        handleChange('atlas_permission')(
          allowedProducts.includes('atlas')
            ? {
                can_read_kpi: true,
                can_undo_validate_kpi: true,
                can_request_kpi: true,
                can_validate_kpi: true,
                validate_kpi_level: allBackendLevelsUntil(Number(approvalLevels)),
              }
            : {}
        );
      } else {
        handleChange('general_permission')({});
        handleChange('community_permission')(
          allowedProducts.includes('community')
            ? { can_read_initiatives: true }
            : {},
        );
        handleChange('atlas_permission')(
          allowedProducts.includes('atlas')
            ? { can_read_kpi: true }
            : {}
        );
      }
    },
    [
      allowedProducts,
      handleChange,
      approvalLevels,
    ]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText={t.organization_profile_save}
      title={t.organization_profile_admins_add}
      className="AddAdmin"
      okButtonProps={{ disabled: pushing }}
      cancelButtonProps={{ disabled: pushing }}
      closable={!pushing}
    >
      <Row>
        <Col span={24} className="AddAdmin-label">
          { t.organization_profile_name }
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={showError('name') ? 'error' : ''}
            help={showError('name')}
            colon={false}
          >
            <CustomInput
              name="name"
              value={values.name}
              onChange={handleChangeEvent}
              placeholder={t.organization_profile_name}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="AddAdmin-label">
          { t.organization_profile_email }
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={showError('email') ? 'error' : ''}
            help={showError('email')}
            colon={false}
          >
            <CustomInput
              name="email"
              value={values.email}
              onChange={handleChangeEvent}
              placeholder={t.organization_profile_email}
            />
          </Form.Item>
        </Col>
      </Row>
      { allowedProducts.includes('atlas') && organization.settings?.atlas?.can_add_auditors &&
        <Row>
          <Col>
            <Form.Item
              label={
                <span className="AddAdmin-label">
                  {t.organization_profile_is_auditor}
                </span>
              }
              hasFeedback
              colon={false}
            >
              <CustomCheckbox
                checked={values.details?.is_auditor}
                onChange={handleOnToggleAuditor}
              />
            </Form.Item>
          </Col>
        </Row>
      }
      <Row>
        <Col span={24} className="AddAdmin-label">
          { t.organization_profile_permissions }
        </Col>
        <Col span={24}>
          <Tabs>
            <Tabs.TabPane
              tab={ t.organization_profile_permissions_general }
              key="general"
            >
              <Row gutter={10}>
                {
                  BOOLEAN_PERMISSIONS.general.map(permission => (
                    <Col key={permission} span={24}>
                      <Checkbox
                        checked={values.general_permission[permission]}
                        onChange={() => togglePermission('general', permission)}
                        disabled={ mandatoryPermissions.general.includes(permission) || disabledPermissions.general?.includes(permission) || permissionDisabled('general', permission) }
                      >
                        <PermissionLogo
                          className="AddAdmin-permission-logo"
                          permission={permission}
                          app="general"
                        />
                        { t[`permission_general_${permission}`] }
                      </Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Tabs.TabPane>
            { allowedProducts.includes('community')
              ? <Tabs.TabPane
                  tab={ t.organization_profile_permissions_community }
                  key="community"
                >
                  <Row gutter={10}>
                    {
                      BOOLEAN_PERMISSIONS.community.map(permission => (
                        <Col key={permission} span={24}>
                          <Checkbox
                            checked={values.community_permission[permission]}
                            onChange={() => togglePermission('community', permission)}
                            disabled={ mandatoryPermissions.community.includes(permission) || disabledPermissions.community?.includes(permission) || permissionDisabled('community', permission) }
                          >
                            <PermissionLogo
                              className="AddAdmin-permission-logo"
                              permission={permission}
                              app="community"
                            />
                            { t[`permission_community_${permission}`] }
                          </Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                </Tabs.TabPane>
              : null
            }
            { allowedProducts.includes('atlas')
              ? <Tabs.TabPane
                  tab={ t.organization_profile_permissions_atlas }
                  key="atlas"
                >
                  <Row gutter={10}>
                    { hasRestrictedAdminsPerm &&
                    <Col span={24}>
                      <h4>{t.permission_atlas_can_affect_all_kpis}:</h4>
                      <Radio.Group
                        value={values.atlas_permission?.can_affect_all_kpis}
                        onChange={() => togglePermission('atlas', 'can_affect_all_kpis')}
                      >
                        <Radio
                          value={true}
                          style={{ display: 'block' }}
                        >
                          {t.permission_atlas_can_affect_all_kpis_yes}
                        </Radio>
                        <Radio
                          value={false}
                          style={{ display: 'block' }}
                        >
                          {t.permission_atlas_can_affect_all_kpis_no}
                        </Radio>
                      </Radio.Group>
                      <Divider />
                    </Col>
                    }
                    {
                      BOOLEAN_PERMISSIONS.atlas.map(permission => (
                        <Col key={permission} span={24}>
                          <Checkbox
                            checked={values.atlas_permission[permission]}
                            onChange={() => togglePermission('atlas', permission)}
                            disabled={ mandatoryPermissions.atlas.includes(permission) || disabledPermissions.atlas?.includes(permission) || permissionDisabled('atlas', permission) }
                          >
                            <PermissionLogo
                              className="AddAdmin-permission-logo"
                              permission={permission}
                              app="atlas"
                            />
                            { t[`permission_atlas_${permission}`] }
                          </Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                  { values.atlas_permission.can_validate_kpi
                    ? 
                      <LevelsPicker 
                        kpi_level={values.atlas_permission.validate_kpi_level}
                        onChange={handleOnChangeApprovalLevel}
                        t={t}
                        organization={organization}
                      />
                    : null
                  }
                </Tabs.TabPane>
              : null
            }
          </Tabs>
        </Col>
      </Row>
    </Modal>
  )
}

export default injectIntl(AddAdmin);
