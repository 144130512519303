import React from 'react'
import { injectIntl } from 'react-intl'
import TrueFalse from './TrueFalse'

const OptionsList = ({
  intl,
  label,
  path,
  optionValue = [],
  templateSection,
  onChange,
}) => {
  return (
    <div className="Option">
      {templateSection.values.map((value) => (
        <div className="Option-item" key={value}>
          <TrueFalse
            label={`${label}_${value}`}
            optionValue={optionValue.indexOf(value) > -1}
            onChange={(_, checked) =>
              onChange(
                path,
                checked
                  ? [...optionValue, value]
                  : optionValue.filter((option) => option !== value)
              )
            }
          />
        </div>
      ))}
    </div>
  )
}

export default injectIntl(OptionsList)
