import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
    useMemo
} from "react";
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { List, Drawer, Badge, notification, Row, Col } from 'antd';
//import Loading from 'containers/Loading';
//import useScreenSize from 'utils/useScreenSize';
import { WebSocketContext } from "components/WebsocketContext";
import Notification from "../Notification";
import CustomButton from 'components/CustomButton';
import AplanetIcon from 'components/AplanetIcon';
//import NotificationBody from "../Notification/Body";
import NotificationPopup from "components/NotificationPopup";
import './style.less'

const NO_OP = () => { }

const NotificationCenter = ({
    intl,
    onNavigation = NO_OP
}) => {
    const { socket, sendMessage } = useContext(WebSocketContext);
    const [visible, setVisible] = useState(false);
    const [notReadFilter, setNotReadFilter] = useState(true);
    const [readFilter, setReadFilter] = useState(false);
    const [allFilter, setAllFilter] = useState(false);

    const t = intl.messages;
    const {
        data: notifications,
        no_reads
    } = useSelector(state => state.notification)

    const readNotification = (id) => {
        sendMessage('notification:read', { id });
    }
    const unreadNotification = (id) => {
        sendMessage('notification:unread', { id });
    }
    const deleteNotification = (id) => {
        sendMessage('notification:delete', { id });
    }

    const markAllRead = () => {
        sendMessage('notification:read', { all: true });
    }
    const markAllUnread = () => {
        sendMessage('notification:unread', { all: true });
    }

    const handleNavigation = useCallback((link) => {
        onNavigation(link);
        setVisible(false);
    },
        //eslint-disable-next-line
        []);

    useEffect(() => {
        if (!socket) {
            return
        };
        //side effect of useEffect: multiple listeners for same event.
        if (socket.listeners('notification:create').length > 0) {
            socket.off('notification:create');
        }
        socket.on('notification:create', (msg) => {
            notification.open({
                message: msg.content.title,
                className: 'Notification_container unread',
                description: (
                    <NotificationPopup
                        intl={intl}
                        data={msg}
                        onNavigation={handleNavigation}
                    />
                )
            });
        });

    }, [
        socket,
        intl,
        handleNavigation
    ])

    const handleFilterNotifications = (filter) => {
        switch (filter) {
            case 'all':
                setNotReadFilter(false);
                setReadFilter(false);
                setAllFilter(true);
                break;

            case 'read':
                setNotReadFilter(false);
                setReadFilter(true);
                setAllFilter(false);
                break;
            default:
                setNotReadFilter(true);
                setReadFilter(false);
                setAllFilter(false);
                break;
        }
    };

    const processed_notifications = useMemo(() => {
        return notifications.filter((el) => {
            if (allFilter) {
                return true;
            }
            // let resultFilter = false;
            // if (readFilter) {
            //     resultFilter = el.read;
            // }else if (notReadFilter) {
            //     resultFilter = !el.read;
            // }
            //return resultFilter;
            return readFilter ? (el.read) : notReadFilter ? (!el.read) : false;
        })
    }, [
        notifications,
        readFilter,
        allFilter,
        notReadFilter
    ]);

    return (
        <React.Fragment>
            <Badge count={no_reads} className="notificationCenter_badge">
                <AplanetIcon name="Bell" faStyle='fad' onClick={() => setVisible(true)} />
            </Badge>

            <Drawer
                visible={visible}
                title={t.notification_center_title}
                placement="right"
                size="large" // Undefined in this ant version
                onClose={() => setVisible(false)}
                footer={[]}
                className="notificationCenter_drawer"
                // width={['md','lg','xl','xxl'].includes(useScreenSize()) ? 300 : 600}
                width={400}

            >
                <Row>
                    <Col span={24} className="notificationCenter__filter_container">
                        <div onClick={() => handleFilterNotifications('not-read')} className={`notificationCenter__filter_item ${notReadFilter ? 'active' : ''} `}>
                            <AplanetIcon faStyle="fas" name="Email notification" />
                            <span >
                                {t.notification_center_filter_not_read}
                            </span>
                        </div>
                        <div onClick={() => handleFilterNotifications('read')} className={`notificationCenter__filter_item ${readFilter ? 'active' : ''} `}>
                            <AplanetIcon name="Email read" />
                            <span >
                                {t.notification_center_filter_read}
                            </span>
                        </div>
                        <div onClick={() => handleFilterNotifications('all')} className={`notificationCenter__filter_item ${allFilter ? 'active' : ''} `}>
                            <AplanetIcon name="List ul" />
                            <span>
                                {t.notification_center_filter_all_notifications}
                            </span>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            {notReadFilter &&
                                <Col>
                                    <CustomButton
                                        icon={<AplanetIcon name="Email read" />}
                                        className="notificationCenter__bulk_read"
                                        onClick={() => markAllRead()}
                                    >
                                        {t.notification_center_mark_all_read}
                                    </CustomButton>
                                </Col>
                            }

                            {readFilter &&
                                <Col>
                                    <CustomButton
                                        icon={<AplanetIcon faStyle="fas" name="Email notification" />}
                                        className="notificationCenter__bulk_unread"
                                        onClick={() => markAllUnread()}
                                    >
                                        {t.notification_center_mark_all_not_read}
                                    </CustomButton>
                                </Col>
                            }

                        </Row>
                    </Col>
                    <Col span={24}>
                        {processed_notifications.length ?
                            (
                                <List
                                    className="notificationCenter_list_container"
                                    size="large"
                                    dataSource={processed_notifications}
                                    renderItem={n => {
                                        return (
                                            <List.Item
                                                key={n.id}
                                                className="notificationCenter_list_item"
                                            >
                                                <Notification
                                                    data={n}
                                                    onRead={readNotification}
                                                    onUnread={unreadNotification}
                                                    onDelete={deleteNotification}
                                                    onNavigation={handleNavigation}
                                                />
                                            </List.Item>
                                        )
                                    }
                                    }
                                />

                            )
                            : (
                                <div></div>
                            )

                        }
                    </Col>
                </Row>
            </Drawer>
        </React.Fragment>



    );
};

export default injectIntl(NotificationCenter);