import {
  format,
} from 'date-fns';

const getLocale = (intl) => ((intl && intl.formats) || {}).dateFns;

export const formatCycleDate = (intl, cycle_date) => {
  const [ monthStr, dayStr ] = (cycle_date || '01-01').split('-');
  const month = parseInt(monthStr);
  const day = parseInt(dayStr);

  const d = new Date(1970, (month - 1), day, 12, 0, 0);
  return format(d, 'd MMMM', { locale: getLocale(intl) });
};

