import React from 'react'
import { injectIntl } from 'react-intl'
import { CustomCheckbox } from 'aplanet-ui-kit'

const TrueFalse = ({ intl, label, optionValue, path, onChange }) => (
  <CustomCheckbox
    checked={optionValue}
    onChange={(e) =>
      e.target.checked !== optionValue && onChange(path, e.target.checked)
    }
    data-testid={path}
  >
    {intl.formatMessage({ id: `configuration_label_${label}` })}
  </CustomCheckbox>
)

export default injectIntl(TrueFalse)
