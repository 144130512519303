import React, {
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import OrganizationSummaryCard from 'components/OrganizationSummaryCard';
import { CustomInput } from 'aplanet-ui-kit';

import {
  Row,
  Col,
  Layout,
  Spin,
  Space,
  Empty, Dropdown, Menu
} from 'antd';

import {
  setOrganization,
  resetOrganization
} from 'actions/organization';

import './style.less';
import useAdminMemberships from '../../utils/useAdminMemberships';
import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import { MenuOutlined } from '@ant-design/icons';
import { getDefaultRoute } from '../../utils/clientUrl';

const {
  Content,
  Header
} = Layout;

const OrganizationSelector = ({
                                intl,
                                organization,
                                setOrganization,
                                resetOrganization,
                                location,
                                history
                              }) => {
  const t = intl.messages;
  const [choice, setChoice] = useState(null);
  const [searchText, setSearchText] = useState('');

  const {
    fetching,
    data
  } = organization;
  const memberships = useAdminMemberships();

  // Reset organization when getting in
  useEffect(() => {
    resetOrganization();
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  useEffect(() => {
    if (choice && data) {
      history.push(getDefaultRoute(location.search, choice))
    }
  },
    //eslint-disable-next-line
    [choice, data, history, memberships]);

  const handleClick = useCallback((organization) => {
    setOrganization(organization);
    setChoice(organization);
  }, [
    setOrganization,
  ]);


  const filteredOrgs = useMemo(() => (
    memberships.filter(
      org => org.name.toLowerCase().includes(
        searchText.toLowerCase()
      )
    )
  ), [
    memberships,
    searchText,
  ]);

  return (
    <Layout className="Layout">
      <Header className="OrganizationSelector-Header">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to="/logout">
                    {t.avatardropdown_logout}
                  </Link>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <section>
              <Avatar
                shape="circle"
                icon={<MenuOutlined />}
                className='OrganizationSelector-avatar'
              />
            </section>
          </Dropdown>
      </Header>
      <Content className="Content">
        <div className="Whitespace"></div>
        { fetching ? <Spin size="large" /> :
          <React.Fragment>
            <Space size={20} direction="vertical">
              <h1>{ t.organizationselector_title }</h1>
              <CustomInput
                placeholder={ t.search }
                onChange={e => setSearchText(e.target.value)}
              />
              <Row
                type="flex"
                gutter={20}
                className="OrganizationSelector__row"
              >
                { filteredOrgs.length
                  ? filteredOrgs.map(organization => (
                    <Col
                      key={organization.slug}
                      xs={24}
                      md={8}
                      lg={6}
                      className="OrganizationSelector__col"
                    >
                      <OrganizationSummaryCard
                        onClick={handleClick}
                        organization={organization}
                      >
                        { organization.name }
                      </OrganizationSummaryCard>
                    </Col>
                    ))
                    : <Empty
                        className="OrganizationSelector-empty"
                        description={ t.no_data }
                      />
                }
              </Row>
            </Space>
          </React.Fragment>
        }
        <div className="Whitespace"></div>
      </Content>
    </Layout>
  )
}

const mapStateToProps = ({
  organization
}) => ({
  organization
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps,
      {
        setOrganization,
        resetOrganization,
      })(OrganizationSelector)
  )
);
