import React, { useCallback, useState } from 'react'
import { injectIntl } from 'react-intl'

import CustomInput from 'components/CustomInput'
import CustomButton from 'components/CustomButton'

const AddLabelButton = ({ onAdd }) => {
  const [isAdding, setIsAdding] = useState(false)
  const onChange = useCallback((e) => {
    onAdd(e.target.value)
    setIsAdding(false)
  }, [onAdd, setIsAdding])

  return (
    <>
      {isAdding ? (
        <CustomInput
          className="ValueInput"
          data-testid="conf-text"
          onPressEnter={onChange}
          onBlur={onChange}
        />
      ) : (
        <CustomButton
          onClick={() => setIsAdding(true)}
          name="conf-add-button"
        >
          <span>+</span>
        </CustomButton>
      )}
    </>
  )
}

export default injectIntl(AddLabelButton)
