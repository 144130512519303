const TARGET = 'email';

const initialState = {
  data: null,
  fetching: false,
  pushing: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        pushing: action.method !== 'GET',
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      const result = action.response.result;

      switch(action.response.method) {
        case 'PUT':
          return {
            data: state.data.map(
              email => (email.slug === result.slug && email.locale === result.locale) ? result : email
            ),
            fetching: false,
            pushing: false,
            error: null,
          };
        default:
          return {
            data: result,
            fetching: false,
            pushing: false,
            error: null,
          };
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code || 500,
      };
    default:
      return state;
  }
};

export {
  reducer as email,
};
