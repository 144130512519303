import { useEffect, useMemo, useState } from 'react'

const RedirectIfNotAdminRole = ({ adminMemberships, loading, to, render, ...props }) => {
  const [renderComponent, setRenderComponent] = useState(false)
  const isAdmin = useMemo(() => {
    return adminMemberships.length > 0
  }, [adminMemberships])

  useEffect(
    () => {
      if (!loading) {
        if(!isAdmin) {
          window.location.replace(to);
        } else {
          setRenderComponent(true)
        }
      }
    },
    [isAdmin, adminMemberships, to, loading]
  )

  if (renderComponent) {
    return render(props);
  }

  return null
}

export default RedirectIfNotAdminRole
