const DEFAULT_PASSWORD_POLICY = {
  minLength: 6,
  maxLength: 80,
  forbidEmail: true,
};

const EMAIL_PASSWORD_SUBSTRING_LENGTH = 3;
const SYMBOL_REGEXP = new RegExp('[^0-9A-Za-z]');
const UPPERCASE_REGEXP = new RegExp('[A-Z]');
const LOWERCASE_REGEXP = new RegExp('[a-z]');
const NUMBER_REGEXP = new RegExp('[0-9]');

const checkEmail = (email, password) => {
  if(!email || !password) {
    return false;
  }

  const lcPassword = password.toLowerCase();

  if(email.length <= EMAIL_PASSWORD_SUBSTRING_LENGTH) {
    return lcPassword.includes(email.toLowerCase());
  }

  const chars = email.toLowerCase().split('');

  for(let i = 0; i <= (chars.length - EMAIL_PASSWORD_SUBSTRING_LENGTH); i++) {
    if(lcPassword.includes( chars.slice(i, i+EMAIL_PASSWORD_SUBSTRING_LENGTH).join('') )) {
      return true;
    }
  }

  return false;
};

// TODO: replace strings
export const getPolicyError = (intl, policy) => (name) => intl.formatMessage({
  id: `password_policy_${name}`,
}, {
  [name]: policy[name],
});

const validate = (
  intl,
  policy = DEFAULT_PASSWORD_POLICY,
  email,
) => (password) => {
  let result = null;

  const getError = getPolicyError(intl, policy);

  if(
    typeof (policy.minLength) !== 'undefined' &&
    password.length < policy.minLength
  ) {
    result = getError('minLength');
  } else if(
    typeof (policy.maxLength) !== 'undefined' &&
    password.length > policy.maxLength
  ) {
    result = getError('maxLength');
  } else if(
    policy.hasSymbols &&
    !SYMBOL_REGEXP.test(password)
  ) {
    result = getError('hasSymbols');
  } else if(
    policy.hasUppercase &&
    !UPPERCASE_REGEXP.test(password)
  ) {
    result = getError('hasUppercase');
  } else if(
    policy.hasLowercase &&
    !LOWERCASE_REGEXP.test(password)
  ) {
    result = getError('hasLowercase');
  } else if(
    policy.hasNumbers &&
    !NUMBER_REGEXP.test(password)
  ) {
    result = getError('hasNumbers');
  } else if(
    policy.forbiddenWords &&
    policy.forbiddenWords.find(word => password.toLowerCase().includes(word.toLowerCase()))
  ) {
    result = getError('forbiddenWords');
  } else if(
    policy.forbidEmail &&
    checkEmail(email, password)
  ) {
    result = getError('forbidEmail');
  }

  return result;
}

export default validate;

