import React, { useMemo } from 'react';

import './style.less';

import {
  Avatar,
} from 'antd';

const SATURATION = 30;
const LIGHTNESS = 80;
const MAX_WORDS = 2;

const AplanetAvatar = ({
  src,
  name,
  icon,
  size,
  className,
  ...props
}) => {
  const backgroundColor = useMemo(() => {
    if(src || !name) {
      return null;
    }
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    var hue = hash % 360;
    // See https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
    return `hsl(${ hue }, ${ SATURATION }%, ${ LIGHTNESS }%)`;
  }, [ src, name ]);

  const initials = useMemo(() => {
    if(src || !name) {
      return null;
    }

    const words = name.split(' ').filter(str => str.length > 0);
    return words.slice(0, MAX_WORDS).map(word => word[0].toUpperCase()).join('');
  }, [ src, name ]);

  const classNames = useMemo(() => {
    return `${className} AplanetAvatar ${ src ? 'AplanetAvatar-img' : name ? 'AplanetAvatar-initials' : 'AplanetAvatar-icon' }`;
  }, [ src, name, className ]);

  const fontSize = useMemo(() => {
    return size && typeof size === 'number' ? Math.ceil(size/2) : 'inherit';
  }, [ size ]);

  return (
    src
    ? <Avatar src={src} size={size} className={classNames} {...props} />
    : name
    ? <Avatar style={{ backgroundColor, fontSize }} size={size} className={classNames} {...props}>{ initials }</Avatar>
    : <Avatar icon={icon} size={size} className={classNames} {...props} />
  );
}

export default AplanetAvatar;
