import validatePasswordPolicy from 'utils/validatePasswordPolicy';
import { isValidPhoneNumber } from 'react-phone-number-input';
import phoneMetadata from 'components/PhoneInput/metadata.custom.json';

const allowedCountryCodes = Object.keys(
  phoneMetadata.country_calling_codes || {}
).map(code => `+${code}`);

const changePasswordValidation = (
  intl,
  password_policy,
  email,
) => ({
  old_password = '',
  password = '',
  password_again = '',
}) => {
  const t = intl.messages;
  let errors = {};

  if (!old_password) {
    errors.old_password = t.form_error_required;
  }

  if (password || password_again) {
    // Trying to change password, run its validation
    const policyError = validatePasswordPolicy(
      intl, password_policy, email
    )(password);
    if (policyError) {
      errors.password = policyError;
    }

    if (!errors.password && password !== password_again) {
      errors.password_again = t.change_password_passwords_must_be_equal;
    }
  }

  return errors;
};

const fullValidation = (
  intl
) => ({
  name,
  phone,
}) => {
  const t = intl.messages;
  let errors = {};
  if (!name) {
    errors.name = t.form_error_required;
  }

  if (phone && !allowedCountryCodes.find(cc => phone.startsWith(cc))) {
    errors.phone = t.form_error_choose_phone_country;
  } else if (phone && !isValidPhoneNumber(phone)) {
    errors.phone = t.form_error_invalid_phone;
  }

  return errors;
};

export {
  changePasswordValidation,
  fullValidation,
};
