import React, {useMemo} from 'react'
import {
    Col,
    Row,
    Checkbox,
    Form,
} from 'antd';
  
import T from 'components/T';

import {
  backendLevelToLevel,
} from 'utils/validation';
  
import './style.less'

const DEFAULT_APPROVAL_LEVELS = 1;

const LevelsPicker = ({organization, t, kpi_level, onChange}) => {
  
  const approvalLevels = useMemo(() => {
    return (organization.settings.atlas || {}).approval_levels || DEFAULT_APPROVAL_LEVELS;
  }, [
    organization,
  ]);

  const approvalLevelOptions = useMemo(() => {
    return new Array(approvalLevels).fill(0).map((_, index) => {
      const level = index + 1;
      return {
        label: (
          <T
            level_of_approval_n={{
              level,
            }}
          />
        ),
        value: level,
      };
    });
  }, [
    approvalLevels,
  ]);
  
  return (
  <div className='levels_picker'>
      <Row className="ManagePermissions-level-of-approval">
        <Col span={24}>
          <Form.Item
            label={t.level_of_approval}
            colon={false}
            required
          >
            <Checkbox.Group 
              options={approvalLevelOptions} 
              onChange={onChange}
              value={
                backendLevelToLevel(
                  kpi_level
                )
              }
            />
          </Form.Item>
        </Col>
      </Row>
  </div>
)}

export default LevelsPicker
