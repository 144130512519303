import { loop, Cmd } from 'redux-loop';
import { isEmpty } from 'lodash'

const TARGET = 'organization_detail';
const ORG_EDIT_TARGET = 'organization_edit';
const MEMBER_TARGET = 'member';
const ID_FIELD = 'id'; // NOTICE: This is specific for permissions

const isTarget = (target) => [TARGET, MEMBER_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  fetching: false,
  pushing: false,
  data: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        ...state,
        data: action.method === 'GET' ? null : state.data,
        fetching: action.method === 'GET' || state.fetching,
        pushing: action.method !== 'GET' || state.pushing,
      };
    case 'API_CALL_COMPLETE':
      if (!action.response || !isTarget(action.response.target)) return state;
      if(isGeneralTarget(action.response.target)) {
        switch(action.response.method) {
          case 'PUT':
            // To support updating multiple organizations at once.
            const result = Array.isArray(action.response.result)
              ? action.response.result
              : [ action.response.result ];

            const nextActions = Cmd.list(
              result.map(organization => (
                Cmd.action({
                  type: 'ORGANIZATION_UPDATE',
                  organization,
                })
              ))
            );
            const organization = result.find(organization => organization.slug === state.data.slug);
            const finalState = {
              ...state,
              data: { ...state.data, ...organization},
              fetching: false,
              error: null,
              success: true
            };

            return loop(finalState, nextActions);
          default:
            return {
              ...state,
              data: action.response.result,
              fetching: false,
              pushing: false,
              error: null
            };
        }
      }

      const targetMember = action.response.result;
      if(!targetMember || !['POST', 'PUT', 'DELETE'].includes(action.response.method) || !state.data || !state.data.users) {
        // This is not a request for me, bailing out
        return state;
      }
      const index = state.data.users.findIndex((user) => user[ID_FIELD] === targetMember[ID_FIELD] && user.organization_id === targetMember.organization_id);
      if(index >= 0 || action.response.method === 'POST') {
        let users;
        switch(action.response.method) {
          case 'POST':
            users = index >= 0 ?
              [ targetMember, ...state.data.users.slice(0, index), ...state.data.users.slice(index+1) ] :
              [ targetMember, ...state.data.users ];
            break;
          case 'DELETE':
            users = [ ...state.data.users.slice(0, index), ...state.data.users.slice(index+1) ];
            break;
          default:
            if (targetMember.status === 'deleted' || (isEmpty(targetMember.atlas_permission) && isEmpty(targetMember.community_permission) && isEmpty(targetMember.general_permission))) {
              users = [...state.data.users].filter(user => user.role === 'admin' && user.id !== targetMember.id);
              break;
            } else {
              users = [
                ...state.data.users.slice(0, index), targetMember, ...state.data.users.slice(index+1)
              ].filter(user => user.role === 'admin');
              break;
            }
        }

        return {
          ...state,
          data: {
            ...state.data,
            users,
          },
          pushing: false,
          error: null,
        };
      } else {
        return {
          ...state,
          pushing: false,
          error: null,
        };
      }

    case 'API_CALL_FAILED':
      if (!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state,
        fetching: state.fetching ? !(action.request.method === 'GET') : state.fetching,
        pushing: state.pushing ? !(action.request.method !== 'GET') : state.pushing,
        error: action.code,
        success: false
      };
    case 'REFRESH_TOKEN_FAILED':
      return initialState;
    case 'ORGANIZATION_DETAIL_UPDATE':
      if (action.organization.slug === state.data.slug) {
        return {
          ...state,
          data: { ...state.data, ...action.organization},
        }
      }
      return state;
    case 'RESET_SUCCESS':
      if (action.target !== ORG_EDIT_TARGET) return state;
      return {
        ...state,
        success: false
      };

    case 'RESET_ERROR':
      if (action.target !== ORG_EDIT_TARGET) return state;
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export { reducer as organization_detail };
