export const idField = 'id';
export const childrenField = 'children';

const mergeObject = (obj, el) => ({ ...obj, ...el });

export const flattenTree = (tree) => {
  if (!tree) return {};
  if (!tree[childrenField] || tree[childrenField].length === 0) {
    return {
      [tree[idField]]: tree,
    };
  }
  return {
    [tree[idField]]: { ...tree, [childrenField]: tree[childrenField].map(child => child[idField]) },
    ...(tree[childrenField].map(child => flattenTree(child)).reduce(mergeObject, {})),
  };
};

// Finds the top-most left-most element in a tree that matches predicate
// Based on https://stackoverflow.com/a/9133690
export const findTree = (tree = [], f) => {
  let stack = [], node;
  stack.push(...tree);

  while (stack.length > 0) {
    node = stack.pop();
    if (f(node)) {
      return node;
    } else if (node[childrenField] && node[childrenField].length) {
      stack.push(...node[childrenField]);
    }
  }

  return null;
}

// Map over tree a function taking (node, processedParent)
export const mapOverTree = (tree = [], childrenKey = 'children') => (f, processedParent = null) => {
  return tree.map(node => {
    const processed = f(node, processedParent);

    if ((node[childrenKey] || []).length === 0) {
      return processed;
    }

    return {
      ...processed,
      [childrenKey]: mapOverTree(node[childrenKey], childrenKey)(f, processed),
    }
  });
};

// Map over tree a function taking (node, processedChildren)
// REMEMBER: When returning each f(node, children) you NEED to return the children like:
//           return {
//             ...node,
//             [WHATEVER],
//             children,
//           }
//
//           Otherwise the children prop get overritten by the one in 'node'
export const mapOverTreeFromLeafs = (tree = [], childrenKey = 'children') => (f) => {
  return tree.map(node => {
    if ((node[childrenKey] || []).length === 0) {
      return f(node, []);
    }

    return f(node, mapOverTreeFromLeafs(node[childrenKey], childrenKey)(f));
  });
};

export const searchTreeWithFullPath = (
  tree = [], f, childrenKey = 'children'
) => {
  return tree.reduce(function iter(r, a) {
    let children;
    if (Array.isArray(a[childrenKey])) {
      children = a[childrenKey].reduce(iter, []);
    }
    const passesSearchCondition = f(a);
    if (children?.length || passesSearchCondition) {
      return r.concat({ ...a, children, matched: passesSearchCondition });
    }
    return r;
  }, []);
};
