import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import ChangePassword from 'components/UserProfile/ChangePassword';
import CustomSelect from 'components/CustomSelect';
import PhoneInput from 'components/PhoneInput';
import useForm from 'utils/useForm';
import { fullValidation as validation } from './validation';

import {
  Col,
  Row,
  Divider,
  Form,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

const Information = ({
  intl,
  profile,
  organization,
  updateProfile,
  currentAction,
  error,
  pushing,
}) => {
  const t = intl.messages;
  const [ updating, setUpdating ] = useState(false)
  const [ editingPhone, setEditingPhone ] = useState(false);

  const validateForm = useMemo(() =>
    validation(intl),
    [ intl ]
  );

  const submitForm = () => {
    setUpdating(true);
    updateProfile('phone', {
      phone: values.phone,
    });
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: profile,
  });
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || null,
    [isDirty, errors]
  );

  const organizationConfig = useMemo(() => organization?.config ?? {}, [organization]);
  
  const preferredLanguages = useMemo(() => [
    ...(organizationConfig?.community?.preferred_languages || []),
    ...(organizationConfig?.atlas?.preferred_languages || [])
  ], [organizationConfig]);
  
  const languages = useMemo(() => {
    const preferredLanguagesSet = [...new Set([
      profile?.language,
      ...preferredLanguages
    ].filter(Boolean))];
    return preferredLanguagesSet.map(value => ({ value, label: t[`language_${value}`] }));
  }, [ t, preferredLanguages, profile?.language ]);

  const handleUpdateLocale = useCallback(
    (language) => {
      updateProfile('language', { language })
    },
    [ updateProfile ]
  );

  const togglePhoneInput = useCallback(
    () => setEditingPhone(!editingPhone),
    [ editingPhone ]
  );

  useEffect(
    () => {
      if (updating && !pushing) {
        if (!error) {
          setEditingPhone(false);
        }
        setUpdating(false);
      }
    },
    [ updating, pushing, error ]
  );

  return (
    <div className="UserProfile-information">
      <Row className="UserProfile-information-row">
        <Col span={6} className="UserProfile-label">
          { t.user_profile_email }
        </Col>
        <Col span={18} className="UserProfile-label">
          { t.user_profile_phone }
        </Col>
        <Col span={6} className="UserProfile-value">
          { profile.email }
        </Col>
        <Col span={18} className="UserProfile-value">
          <Row type="flex" align="middle" gutter={10}>
            { editingPhone
              ? <>
                  <Col>
                    <Form.Item
                      hasFeedback
                      validateStatus={ showError('phone') ? 'error' : '' }
                      help={ showError('phone') }
                      colon={false}
                    >
                      <PhoneInput
                        placeholder={ t.user_profile_phone }
                        value={ values.phone }
                        onChange={handleChange('phone')}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Row type="flex" align="middle" gutter={5}>
                      <Col>
                        <CheckCircleOutlined
                          onClick={handleSubmit}
                        />
                      </Col>
                      <Col>
                        <CloseCircleOutlined
                          onClick={togglePhoneInput}
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              : <>
                  <Col>
                    <span>
                      { profile.phone }
                    </span>
                  </Col>
                  <Col>
                    <EditOutlined onClick={togglePhoneInput} />
                  </Col>
                </>
            }
          </Row>
        </Col>
      </Row>
      <Divider className="UserProfile-information-divider" />
      <Row className="UserProfile-information-row">
        <Col span={24} className="UserProfile-label">
          { t.user_profile_language }
        </Col>
        <Col span={24} className="UserProfile-value">
          <CustomSelect
            className="UserProfile-select"
            value={profile?.language}
            onChange={handleUpdateLocale}
          >
            {
              languages.map(({
                value,
                label,
              }) => (
                <CustomSelect.Option
                  key={value}
                  value={value}
                >
                  { label }
                </CustomSelect.Option>
              ))
            }
          </CustomSelect>
        </Col>
        <div>{!preferredLanguages.includes(profile?.language) && intl.formatMessage({id: 'profile_language_notification'})}</div>
      </Row>
      { profile.showChangePassword && (
        <>
          <Divider className="UserProfile-information-divider" />
          <ChangePassword
            profile={profile}
            organization={organization}
            onSubmit={updateProfile}
            error={error}
            pushing={pushing}
          />
        </>
      )}
    </div>
  );
}

export default injectIntl(Information);
