import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';
import useForm from 'utils/useForm';
import { changePasswordValidation as validation } from './validation';

import {
  Col,
  Row,
  Form,
} from 'antd';


const ChangePassword = ({
  intl,
  profile,
  organization,
  onSubmit,
  error,
  pushing,
}) => {
  const t = intl.messages;
  const [ updating, setUpdating ] = useState(false);

  const validateForm = useMemo(() =>
    validation(
      intl,
      organization.config.password_policy,
      profile.email,
    ),
    [
      intl,
      organization,
      profile,
    ]
  );

  const submitForm = () => {
    setUpdating(true);
    onSubmit('password', {
      old_password: values.old_password,
      password: values.password,
    });
  };

  const {
    values,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || null,
    [isDirty, errors]
  );

  useEffect(
    () => {
      if (updating && !pushing) {
        if (!error) {
          resetForm();
        }
        setUpdating(false);
      }
    },
    [ updating, pushing, error, resetForm ]
  );

  return (
    <div className="UserProfile-change-password">
      <Row className="UserProfile-information-row" gutter={10}>
        <Col span={24} className="UserProfile-information-row-heading">
          { t.user_profile_change_password }
        </Col>
      </Row>
      <Row className="UserProfile-information-row">
        <Col span={24} className="UserProfile-label">
          { t.user_profile_password_current }
        </Col>
        <Col span={12} className="UserProfile-value">
          <Form.Item
            hasFeedback
            validateStatus={ showError('old_password') ? 'error' : '' }
            help={ showError('old_password') }
            colon={false}
            required
          >
            <CustomInput.Password
              placeholder={ t.user_profile_password_current }
              name="old_password"
              value={values.old_password}
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="UserProfile-information-row">
        <Col span={24} className="UserProfile-label">
          { t.user_profile_password_new }
        </Col>
        <Col span={12} className="UserProfile-value">
          <Form.Item
            hasFeedback
            validateStatus={ showError('password') ? 'error' : '' }
            help={ showError('password') }
            colon={false}
            required
          >
            <CustomInput.Password
              placeholder={ t.user_profile_password_new }
              name="password"
              value={values.password}
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="UserProfile-information-row">
        <Col span={24} className="UserProfile-label">
          { t.user_profile_password_repeat_new }
        </Col>
        <Col span={12} className="UserProfile-value">
          <Form.Item
            hasFeedback
            validateStatus={ showError('password_again') ? 'error' : '' }
            help={ showError('password_again') }
            colon={false}
            required
          >
            <CustomInput.Password
              placeholder={ t.user_profile_password_repeat_new }
              name="password_again"
              value={values.password_again}
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="UserProfile-information-row">
        <Col span={24}>
          <CustomButton
            type="primary"
            onClick={handleSubmit}
            loading={updating}
            disabled={updating}
          >
            { t.user_profile_change_password }
          </CustomButton>
        </Col>
      </Row>
    </div>
  );
}

export default injectIntl(ChangePassword);