import React from 'react';
import { injectIntl } from 'react-intl';
import { without } from 'lodash';
import { Divider, Tag, Typography } from 'antd';
import AddLabelButton from './AddLabelButton';
import { isAvailable } from '../SettingsTemplate';

const { Text } = Typography

export const tagColor = {
  GRI: '#003c72',
  EQ: '#5a0e62',
}

const LabelList = ({
  intl,
  label,
  path,
  availableDimensions,
  optionValue = [],
  templateSection,
  onChange,
}) => {
  return (
    <div key={label} className="LabelList">
      <Text key={label} type="keyboard" className="LabelList-title">
        {intl.formatMessage({ id: `configuration_label_${label}` })}
        <span className="LabelList-infoList">
          {templateSection.dimensions?.filter(isAvailable(availableDimensions)).map((dimension) => (
            <span
              key={dimension}
              className="LabelList-info"
              style={{ backgroundColor: tagColor[dimension] }}
            >
              {dimension}
            </span>
          ))}
        </span>
      </Text>
      <div className="LabelList-values">
        {optionValue.map((value) => (
          <Tag
            key={value}
            data-testid={value}
            closable
            onClose={() => onChange(path, without(optionValue, value))}
          >
            {value}
          </Tag>
        ))}
        <AddLabelButton
          onAdd={(newValue) => onChange(path, [...optionValue, newValue])}
        />
      </div>
      <Divider dashed />
    </div>
  )
}

export default injectIntl(LabelList)
